import React from "react";
// import Table from "../components/Table";
import Partnerslider from "../components/Partnerslider";
import signupPartnership from "../assets/images/signups.png";
import refer from "../assets/images/reffer.png";
import commissions from "../assets/images/monthlly commission.png";
import loyalcustomers from "../assets/images/loyal costumer.png";
import Navbar from "../components/Navbar";

const Partnerps = () => {
  return (
    <div className="font-poppinsRegular ">
          <Navbar backgroundColour={'bg-custom-color1'}/>

      {/* 1st section */}
      <div className=" w-[100%]  flex items-center justify-center bg-custom-color1 text-white">
        <div className="container flex items-center m-auto justify-center w-[85%]">
          {/* First Column (Paragraph Section) */}
          <div className="md:w-[100%] w-[100%]    my-10 h-[100%]  flex justify-between items-center flex-col  md:flex-row">
            <div className="md:w-[50%] md:h-[85%] w-[100%] h-96   xs:p-[1rem] md:p-[0px] md:text-2xl ">
              <p className="text-black-700 font-poppinsRegular  leading-12  md:text-7xl text-5xl">
                <b>Finepay</b>
                <br /> <b>Partner</b>
                <br />
                <b className="text-sky-400/75 leading-9 font-poppinsRegular">Program</b>
              </p>
              <div>
                <p className="leading-9 pt-[1rem]">
                  Earn upto <b className=" text-yellow-100 font-poppinsRegular"> ₹2 Lakhs</b>{" "}
                </p>
                <p className="font-poppinsRegular">in commissions with Finepay</p>
              </div>

              <div className="w-[100%] flex  h-[50%] items-center gap-[1rem] xs:flex-row  ">
                <button className="font-poppinsRegular mt-[1rem] w-40 font-bold  bg-custom-color4 h-12 rounded-lg text-white md:text-2xl text-center   xs:text-sm  tracking-tight">
                  Join now
                </button>

                <div className="md:w-[45%] flex  h-[50%] items-center gap-[1rem] xs:flex-row ">
                  <button className="font-poppinsRegular mt-[1rem] text-white bg-richblack-300 w-32 h-12 rounded-lg text-center md:text  text-2xl xs:text-sm  tracking-tight ">
                    Login
                  </button>
                </div>
              </div>
            </div>
            {/* Second Column (Image Section) */}
            <div className="md:w-[48%]    w-[100%] flex justify-center    md:h-[100%]  h-[50%]">
              <img src="./images/partnerskr.webp" alt="twsimage" style={{objectFit:"contain"}}/>
            </div>
          </div>
        </div>
      </div>

      {/* 2nd section */}
      <div className=" bg-custom-color2  text-white py-20   w-[100%">
        {/* Paragraph Section */}
        <div className="   md:w-[70%]  pb-10  w-[80%] text-center  m-auto items-center flex flex-col gap-3  justify-center">
          <h1 className="font-bold md:text-5xl text-2xl font-poppinsRegular">
            Who Can be a FinePay Partner?
          </h1>
        </div>
        {/* Items for greed */}
        <div className="w-[90%]  md:w-[70%] grid md:grid-cols-4 grid-cols-2 md:grid-rows-1 xs:grid-rows-2 items-center text-center m-auto  gap-7">
          <div className="">
            <div className="  bg-[#2e2942]  md:w-40   md:h-40  w-36 h-36 p-[1rem]  rounded-2xl  items-center  md:textwhiteer m-auto  flex     justify-center text-white   ">
              <img
                src="./images/Wealth Managers@2x.png"
                className=" rounded-lg h-[85%] w-[85%] m-auto "
                alt=""
                style={{objectFit:"contain"}}
              />
            </div>
            <p className=" font-bold p-[1rem] text-xl  font-poppinsRegular">Wealth Managers</p>
          </div>
          <div className=" ">
            <div className="  md:w-40   md:h-40  w-36 h-36 bg-[#2e2942] rounded-2xl  p-[1rem]   items-center  text-center m-auto  flex     justifywhiteer text-white   ">
              <img
                src="./images/AMFI Distributors@2x.png"
                className="rounded-lg h-[85%] w-[85%] m-auto"
                alt=""
                style={{objectFit:"contain"}}
              />
            </div>
            <p className=" font-bold text-xl p-[1rem] font-poppinsRegular">AMFI Distributors</p>
          </div>
          <div className="  ">
            <div className="   md:w-40   md:h-40  w-36 h-36 rounded-2xl bg-[#2e2942]   p-[1rem] items-center  text-center m-auto  flex     justify-center text-white   ">
              <img
                src="./images/Financial Advisors@2x.png"
                className="rounded-lg h-[90%] w-[90%]  "
                alt=""
                style={{objectFit:"contain"}}
              />
            </div>
            <p className=" font-bold p-[1rem] text-xl font-poppinsRegular">Financial Advisors</p>
          </div>
          <div className=" ">
            <div className="   md:w-40   md:h-40  w-36 h-36  rounded-2xl bg-[#2e2942]   p-[1rem]  items-center  text-center m-auto  flex     justify-center text-white   ">
              <img
                src="./images/Insurance Sellers@2x.png"
                className="rounded-lg h-[90%] w-[90%]  "
                alt="usmk"
                style={{objectFit:"contain"}}
              />
            </div>
            <p className=" font-bold p-[1rem] text-xl font-poppinsRegular">Insurance Sellers</p>
          </div>
        </div>
      </div>

      {/* 3rd section */}
      <div className="   py-20  bg-custom-color1 text-white ">
        <div className="md:w-[85%] w-[100%] m-auto  ">
          {/* Paragraph Section */}
          <div className=" md:w-[75%]  w-[82%] text-center  m-auto items-center flex flex-col gap-3  justify-center">
            <h1 className="font-bold md:text-5xl  text-3xl font-poppinsRegular">
              What does a <b className="  text-yellow-100">Finepay</b> Partner
              do?
            </h1>
          </div>

          <div className="md:w-[60%]  w-[100%] md:h-[92%]    m-auto  flex items-center  justify-cente">
            <div className=" md:w-[100%]   items-center      flex justify-between  flex-col    md:flex-row ">
              {/* Image Icons */}
              {/* <div>
                <div className=" md:w-[100%] w-[88%] h-[50%]">
                  <img src="./images/coinsk.webp" alt="Icon " />
                </div>
              </div> */}
              <div className="  m-auto   md:w-[80%] w-[85%]   ">
                {/* Paragraph Section */}
                <p className=" md:text-3xl text-center font-bold p-[1rem] text-xl md:leading-10 font-poppinsRegular">
                  Tell your Clients and partners to invest in FinePay and earn
                  upto 12% in returns with our P2P asset and Digital Gold
                </p>
                <p className=" md:text-xl text-center text-sm md:leading-10 p-[1rem] font-bold font-poppinsRegular">
                  Ask them to signup with your refrral code and track your
                  commissions on the Partner dashboard
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 4th section */}
      <div className=" bg-custom-color2 text-white ">
        <div className="w-[85%] m-auto">
          <Partnerslider />
        </div>

        <div className="h-max-content py-20 bg-custom-color2  flex flex-col  items-center justify-center w-[100%]">
          <div className="w-[85%] ">
            <div className=" m-auto text-white my-10 text-center flex flex-col gap-3 items-center justify-center">
              <div className=" w-[90%] ">
                <h2 className="text-3xl md:text-4xl text-center  text-gray-400 font-poppinsRegular">
                  Join our Growth Story{" "}
                  <b className="text-yellow-100">and Become Our Partner</b>
                </h2>
              </div>
            </div>

            <div className=" text-black m-auto  flex justify-center gap-10  flex-col md:flex-row  ">

              {/* First Div */}
              <div className="rounded-lg  h-[240px]    bg-custom-color1 text-white   items-center flex flex-col py-6 md:w-[38%]  text-center">
                <div className="   w-28    items-center  text-center m-auto  flex     justify-center     ">
                  <img
                    src={signupPartnership}
                    className="rounded-lg h-[100px] w-[70%] "
                    alt="serchfileic"
                    style={{objectFit:"contain"}}
                  />
                </div>

                <p className="p-[1rem]  w-[100%] font-poppinsRegular">
                  Sign up for FinePay Partnership Program
                </p>
              </div>

              {/* Second Div */}
              <div className="rounded-lg  h-[240px] bg-custom-color1 text-white items-center flex flex-col md:w-[38%] py-6 text-center">
                <div className="   w-28    items-center  text-center m-auto  flex     justify-center  ">
                  <img
                    src={refer}
                    className="rounded-lg h-[110px] w-[80%]"
                    alt="serchfileic"
                    style={{objectFit:"contain"}}
                  />
                </div>

                <p className="p-[0.8rem]  w-[100%] font-poppinsRegular">
                  Refer
                  <br />
                  investors/borrowers to FinePay
                </p>
              </div>

              {/* Third Div */}
              <div className="rounded-lg  h-[240px]   bg-custom-color1 text-white   items-center flex flex-col  md:w-[38%] py-6  text-center">
                <div className="   w-28      pb-[1rem]   items-center  text-center m-auto  flex     justify-center    ">
                  <img
                    src={commissions}
                    className="rounded-lg h-[110px] w-[80%]"
                    alt="serchfileic"
                    style={{objectFit:"contain"}}
                  />
                </div>

                <p className="p-[0.2rem] w-[100%]  font-poppinsRegular">
                  Earn commissions on the investment/borrowing done by referred
                  customers
                </p>
              </div>

              {/* fourth div */}
              <div className="rounded-lg  h-[240px]  bg-custom-color1 text-white   items-center flex flex-col  md:w-[38%] py-6  text-center">
                <div className="   w-28      pb-[1rem]   items-center  text-center m-auto  flex     justify-center    ">
                  <img
                    src={loyalcustomers}
                    className="rounded-lg h-[110px] w-[80%] "
                    alt="serchfileic"
                    style={{objectFit:"contain"}}
                  />
                </div>

                <p className="p-[0.2rem] w-[100%]  font-poppinsRegular">
                  Create a oiik of happy and loyal customers by fulfilling their
                  requirement
                </p>
              </div>
            </div>

            <div>
              <div className=" md:w-[85%]  w-[100%]   m-auto text-white text-center  my-10 flex flex-col gap-3 md:flex-row items-center justify-center">
                <div>
                  <button className="mt-[1rem] font-poppinsRegular md:w-48 md:h-12  w-60 h-16 font-bold  bg-custom-color4  rounded-lg text-white md:text-2xl text-center   text-2xl  tracking-tight">
                    Register Now
                  </button>
                </div>
                <div>
                  <button className="mt-[1rem] font-poppinsRegular md:w-48 md:h-12 w-60 h-16 font-bold  bg-custom-color4  rounded-lg text-white md:text-2xl text-center   text-2xl  tracking-tight">
                    Log in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 5th section  table*/}
      <div className="w-[100%] py-10  bg-custom-color1">
        <div className=" md:w-[53%] w-[100%] md:h-48   flex items-center   m-auto justify-between flex-col  md:flex-row ">
          {/* Paragraph Section */}
          <div>
            <h1 className="md:text-4xl text-white text-3xl   text-center p-[2rem] font-poppinsRegular">
              Become a FinePay Partner
            </h1>
          </div>
          <button className="font-poppinsRegular mt-[1rem] md:w-40   w-44 h-16 font-bold  bg-custom-color4  rounded-lg text-white md:text-2xl text-center   text-2xl  tracking-tight">
            Join us
          </button>
        </div>
      </div>
    </div>
  );
};

export default Partnerps;
