import React from "react";
// import blogBanner from "../assets/images/blogBanner.jpg";
import BlogBanner from "../assets/images/blogBanner.png";
import { useNavigate } from "react-router-dom";
import kuberLogo from "../assets/images/fine.png";


const BlogCardSquare = ({img}) => {

  const navigate = useNavigate();


  const handleBlog = () => {
    navigate("/blogdetails");
    window.scrollTo(0, 0);
  };

  return (
    <div className="h-[300px] md:w-[300px]  rounded-lg shadow-lg">
      <div className="h-[45%] w-[100%] bg-white rounded-t-lg">
        <img
          src={img}
          alt="blog banner"
          className="h-[100%] w-[100%] rounded-t-lg"
          style={{objectFit:"fill"}}
        />
      </div>
      
      <div className="h-[55%] w-[100%] bg-richblack-900 p-[1rem] rounded-b-lg">
        <p className="text-white font-poppinsRegular  text-md font-semibold">
        Dive into Digital Gold: Your Complete Investment Handbook
        </p>
        <div className="mt-[2rem] h-[35%] items-center flex  justify-between">
          <div>
            <img src={kuberLogo} alt="kplogo" className="h-10 w-10" style={{objectFit:"contain"}}/>
            <p className=" font-poppinsRegular text-pure-greys-200 text-sm pl-2">
              Wed Feb 28 2024
            </p>
          </div>
          <button
            onClick={handleBlog}
            className="p-[0.4rem] font-poppinsRegular rounded-lg bg-custom-color1 text-white"
          >
            Read More
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogCardSquare;
