import React, { useState } from "react";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import kuberPeLogo from "../assets/images/fine.png";

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const openPlayStore = () => {
        // URL for your app on the Play Store
        // const playStoreUrl = "http://app.kalash.app/refer/SRK892";
        // window.open(playStoreUrl, "_blank");
    };

    const isHomeRoute = location.pathname === "/";

    const [showTooltip, setShowTooltip] = useState(false);
    const [showPlayTooltip, setPlayShowTooltip] = useState(false);

    const handleNavigate = (type) => {
        switch (type) {
            case "Home":
                navigate("/");
                window.scrollTo(0, 0);
                break;
            case "About-us":
                navigate("/aboutus");
                window.scrollTo(0, 0);
                break;
            case "Digital-Gold":
                navigate("/dgold");
                window.scrollTo(0, 0);
                break;
            case "Gold-Pro":
                navigate("/goldpro");
                window.scrollTo(0, 0);
                break;
            case "Fine-Plus":
                navigate("/kalash-plus");
                window.scrollTo(0, 0);
                break;
            case "Gold-Plus":
                navigate("/gold-plus");
                window.scrollTo(0, 0);
                break;
            case "Fine-X":
                navigate("/finex");
                window.scrollTo(0, 0);
                break;
            case "Carriers":
                navigate("/careers");
                window.scrollTo(0, 0);
                break;
            case "What is Digital Gold":
                navigate("/digitalgold");
                window.scrollTo(0, 0);
                break;
            case "Partner-Program":
                navigate("partnerprogram");
                window.scrollTo(0, 0);
                break;
            case "Check gold price":
                navigate("/check-live-gold-price");
                window.scrollTo(0, 0);
                break;
            case "FAQ":
                navigate("/faq");
                window.scrollTo(0, 0);
                break;
            case "Terms":
                navigate("/terms");
                window.scrollTo(0, 0);
                break;
            case "Privacy-Policy":
                navigate("/privacypolicy");
                window.scrollTo(0, 0);
                break;
            case "Refund":
                navigate("/refunds");
                window.scrollTo(0, 0);
                break;
            case "Blogs":
                navigate("/blogs");
                window.scrollTo(0, 0);
                break;
            case "contactsupport":
                navigate("/contactsupport");
                window.scrollTo(0, 0);
                break;
            case "Ismspolicy":
                navigate("/ismspolicy");
                window.scrollTo(0, 0);
                break;
            case "Grievanceredressal":
                navigate("/grievanceredressal");
                window.scrollTo(0, 0);
                break;
            case "Removeaccount":
                navigate("/delete_account");
                window.scrollTo(0, 0);
                break;

            case "goldsip":
                navigate("/goldsip");
                window.scrollTo(0, 0);
                break;
            case "dailysavings":
                navigate("/dailysavings");
                window.scrollTo(0, 0);
                break;
            case "emi-gyan":
                navigate("/emi-gyan");
                window.scrollTo(0, 0);
                break;
            case "sitemap":
                navigate("/sitemap");
                window.scrollTo(0, 0);
                break;
            case "disclaimer":
                navigate("/disclaimer");
                window.scrollTo(0, 0);
                break;
            case "spotplatform":
                navigate("/spot-platform");
                window.scrollTo(0, 0);
                break;
            default:
                navigate("/");
                window.scrollTo(0, 0);
                break;
        }
    };

    return (
        <footer className=" bg-richblack-900  text-white">
            <div className="w-[85%] m-auto pt-[2rem]">
                <div className=" flex flex-col md:flex-row md:justify-between gap-10 border-t border-b border-richblue-500 font-poppinsRegular">
                    <div className="md:w-[50%] xs:w-full text-left">
                        {/* logo */}
                        <div
                            onClick={() => handleNavigate("Home")}
                            className="mt-5"
                        >
                            <div className="h-[100%] md:w-[18%] w-[100%] cursor-pointer flex items-center justify-left">
                                <img
                                    src={kuberPeLogo}
                                    alt="Kalash"
                                    className="h-[100%] w-[200px]"
                                    style={{ objectFit: "contain" }}
                                />
                                <span
                                    style={{ fontSize: 60, color: "#f1a61e" }}
                                ></span>
                            </div>
                        </div>

                        {/* content */}
                        <div className="md:w-[62%] h-32 flex items-center text-[#717171]">
                            <h2>
                                FinePay, a platform used to encourage savings
                                habits in Indians by helping them save on a
                                daily basis.
                            </h2>
                        </div>

                        {/* social media */}
                        <div className="flex md:w-[70%] w-full gap-7">
                            <div className="">
                                <a
                                    href="https://www.instagram.com/kalash_app/"
                                    target="blank"
                                >
                                    {" "}
                                    <FaInstagram
                                        style={{
                                            height: "40px",
                                            width: "40px",
                                        }}
                                    />
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://www.facebook.com/kalashnow/"
                                    target="blank"
                                >
                                    {" "}
                                    <FaFacebook
                                        style={{
                                            height: "40px",
                                            width: "40px",
                                        }}
                                    />
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://www.linkedin.com/company/kalashapp/"
                                    target="blank"
                                >
                                    {" "}
                                    <FaLinkedin
                                        style={{
                                            height: "40px",
                                            width: "40px",
                                        }}
                                    />
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://twitter.com/kalash_media"
                                    target="blank"
                                >
                                    {" "}
                                    <FaTwitter
                                        style={{
                                            height: "40px",
                                            width: "40px",
                                        }}
                                    />
                                </a>
                            </div>

                            <div>
                                <a
                                    href="https://www.youtube.com/@Kalash_app"
                                    target="blank"
                                >
                                    {" "}
                                    <FaYoutube
                                        style={{
                                            height: "40px",
                                            width: "40px",
                                        }}
                                    />
                                </a>
                            </div>
                        </div>

                        {/* google play apple */}
                        <div className=" w-[100%] md:w-[80%] h-20 flex gap-3 my-5 items-center cursor-pointer">
                            <div
                                onMouseEnter={() => setPlayShowTooltip(true)}
                                onMouseLeave={() => setPlayShowTooltip(false)}
                                className="h-12 w-60 border rounded-lg flex items-center justify-center"
                            >
                                <img
                                    src="./images/playstore.png"
                                    alt="google"
                                    className="w-10 h-10"
                                    style={{ objectFit: "contain" }}
                                />
                                <div className="text-white leading-3">
                                    <p className="md:text-sm text-xs text-gray-400">
                                        Get It On
                                    </p>
                                    <p className="md:text-sm text-xs">
                                        Google Play
                                    </p>
                                </div>
                                {showPlayTooltip && (
                                    <span className="absolute bg-[#7745ff] text-white text-md py-1 px-6 rounded-md mt-24 ml-44 opacity-100 transition-opacity duration-300">
                                        Comming Soon....
                                    </span>
                                )}
                            </div>

                            <div
                                className="h-12 w-60 border rounded-lg flex items-center justify-center"
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                            >
                                <FaApple
                                    style={{ height: "30px", width: "30px" }}
                                />
                                <div className="text-white leading-3">
                                    <p className="md:text-sm text-xs text-gray-400">
                                        Download on{" "}
                                    </p>
                                    <p className="md:text-sm text-xs">
                                        App Store
                                    </p>
                                </div>
                                {showTooltip && (
                                    <span className="absolute bg-[#7745ff] text-white text-md py-1 px-6 rounded-md mt-24 ml-44 opacity-100 transition-opacity duration-300">
                                        Comming Soon....
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* all pages */}
                    <div className="flex lg:justify-around  py-5 justify-between w-full md:w-[50%] leading-8 ">
                        <div className="text-left">
                            <div className="text-2xl">Finepay</div>
                            <a
                                href="aboutus"
                                onClick={() => handleNavigate("About-us")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white  "
                            >
                                About Us
                            </a>
                            <a
                                href="dgold"
                                onClick={() => handleNavigate("Digital-Gold")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white   "
                            >
                                Digital Gold
                            </a>

                            <a
                                href="goldpro"
                                onClick={() => handleNavigate("Gold-Pro")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white   "
                            >
                                Gold Pro
                            </a>
                            <a
                                href="kalash-plus"
                                onClick={() => handleNavigate("Fine-Plus")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white   "
                            >
                                Fine Plus
                            </a>
                            <a
                                href="gold-plus"
                                onClick={() => handleNavigate("Gold-Plus")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white   "
                            >
                                Gold Plus
                            </a>
                            <a
                                href="finex"
                                onClick={() => handleNavigate("Fine-X")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white   "
                            >
                                Fine X
                            </a>
                            <a
                                href="goldsip"
                                onClick={() => handleNavigate("goldsip")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white   "
                            >
                                Gold SIP
                            </a>
                            <a
                                href="careers"
                                onClick={() => handleNavigate("Carriers")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white   "
                            >
                                Careers
                            </a>
                        </div>

                        <div className="text-left">
                            <div className="text-2xl">Explore</div>
                            {/* <a
                                href="instantloans"
                                onClick={() => handleNavigate("Instant-Loans")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white"
                            >
                                Instant Loans
                            </a> */}
                            <a
                                href="digitalgold"
                                onClick={() =>
                                    handleNavigate("What is Digital Gold")
                                }
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white"
                            >
                                What is Digital Gold ?
                            </a>
                            <a
                                href="partnerprogram"
                                onClick={() =>
                                    handleNavigate("Partner-Program")
                                }
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white"
                            >
                                Partner Programs
                            </a>
                            <a
                                href="check-live-gold-price"
                                onClick={() =>
                                    handleNavigate("Check gold price")
                                }
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white"
                            >
                                Check Gold Price
                            </a>
                            <a
                                href="dailysavings"
                                onClick={() => handleNavigate("dailysavings")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white"
                            >
                                Daily Saving
                            </a>
                            <a
                                href="emi-gyan"
                                onClick={() => handleNavigate("emi-gyan")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white"
                            >
                                EMIygyan
                            </a>
                            <a
                                href="faq"
                                onClick={() => handleNavigate("FAQ")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white"
                            >
                                FAQS
                            </a>
                            <a
                                href="blogs"
                                onClick={() => handleNavigate("Blogs")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white"
                            >
                                Blogs
                            </a>
                        </div>
                    </div>
                </div>

                {/* address div */}
                <div className="border-b border-richblue-500 md:mt-6 md:pb-0 pb-[0.5rem] flex flex-col md:flex-row md:justify-between gap-10 md:h-32 font-poppinsRegular">
                    <div className="md:w-[50%] w-[100%] ">
                        <div className="text-left ">
                            <h1 className="text-xl py-2">Address</h1>
                            <h2 className="text-[#717171] text-sm">
                                <div
                                    style={{
                                        fontSize: 18,
                                        fontWeight: "900",
                                        color: "#776",
                                    }}
                                >
                                    <strong>WeWork Manyata Redwood</strong>
                                </div>
                                Embassy Manyata Business Park, Block D3,
                                Nagavara, Bengaluru, Karnataka, India-560045
                            </h2>
                        </div>
                    </div>
                    <div className=" flex md:justify-around justify-between w-full md:w-[50%] leading-8 ">
                        <div className="text-left ">
                            <a
                                href="privacypolicy"
                                onClick={() => handleNavigate("Privacy-Policy")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white "
                            >
                                Privacy Policy
                            </a>
                            <a
                                href="terms"
                                onClick={() => handleNavigate("Terms")}
                                className="block text-[#717171] hover:text-white cursor-pointer  active:text-white "
                            >
                                Terms of Use
                            </a>
                            {isHomeRoute && (
                                <a
                                    href="refunds"
                                    onClick={() => handleNavigate("Refund")}
                                    className="block text-[#717171] hover:text-white cursor-pointer  active:text-white "
                                >
                                    Refund Policy
                                </a>
                            )}
                        </div>

                        <div className="text-left">
                            <div>
                                <a
                                    href="contactsupport"
                                    onClick={() =>
                                        handleNavigate("contactsupport")
                                    }
                                    className="block text-[#717171] hover:text-white cursor-pointer  active:text-white "
                                >
                                    Contact Support
                                </a>
                            </div>
                            <div>
                                <a
                                    href="ismspolicy"
                                    onClick={() => handleNavigate("Ismspolicy")}
                                    className="text-[#717171] hover:text-white cursor-pointer  active:text-white "
                                >
                                    ISMS Policy
                                </a>
                            </div>
                            <div>
                                <a
                                    href="grievanceredressal"
                                    onClick={() =>
                                        handleNavigate("Grievanceredressal")
                                    }
                                    className="text-[#717171] hover:text-white cursor-pointer  active:text-white"
                                >
                                    Grievance Redressal
                                </a>
                            </div>
                            <div>
                                <a
                                    href="delete_account"
                                    onClick={() =>
                                        handleNavigate("Removeaccount")
                                    }
                                    className="text-[#717171] hover:text-white cursor-pointer  active:text-white"
                                >
                                    Remove Account
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" md:h-22 font-poppinsRegular md:py-2 py-4">
                    <div className="flex items-center md:justify-end justify-between w-[100%] gap-[1.5rem]">
                        <div>
                            <a
                                href="sitemap"
                                onClick={() => handleNavigate("sitemap")}
                                className="text-[#717171] hover:text-white cursor-pointer  active:text-white "
                            >
                                SiteMap
                            </a>
                        </div>
                        <div>
                            <a
                                href="disclaimer"
                                onClick={() => handleNavigate("disclaimer")}
                                className="text-[#717171] hover:text-white cursor-pointer  active:text-white"
                            >
                                Disclaimer
                            </a>
                        </div>
                        <div>
                            <a
                                href="spot-platform"
                                onClick={() => handleNavigate("spotplatform")}
                                className="text-[#717171] hover:text-white cursor-pointer  active:text-white"
                            >
                                SPOT Platform
                            </a>
                        </div>
                    </div>
                    <div className="py-[1rem]">
                        <p className="text-[#717171] text-center mt-[2rem] md:mt-0">
                            © KALASH FINESTREAM INVESTMENT PRIVATE LIMITED
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
