import React, { useState } from "react";
import inkpotImg from "../assets/images/inkPot.svg";
import BlogCardSquare from "../components/BlogCardSquare";
import BlogCardRactangle from "../components/BlogCardRactangle";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../components/Navbar";
import blogBanner1 from "../assets/web/blog/reasonInvestGold.png";
import blogBanner2 from "../assets/web/blog/planingtobuyDigitalGold.png";
import blogBanner3 from "../assets/web/blog/DigitalgoldVSGoldETF.png";
import blogBaneer4 from "../assets/web/blog/DiveintoDigitalgold.png";

const btn = [
    {
        id: 1,
        text: "All Categories",
    },
    {
        id: 2,
        text: "Investing",
    },
    {
        id: 3,
        text: "Finance",
    },
    {
        id: 4,
        text: "Saving",
    },
    {
        id: 5,
        text: "Digital Gold",
    },
    {
        id: 6,
        text: "Taxes",
    },
];

const blogArr = [
    {
        id: 1,
        img: blogBanner1,
    },
    {
        id: 2,
        img: blogBanner2,
    },
    {
        id: 3,
        img: blogBanner3,
    },
    {
        id: 4,
        img: blogBaneer4,
    },
    {
        id: 5,
        img: blogBanner3,
    },
    {
        id: 6,
        img: blogBaneer4,
    },
    {
        id: 7,
        img: blogBanner2,
    },
    {
        id: 8,
        img: blogBanner3,
    },
    {
        id: 9,
        img: blogBanner2,
    },
    {
        id: 10,
        img: blogBaneer4,
    },
    {
        id: 11,
        img: blogBanner2,
    },
    {
        id: 12,
        img: blogBanner3,
    },
];
const blogArr2 = [
    {
        id: 1,
        img: blogBanner1,
    },
    {
        id: 2,
        img: blogBanner2,
    },
    {
        id: 3,
        img: blogBanner3,
    },
    {
        id: 4,
        img: blogBaneer4,
    },
    {
        id: 5,
        img: blogBanner3,
    },
];

const Blog = () => {
    const [currBtnClicked, setCurrBtnClicked] = useState("All Categories");

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        // centermode:true,
        autoplay: true, // Autoplay enabled
        autoplaySpeed: 3000, // Adjust as needed
        cssEase: "linear", // Ensures smooth animation
        pauseOnHover: true, // Pause autoplay on hover
        className: "mx-auto",
        // responsive: [
        //   {
        //     breakpoint: 1024, // Medium screens
        //     settings: {
        //       slidesToShow: 2, // Show 2 items on medium screens
        //     },
        //   },
        //   {
        //     breakpoint: 700, // Extra-small screens
        //     settings: {
        //       slidesToShow: 1, // Show 1 item on extra-small screens
        //     },
        //   },
        // ],
    };

    return (
        <div className="font-poppinsRegular">
            <Navbar backgroundColour={"bg-custom-color1"} />
            {/* header box of blog */}
            <div className="md:h-[70vh] h-[100vh] bg-custom-color1 flex flex-col  items-center justify-center w-[100%]">
                <div className="md:w-[85%] w-[90%] md:h-[92.7%] h-[70%] flex flex-col md:flex-row items-center justify-between">
                    <div className="md:h-[100%] h-[40%] md:flex items-center justify-center md:w-[49%] w-[100%]">
                        <div className="md:h-[50%] h-[100%] w-[100%]">
                            <p className="font-poppinsRegular md:text-2xl text-xl text-white">
                                FinePay Blog
                            </p>
                            <p className="md:text-6xl text-3xl font-poppinsRegular text-white mt-[1rem]">
                                Fingyan by <br /> FinePay
                            </p>
                            <p className="font-poppinsRegular text-white mt-[1rem]">
                                The one-stop destination for everything you need
                                to know in the world of finance
                            </p>
                        </div>
                    </div>
                    {/* inkpot img */}
                    <div className="md:h-[100%] h-[70%] flex items-center justify-center md:w-[49%] w-[100%]">
                        <img
                            src={inkpotImg}
                            alt="Inkpot"
                            className="h-[80%] w-[80%]"
                            style={{ objectFit: "contain" }}
                        />
                    </div>
                </div>
                {/* tab */}
                <div className="md:h-[8%] h-[26%] w-[100%] flex items-center justify-center ">
                    <div className="hidden md:flex h-[100%] w-[85%] items-center justify-between">
                        {btn.map((button, ind) => {
                            return (
                                <button
                                    key={button.id}
                                    onClick={() =>
                                        setCurrBtnClicked(button.text)
                                    }
                                    className={`rounded-t-lg font-poppinsRegular ${
                                        currBtnClicked === button.text
                                            ? "bg-custom-color2"
                                            : "bg-custom-color1"
                                    } hover:bg-custom-color2 text-white font-bold py-2 px-4 transition-colors duration-300`}
                                >
                                    {button?.text}
                                </button>
                            );
                        })}
                    </div>

                    <div className="md:hidden xs:flex h-[100%] md:w-[85%] w-[95%] flex flex-wrap items-center justify-between">
                        {btn.map((button, ind) => {
                            return (
                                <button
                                    key={button.id}
                                    onClick={() =>
                                        setCurrBtnClicked(button.text)
                                    }
                                    className={`rounded-t-lg font-poppinsRegular rounded-md ${
                                        currBtnClicked === button.text
                                            ? "bg-custom-color2"
                                            : "bg-custom-color1"
                                    } hover:bg-custom-color2 text-white font-bold py-2 px-4 transition-colors duration-300`}
                                >
                                    {button?.text}
                                </button>
                            );
                        })}
                    </div>
                </div>
            </div>

            {/* blogs content section */}
            <div className="h-max-content flex items-center justify-center w-[100%] bg-custom-color2">
                <div className="w-[85%] h-max-content">
                    {/* blog carousel */}
                    <div className="h-max-content mx-auto flex flex-row items-center justify-center bg-custom-color2 w-[100%]  mt-[2rem]">
                        <div className="md:w-[80%] w-[95%]">
                            <Slider {...settings} style={{ marginLeft: "2%" }}>
                                {blogArr2.map((el, ind) => {
                                    return (
                                        <BlogCardRactangle
                                            key={ind}
                                            img={el.img}
                                        />
                                    );
                                })}
                            </Slider>
                        </div>
                    </div>

                    <div className="w-[100%] mt-[5rem] mb-[5rem] flex items-center justify-between flex-wrap gap-[2rem] h-max-content">
                        {blogArr?.map((el, ind) => {
                            return <BlogCardSquare key={ind} img={el.img} />;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog;
