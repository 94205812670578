import React, { Suspense } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Layout from './Layout';
import Home from './Home';
import Blog from './Blog';
import FaqPage from './Faqs/FaqPage';
import Terms from './Terms';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Refund from './Refund';
import ILoans from './ILoans';
import Partnerps from './Partnerps';
import DGold from './DGold';
import CheckGoldPrice from './CheckGoldPrice';
import GoldPlus from './GoldPlus';
import Career from './Careers/Career';
import Apply from './Apply/Apply';
import JobDetails from './Apply/JobDetails';
import Form from './Apply/Form';
import Digitalgold from './Digitalgold';
import Goldpro from './Goldpro';
import ContactSupport from './ContactSupport';
import IsmsPolicy from './IsmsPolicy';
import Grievance from './Grievance';
import KuberJI from './KuberJI';
import BlogDetails from '../components/BlogDetails';
import KuberPlus from './KuberPlus';
import GoldSIP from './GoldSIP';
import DailySavings from './DailySavings';
import EMIHero from './EMIHero';
import SiteMap from './SiteMap';
import Disclaimer from './Disclaimer';
import SpotPlatform from './SpotPlatform';
import GoldsipMain from '../components/GoldsipMain';
import Goldsipsecond from '../components/Goldsipsecond';
import Delete from '../pages/Delete';
// const Home = React.lazy(() => import('./Home'));
const AboutUsLazyLoading = React.lazy(() => import('../pages/Aboutus'));
// const Delete = React.lazy(() => import('../pages/Delete'));

const router = createBrowserRouter([
  {
    path: '',
    element: <Layout />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/blogs', element: <Blog /> },
      { path: '/faq', element: <FaqPage /> },
      { path: '/terms', element: <Terms /> },
      { path: '/privacypolicy', element: <PrivacyPolicy /> },
      { path: '/refunds', element: <Refund /> },
      { path: '/instantloans', element: <ILoans /> },
      { path: '/partnerprogram', element: <Partnerps /> },
      {
        path: '/aboutus',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AboutUsLazyLoading />
          </Suspense>
        ),
      },
      { path: '/dgold', element: <DGold /> },
      { path: '/check-live-gold-price', element: <CheckGoldPrice /> },
      { path: '/gold-plus', element: <GoldPlus /> },
      { path: '/careers', element: <Career /> },
      { path: '/apply', element: <Apply /> },
      { path: '/applyjobs/:id', element: <JobDetails /> },
      { path: '/form', element: <Form /> },
      { path: '/digitalgold', element: <Digitalgold /> },
      { path: '/goldpro', element: <Goldpro /> },
      { path: '/contactsupport', element: <ContactSupport /> },
      { path: '/ismspolicy', element: <IsmsPolicy /> },
      { path: '/grievanceredressal', element: <Grievance /> },
      { path: '/finex', element: <KuberJI /> },
      { path: '/blogdetails', element: <BlogDetails /> },
      { path: '/kalash-plus', element: <KuberPlus /> },
      { path: '/goldsip', element: <GoldSIP /> },
      { path: '/dailysavings', element: <DailySavings /> },
      { path: '/emi-gyan', element: <EMIHero /> },
      { path: '/sitemap', element: <SiteMap /> },
      { path: '/disclaimer', element: <Disclaimer /> },
      { path: '/spot-platform', element: <SpotPlatform /> },
      { path: '/goldsipmain', element: <GoldsipMain /> },
      { path: '/goldsipsecond', element: <Goldsipsecond /> },
    ],
  },
  {
    path: '/delete_account',
    element: <Delete />
  },
]);

export default router;
