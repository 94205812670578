import React, { useState, useEffect } from 'react';

function ScrollToTopFeature({ children }) {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let scrollTimer;

    function handleScroll() {
      setIsScrolling(true);
      clearTimeout(scrollTimer);
      
      scrollTimer = setTimeout(() => {
        setIsScrolling(false);
      }, 30000); // Adjust the time here (10 seconds)
    }

    function handleInactiveScroll() {
      if (!isScrolling && window.scrollY > 0) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }

    window.addEventListener('scroll', handleScroll);

    const interval = setInterval(handleInactiveScroll, 1000); // Check every second for inactivity

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(interval);
      clearTimeout(scrollTimer);
    };
  }, [isScrolling]);

  return <>{children}</>;
}

export default ScrollToTopFeature;
