import React, { useEffect, useState } from 'react';
import faqData from "../pages/Faqs/Faq.json";
import FaqMiddle from "../components/FaqMiddle";
import Slider from "react-slick";

const DailyFaq = () => {
    const [isAutoplay, setIsAutoplay] = useState(true);
    const [hideArrows, setHideArrows] = useState(false);
  
    useEffect(() => {
      const handleResize = () => {
        // Disable autoplay for medium (md) screens and above
        setIsAutoplay(window.innerWidth < 768);
      };
  
      handleResize(); // Initial check
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    useEffect(() => {
      const handleResize = () => {
        setHideArrows(window.innerWidth >= 1024); // Hide arrows for medium and larger screens
      };
  
      handleResize(); // Initial check
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: isAutoplay,
      autoplaySpeed: 3000,
      arrows: !hideArrows, // Show arrows unless hideArrows is true
  
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 2,
            prevArrow: false, // Hide arrows for md screens
            nextArrow: false, // Hide arrows for md screens
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            prevArrow: false, // Hide arrows for md screens
            nextArrow: false, // Hide arrows for md screens
          },
        },
      ],
    };
  
    const { AutoSave } = faqData;
    const { DigitalGold } = faqData;
    const { GoldPlus } = faqData;
    const { GoldPro } = faqData;
    const { FineX } = faqData;
    const { FinePlus } = faqData;
  
    const [autoSave] = useState(AutoSave);
    const [goldP] = useState(GoldPlus);
    const [digitalGold] = useState(DigitalGold);
    const [goldPro] = useState(GoldPro);
    const [kuberJi] = useState(FineX);
    const [kuberPlus] = useState(FinePlus);
  
    const [queChange, setQueChabge] = useState(AutoSave);
  return (
    <div>
         <section className="md:w-[85%] w-[90%] m-auto py-10 font-poppinsRegular">

<h1 className="md:text-6xl text-2xl font-poppinsBold text-center p-14">
  All your <span className="text-[#ffda2d]">questions</span>, answered.
</h1>

<div className="my-10 m-auto">
  <Slider {...settings} style={{paddingLeft:"5%"}}>
    <div>
      <button
        id="contentmarket"
        value="contentmarketing"
        onClick={() => {
          setQueChabge(autoSave);
        }}
        className="h-12 w-32 text-lg md:font-poppinsBold rounded-lg bg-custom-color1 border border-[#7745ff] hover:bg-[#7745ff] focus:bg-[#7745ff]"
      >
        AutoSave
      </button>
    </div>
    <div>
      <button
        id="communicate"
        value="communication"
        onClick={() => {
          setQueChabge(goldP);
        }}
        className="h-12 w-32 text-lg md:font-bold rounded-lg bg-custom-color1 border border-[#7745ff] hover:bg-[#7745ff] focus:bg-[#7745ff]"
      >
        GoldPlus
      </button>
    </div>
    <div>
      <button
        id="promotion"
        value="promotional"
        onClick={() => {
          setQueChabge(digitalGold);
        }}
        className="h-12 w-32 text-lg md:font-bold rounded-lg bg-custom-color1 border border-[#7745ff] hover:bg-[#7745ff] focus:bg-[#7745ff]"
      >
        DigitalGold
      </button>
    </div>
    <div>
      <button
        id="people"
        value="people"
        onClick={() => {
          setQueChabge(goldPro);
        }}
        className="h-12 w-32 text-lg md:font-bold rounded-lg bg-custom-color1 border border-[#7745ff] hover:bg-[#7745ff] focus:bg-[#7745ff]"
      >
        GoldPro
      </button>
    </div>
    <div>
      <button
        id="local"
        value="localization"
        onClick={() => {
          setQueChabge(kuberJi);
        }}
        className="h-12 w-32 text-lg md:font-bold rounded-lg bg-custom-color1 border border-[#7745ff] hover:bg-[#7745ff] focus:bg-[#7745ff]"
      >
        FineX
      </button>
    </div>
    <div>
      <button
        id="local"
        value="localization"
        onClick={() => {
          setQueChabge(kuberPlus);
        }}
        className="h-12 w-32 text-lg md:font-bold rounded-lg bg-custom-color1 border border-[#7745ff] hover:bg-[#7745ff] focus:bg-[#7745ff]"
      >
        FinePlus
      </button>
    </div>
  </Slider>
</div>

<div className="">
  {queChange.map((item, i) => {
    return <FaqMiddle item={item} />;
  })}
</div>

</section>
    </div>
  )
}

export default DailyFaq