import React, { useState } from "react";
import FaqMiddle from "../components/FaqMiddle";
import { useNavigate } from "react-router-dom";
import faqData from "./Faqs/Faq.json";
import BlogDGold from "../components/BlogDGold";
import Presentingold1 from "../assets/web/digital gold/dgmainkalash.png";
import Mask from "../assets/images/Mask Group.svg";
import secure from "../assets/images/secure.svg";
import buy from "../assets/images/buy.svg";
import Navbar from "../components/Navbar";
import LazyImage from "../components/LazyImage";

const DGold = () => {
  const navigate = useNavigate();
  const { DigitalGold } = faqData;
  const [digitalGold] = useState(DigitalGold);

  const handleNavigate = (type) => {
    if (type === "faq") {
      navigate("/faq");
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="bg-richblack-900 text-white">
      <Navbar backgroundColour={'bg-richblack-900'}/>
      <div className="w-[85%] m-auto font-poppinsRegular ">

        {/* first section */}
        <div className=" items-center pt-10 flex flex-col md:flex-row justify-between pb-16">
          <div className="md:w-[50%] ">
            <h2 className="md:text-5xl text-3xl font-poppinsBold text-center">
              Presenting to you the{" "}
              <span className="text-[#FFDA2D]">modern way</span> of investing in
              gold
            </h2>
          </div>
          <div className=" md:w-[50%] w-[100%] items-center flex justify-end">
          <LazyImage src={Presentingold1} alt={"Presentingold"}/>
            {/* <img src="./images/Presentingold.png" alt="D-gold" className=""/> */}
          </div>
        </div>

        {/* second section  */}
        <div className="bg-custom-color1 text-white  md:p-24 p-4 text-center rounded-t-3xl md:rounded-3xl w-[100%] ">
          <h1 className="text-2xl font-poppinsSemibold text-[#7745ff] text-center pb-8">
            What is digital gold?
          </h1>
          <h2 className="md:text-3xl text-xl font-poppinsSemibold text-center">
            Digital Gold is New-Age version of Buying{" "}
            <span className="text-[#7745ff]">24k Pure Gold online</span> and it
            is Stored in secure Lockers by our Partner{" "}
            <span className="text-[#7745ff]">Augmont</span> . You can buy{" "}
            <span className="text-[#014A32]">99.5% Pure</span>{" "}
            <span className="text-[#7745ff]">24k Gold Coins</span> and get
            delivered to your doorstep whenever you want.{" "}
          </h2>
        </div>
      </div>

      {/* third section */}
      <div className="bg-custom-color1 text-white font-poppinsRegular mt-24">
        <div className="w-[85%] m-auto ">
          <h2 className="md:text-5xl text-3xl font-poppinsBold text-white text-center pt-10">
            Advantages of <span className="text-[#7745ff]">digital gold.</span>
          </h2>

          <div class="items-center flex flex-col md:flex-row gap-4 justify-around py-10">
            <div className="p-5 w-80 md:h-48 rounded-lg bg-[#2e2942] items-center flex flex-row gap-2 md:flex-col justify-center">
              <img
                src={Mask}
                alt="Mask Group"
                className=""
                style={{objectFit:"contain"}}
              />
              <h2 className="text-xl font-medium md:text-center">
                <span className="text-[#7745ff]">Buy,sell or transfer</span>{" "}
                with utmost ease
              </h2>
            </div>
            <div className="p-5 w-80 md:h-48  rounded-lg bg-[#2e2942] items-center flex flex-row gap-2 md:flex-col justify-center">
              <img src={secure} alt="secure" className="" style={{objectFit:"contain"}}/>
              <h2 className="text-xl font-medium md:text-center">
                It is <span className="text-[#7745ff]">totally secure</span> and
                fraud resistant
              </h2>
            </div>
            <div className="p-5 w-80 md:h-48 rounded-lg bg-[#2e2942] items-center flex flex-row gap-2 md:flex-col justify-center">
              <img src={buy} alt="buy" className="" style={{objectFit:"contain"}}/>
              <h2 className="text-xl font-medium h-[50%] md:text-center">
                Anyone can buy, starting{" "}
                <span className="text-[#7745ff]">as low as ₹10</span>
              </h2>
            </div>
          </div>
        </div>
      </div>

      {/* forth section */}
      <div className="w-[85%] m-auto font-poppinsRegular ">
        <div className="">
          <h2 className="md:text-5xl text-3xl font-poppinsBold py-24 text-center">
            How Does It Work?
          </h2>
        </div>
        <div className="flex justify-between flex-col md:flex-row md:gap-8 gap-16 relative h-max-content">
          <div className="flex flex-col items-center justify-center rounded-3xl bg-[#2e2942] pb-10">
            <div className="relative p-3 flex bottom-11 bg-richblack-900 items-center rounded-full">
              <img
                src="./images/automated syastem.svg"
                alt="automated syastem"
                style={{objectFit:"contain"}}
              />
              
            </div>
            <LazyImage src={"./images/cards.png"} alt={"cards"}/>
            {/* <img src="./images/cards.png" alt="cards" className="max-w-full" /> */}
            <div className="px-[1rem]">
            <h1 className="text-2xl my-2 text-center font-poppinsBold">
              Automated System
            </h1>
            <h2 className="text-lg text-gray-600 text-center">
              save everyday, consistently. choose an amount & never miss saving
              again
            </h2>
            </div>
           
          </div>

          <div className="flex flex-col items-center justify-center rounded-3xl bg-[#2e2942] pb-10">
            <div className="relative p-3 flex bottom-11 bg-richblack-900 items-center rounded-full">
              <img src="./images/Group 190698.svg" alt="automated syastem" style={{objectFit:"contain"}} />
            </div>
            <div className="px-[1rem]">
            {/* <LazyImage src={"./images/save.png"} alt={"save"}/> */}
            <img src="./images/save.png" alt="save " className="max-w-full" style={{objectFit:"contain"}}/>
            <h1 className="text-2xl my-2 text-center font-poppinsBold">
              Many ways to save
            </h1>
            <h2 className="text-lg text-gray-600 text-center">
              Daily,weekly or monthly Save the way you like!
            </h2></div>
          </div>

          <div className="flex flex-col items-center justify-center rounded-3xl bg-[#2e2942] pb-10">
            <div className="relative p-3 flex bottom-11 bg-richblack-900 items-center rounded-full">
              <img src="./images/Group1 202606.svg" alt="automated syastem" style={{objectFit:"contain"}}/>
            </div>
            <img
              src="./images/withdraw.png"
              alt="withdraw"
              className="max-w-full"
              style={{objectFit:"contain"}}
            />
            <div className="px-[1rem]">
            <h1 className="text-2xl my-2 text-center font-poppinsBold">
              Withdraw anytime
            </h1>
            <h2 className="text-lg text-gray-600 text-center">
              Pause or cancel saving anytime. Get your money back, no questions
              asked
            </h2></div>
          </div>
        </div>
      </div>

      {/* fifth section */}
      <div className="w-[85%] m-auto mt-24 font-poppinsRegular ">
        <div className="pb-10">
          <h2 className="md:text-5xl text-3xl font-poppinsBold text-center">
            We guarantee <span className="text-[#FFDA2D]">100% security</span>{" "}
            in the process <span className="text-[#FFDA2D]">.</span>
          </h2>
        </div>
        <div
          className=" w-[70%]  m-auto hidden md:grid"
          style={{
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
            gap: "1rem",
            gridTemplateAreas: `
        "header header sidebar"
        " main footer sidebar"
      `,
          }}
        >
          <div
            className="bg-custom-color1 items-center flex gap-5 rounded-3xl p-4"
            style={{ gridArea: "header" }}
          >
            <img src="./images/store.svg" alt="" style={{objectFit:"contain"}}/>
            <h2 className="text-xl">
              Your gold is started in a100% secure vault.
            </h2>
          </div>
          <div
            className="bg-custom-color1 rounded-3xl p-6"
            style={{ gridArea: "sidebar" }}
          >
            <h2 className="text-xl text-center  py-5">
              <span className="text-[#FFDA2D]">Plan</span> karo, invest karo,
              Grow karo!
            </h2>
            <img src="./images/UTTAM KUMAR copy.png" alt="" className="rounded-3xl h-48 m-auto" style={{objectFit:"contain"}}/>
          </div>
          <div
            className="bg-custom-color1 rounded-3xl p-4 items-center flex flex-col justify-center"
            style={{ gridArea: "main" }}
          >
            <img
              src="./images/augmontKalash.png"
              alt=""
              className="h-14 rounded-xl"
              style={{objectFit:"contain"}}
            />
            <h2 className="text-xl">Trusted gold partner</h2>
          </div>
          <div
            className="bg-custom-color1 rounded-3xl p-4 items-center flex flex-col justify-center"
            style={{ gridArea: "footer" }}
          >
            <img src="./images/npci.svg" alt="" style={{objectFit:"contain"}}/>
            <h2 className="text-xl">Trusted payments</h2>
          </div>
        </div>
        {/* small screen */}
        <div
          className="w-[100%] grid  m-auto md:hidden"
          style={{
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            gap: "1rem",
            gridTemplateAreas: `
        "header header"
         "main footer" 
         "sidebar sidebar"
      `,
          }}
        >
          <div
            className="bg-custom-color1 p-4 items-center  flex gap-5 rounded-3xl"
            style={{ gridArea: "header" }}
          >
            <img src="./images/store.svg" alt="" style={{objectFit:"contain"}}/>
            <h2 className="text-sm">
              Your gold is started in a100% secure vault.
            </h2>
          </div>
          <div
            className="bg-custom-color1 h-28 p-4 items-center  flex gap-5 rounded-3xl"
            style={{ gridArea: "sidebar" }}
          >
            <img src="./images/UTTAM KUMAR copy.png" alt="" className="h-[90%] rounded-xl" style={{objectFit:"contain"}}/>
            <h2 className="text-sm text-left font-poppinsSemibold">
              <span className="text-[#FFDA2D]">Plan</span> karo, invest karo,
              Grow karo!
            </h2>
          </div>
          <div
            className="bg-custom-color1 p-4 rounded-3xl"
            style={{ gridArea: "main" }}
          >
            <h2 className="text-sm">Trusted gold partner</h2>
            <img src="./images/augmontKalash.png" alt=""  className="w-32 h-10 rounded-xl" style={{objectFit:"contain"}}/>
          </div>
          <div
            className="bg-custom-color1 p-4 rounded-3xl"
            style={{ gridArea: "footer" }}
          >
            <h2 className="text-sm ">Trusted payments</h2>
            <img src="./images/npci.svg" alt="" style={{objectFit:"contain"}} />
          </div>
        </div>
      </div>

      {/* sixth section blogs */}
      <BlogDGold />

      {/* seventh section faq */}
      <div className="w-[85%] m-auto  font-poppinsRegular mt-24 pb-20">
        <div className="w-[100%]">
          <div className="items-center w-[100%]  flex flex-col gap-3  justify-center">
            <h1 className="md:text-5xl text-2xl font-poppinsBold text-center">
              All your <span className="text-[#ffda2d]">questions</span>,
              answered.
            </h1>
          </div>

          <div className="text-left mt-10">
            {digitalGold.map((item) => (
              <FaqMiddle item={item} />
            ))}
          </div>
          <div className="h-12 w-48 m-auto">
            <button
              onClick={() => {
                handleNavigate("faq");
              }}
              className="text-lg rounded-lg bg-custom-color1 border border-[#7745ff] h-[100%] w-[100%] hover:bg-[#7745ff] active:bg-[#7745ff] font-poppinsRegular"
            >
              Learn More
            </button>
          </div>
        </div>
      </div>


    </div>
  );
};

export default DGold;
