import React, { useState } from "react";

import { FaApple } from "react-icons/fa";
import FaqMiddle from "../components/FaqMiddle";
import faqData from "../pages/Faqs/Faq.json";
import {useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import BlogDGold from "../components/BlogDGold";
import Presentingold1 from "../assets/images/Presentingold1.png";


const Digitalgold = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate=useNavigate();
  const { DigitalGold } = faqData;
  const [digitalGold] = useState(DigitalGold);


  const openPlayStore = () => {
    // URL for your app on the Play Store
    const playStoreUrl = "http://app.kalash.app/refer/SRK892";
    window.open(playStoreUrl, "_blank");
  };

  const handleNavigate=(type)=>{
    if(type==='blog'){
    navigate("/blogs");
    window.scrollTo(0, 0);
    }else if(type==='faq'){
      navigate('/faq')
      window.scrollTo(0,0);
    }
  }


  return (
    <div className="bg-richblack-900 text-white w-full font-poppinsRegular overflow-x-hidden">
          <Navbar backgroundColour={'bg-richblack-900'}/>

      {/* 1st section */}
      <div className="md:w-[85%] w:[100%] pt-5 pb-20 font-poppinsRegular m-auto  container flex items-center justify-center ">
        {/* First Column (Paragraph Section) */}
        <div className="m-auto ">
        <div className=" items-center flex justify-between  flex-col  md:flex-row">
          {/* First Column (Paragraph Section) */}
          <div className="md:w-[52%]">
            <div className=" text-white text-2xl">
              <p>MAXIMIZE YOUR WEALTH</p>
            </div>
            <p className="text-black-700 my-6  text-3xl xs:text-center   text-white leading-12 md:text-5xl">
              Grow with
              <br /> <b className="text-yellow-100">industry leading</b>
              <br /> returns
            </p>
            {/* google play apple */}
            <div className="lg:w-[80%] w-[100%] flex gap-3 items-center cursor-pointer">
    
                <div onClick={openPlayStore}  className="h-16 text-white border rounded-lg flex items-center justify-center w-[50%]">
                  <img
                    src="./images/playstore.png"
                    alt="google"
                    className="w-10 h-10"
                    style={{objectFit:"contain"}}
                  />
                  <div className="text-white leading-3">
                    <p className="text-sm text-gray-400">Get It On</p>
                    <p className="text-md">Google Play</p>
                  </div>
                </div>
                
              <div
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                className="h-16 border text-white rounded-lg flex items-center justify-center w-[50%]"
              >
                <div className="text-white">
                  <FaApple style={{ height: "30px", width: "30px" }} />
                </div>
                <div className="text-white leading-3">
                  <p className="text-sm text-gray-400">Download on the</p>
                  <p className="text-md">App Store</p>
                </div>
              </div>
              {showTooltip && (
                <span className="absolute bg-[#7745ff] text-white text-md py-1 px-6 rounded-md mb-24 ml-80 opacity-100 transition-opacity duration-300">
                  Comming Soon....
                </span>
              )}
            </div>
          </div>

          {/* Second Column (Image Section) */}
          <div className="md:w-[48%] w-full flex justify-center h-100% ">
            <img src={Presentingold1} alt="twsimage" style={{objectFit:"contain"}}/>
          </div>
        </div>
      </div>
      </div>

      {/* 2nd section */}
      <div className="w-[85%] m-auto font-poppinsRegular  pb-20">
        <div className="">
          <h2 className="md:text-6xl text-3xl font-poppinsBold text-center text-white mb-20">
            How Does It Work?
          </h2>
        </div>
        <div className="flex justify-between flex-col md:flex-row md:gap-8 gap-16 relative">
          <div className="flex flex-col items-center justify-center  rounded-3xl bg-custom-color1 text-white pb-10">
            <div className="relative p-3 flex bottom-11 bg-richblack-900 items-center rounded-full">
              <img
                src="./images/automated syastem.svg"
                alt="automated syastem"
                style={{objectFit:"contain"}}
              />
            </div>
            <img src="./images/cards.png" alt="cards" className="w-[100%]" style={{objectFit:"contain"}}/>
            <h1 className="text-2xl my-2 text-center font-poppinsBold">
              Automated System
            </h1>
            <h2 className="text-lg text-gray-600 text-center">
              save everyday, consistently. choose an amount & never miss saving
              again
            </h2>
          </div>

          <div className="flex flex-col items-center justify-center rounded-3xl bg-custom-color1 text-white pb-10">
            <div className="relative p-3 flex bottom-11 bg-richblack-900 items-center rounded-full">
              <img src="./images/Group 190698.svg" alt="automated syastem" style={{objectFit:"contain"}}/>
            </div>
            <img src="./images/save.png" alt="save " className="w-[100%]" style={{objectFit:"contain"}}/>
            <h1 className="text-2xl my-2 text-center font-poppinsBold">
              Many ways to save
            </h1>
            <h2 className="text-lg text-gray-600 text-center">
              Daily,weekly or monthly Save the way you like!
            </h2>
          </div>

          <div className="flex flex-col items-center justify-center rounded-3xl bg-custom-color1 text-white pb-10">
            <div className="relative p-3 flex bottom-11 bg-richblack-900 items-center rounded-full">
              <img src="./images/Group1 202606.svg" alt="automated syastem" style={{objectFit:"contain"}}/>
            </div>
            <img
              src="./images/withdraw.png"
              alt="withdraw"
              className="w-[100%]"
              style={{objectFit:"contain"}}
            />
            <h1 className="text-2xl my-2 text-center font-poppinsBold">
              Withdraw anytime
            </h1>
            <h2 className="text-lg text-gray-600 text-center">
              Pause or cancel saving anytime. Get your money back, no questions
              asked
            </h2>
          </div>
        </div>
      </div>

      {/* 3rd section */}
      <div className=" bg-custom-color2 ">
        {/* First Column (Paragraph Section) */}
        <div className="items-center w-[85%] m-auto flex justify-between flex-col gap-10 md:flex-row  py-20">
          <div className="md:w-[52%]  lg:text-6xl">
            <div className=" text-white md:text-4xl text-2xl xs:text-center font-poppinsBold  my-5">
              <p>
                In association with India's
                <br />
                biggest gold player
              </p>
            </div>
            <p className="text-black-700 md:h-48 h-40 text-xl xs:text-center   text-white leading-12 md:text-3xl mt-5">
              Augmont is among India's most reputed refinery & bullion companies
              with deep inroads in the close-knit jeweller community
            </p>
            {/*  BIS NABL */}
            <div className="md:w-[60%] w-[100%] h-20  flex gap-5 items-center cursor-pointer">
              <div className="h-16 md:w-36 w-[48%] border p-[0.3rem] text-white rounded-lg flex items-center justify-center">
                <img
                  src="./images/bis.webp"
                  alt="google"
                  className="w-10 h-10"
                  style={{objectFit:"contain"}}
                />
                <div className="text-white leading-3 ">
                  <p className="text-lg  text-gray-400 text-center font-poppinsBold">
                    BIS
                  </p>
                  <p className="md:text-sm text-lg">Hallmarked</p>
                </div>
              </div>
              <div className="h-16 md:w-36 w-[48%] border p-[0.5rem] text-white rounded-lg flex items-center  justify-center">
                <div className="text-white">
                  <img
                    src="./images/NABL.webp"
                    alt="google"
                    className="w-10 h-10"
                    style={{objectFit:"contain"}}
                  />
                </div>
                <div className="text-white   leading-3">
                  <p className="text-lg text-gray-400 text-center font-poppinsBold">
                    NABL
                  </p>
                  <p className="md:text-sm text-lg">Accredited</p>
                </div>
              </div>
            </div>
          </div>

          {/* Second Column (Image Section) */}
          <div className="md:w-[48%] w-[100%]  ">
            <div className="flex justify-center">
              <div className="">
                <img
                  src="./images/what is digital goldaggmont-coin-animation2.gif"
                  alt="twsimage"
                  className=" md:h-80 h-48 md:w-[96%]  ml-10 md:mt-0 mt-10 hidden md:flex"
                  style={{objectFit:"contain"}}
                />
              </div>
              {/* <img
                src="./images/AugCard.png"
                alt="twsimage"
                className=" md:h-80 h-[230px]"
              /> */}
            </div>
          </div>
        </div>
      </div>

      {/* 4th section blogs*/}
      <BlogDGold />

      {/* fifth section how gold work */}

      <div className="w-[100%] mt-24 py-20 rounded-3xl  bg-custom-color2  ">
        <div className=" text-white flex flex-col gap-3 items-center justify-center">
          <h2 className=" text-2xl font-poppinsBold md:text-5xl text-center text-gray-400">
            How does Gold+ works?
          </h2>
        </div>

        <div className=" w-[85%]  flex md:h-[380px] h-[950px] justify-between items-center m-auto flex-col md:flex-row ">
          <div>
            <img
              src="./images/wallet@2x.png"
              alt="twsimage"
              className=" md:h-48 md:w-60  h-[220px] "
              style={{objectFit:"contain"}}
            />

            <div className=" text-center text-white font-poppinsBold">
              <p>Your Wallet</p>
            </div>
          </div>

          <div className=" text-xl text-white text-center">
            24k Gold is <b className=" text-yellow-100">securely stored</b> with
            Augmont
          </div>

          <div>
            <img
              src="./images/Coinnew.png"
              alt="twsimage"
              className=" md:h-56  md:w-72  h-[220px] "
              style={{objectFit:"contain"}}
            />

            <div className=" text-center text-white font-poppinsBold">
              <p>AUGMONT</p>
            </div>
          </div>

          <div className="text-xl text-white text-center">
            <p>
              {" "}
              Gold is leased to India's most
              <b className=" text-yellow-100"> trusted jewellers</b>
            </p>
          </div>

          <div>
            <img
              src="./images/Jewellers@2x.png"
              alt="twsimage"
              className=" md:h-48 md:w-60  h-[220px] "
              style={{objectFit:"contain"}}
            />

            <div className=" text-center text-white font-poppinsBold">
              <p> Jewellers</p>
            </div>
          </div>
        </div>

        <div className="flex justify-center  text-center  text-2xl text-white">
          <h2>
            <b className=" text-yellow-100 ">Upto 5% assured returns </b> is
            credited to your Wallet
          </h2>
        </div>
      </div>

      {/* sixth section faq */}
      <div className="w-[85%] m-auto text-white font-poppinsRegular">
        <div className="w-[100%] py-20">
          <div className="items-center text-white w-[100%]  flex flex-col gap-3  justify-center pb-10">
            <h1 className="md:text-4xl text-2xl font-poppinsBold text-center ">
              All your <span className="text-[#ffda2d] ">questions</span>,
              answered.
            </h1>
          </div>

          <div className="text-left text-white">
            {digitalGold.map((item) => (
              <FaqMiddle item={item} className="text-white" />
            ))}
          </div>
         
            <div className="h-12 w-48 m-auto">
              <button onClick={()=>{handleNavigate('faq')}} className="text-lg rounded-lg bg-custom-color1 border border-[#7745ff] h-[100%] w-[100%] hover:bg-[#7745ff] active:bg-[#7745ff]">
                Learn More
              </button>
            </div>
         
        </div>
      </div>
    </div>
  );
};

export default Digitalgold;
