import React from "react";
import Navbar from "../components/Navbar";
import LazyImage from "../components/LazyImage";
import CuratedOpportunities from "../assets/images/Curated Opportunities.png";
import topImg from "../assets/web/SPOT Platform/on top (main)fod google ads.png";
import img1 from "../assets/web/SPOT Platform/Open Account6fod google ads.png";
import img2 from "../assets/web/SPOT Platform/Buy at Live Pricesfod google ads.png";
import img3 from "../assets/web/SPOT Platform/Take Delivery8fod google ads.png";
import map from "../assets/web/SPOT Platform/mapfod google ads.png";
import icon1 from "../assets/web/SPOT Platform/Benchmarkfod google ads.png";
import icon2 from "../assets/web/SPOT Platform/No Brokerage fod google ads.png";
import icon3 from "../assets/web/SPOT Platform/Assured Purity & Weightfod google ads.png";
import icon4 from "../assets/web/SPOT Platform/Secured Platformfod google ads.png";
// import IndiaMap from "../components/IndiaMap";

const SpotPlatform = () => {
  return (
    <div className="bg-richblack-900  text-white">
      <Navbar backgroundColour={"bg-richblack-900"} />

      {/* section main */}
      <div className="lg:w-[85%] m-auto py-20 ">
        {/* first setion */}
        <div className="items-center flex lg:flex-row flex-col md:gap-10 gap-5 font-poppinsRegular">
          {/* get instant loans */}
          <div className="lg:w-[50%] w-[100%] lg:text-left text-center">
            <h1 class="font-poppinsBold md:text-5xl text-3xl leading-[4rem] md:leading-[4rem] lg:leading-[5rem]">
              India’s favourite large bars platform for Gold Investment
            </h1>
          </div>
          {/* loans image */}
          <div className="lg:w-[50%] w-[100%] lg:h-[400px]">
            <LazyImage src={topImg} alt={"instnt"} />
          </div>
        </div>

        {/* second secction */}
        <div className="">
          <h1 className="md:text-4xl text-2xl text-brown-200 text-center py-12 font-poppinsSemibold">
            FEATURES
          </h1>
          <div className="lg:w-[100%] md:w-[90%] m-auto">
            <div className="items-center flex md:flex-row flex-col gap-14">
              <div className=" md:w-32 w-28 h-28 md:h-32">
                <img
                  src={icon1}
                  alt=""
                  className="h-[100%] w-[100%]"
                />
              </div>
              <div className=" text-center md:text-left md:w-[80%] w-[90%]">
                <h1 className="md:text-3xl text-xl">Benchmark Prices</h1>
                <h2 className="md:text-xl text-sm">
                  SPOT prices are the most competitive and benchmark prices
                  across India’s jewellery markets
                </h2>
              </div>
            </div>

            <div className="items-center flex md:flex-row flex-col gap-14 mt-10">
              <div className=" md:w-32 w-28 h-28 md:h-32">
                <img
                  src={icon2}
                  alt=""
                  className="h-[100%] w-[100%]"
                />
              </div>
              <div className=" text-center md:text-left md:w-[80%] w-[90%]">
                <h1 className="md:text-3xl text-xl">No Brokerage</h1>
                <h2 className="md:text-xl text-sm">
                  SPOT replaces the bullion market experience in electronic
                  market, hence there is no brokerage or terminal usage charge
                </h2>
              </div>
            </div>

            <div className="items-center flex md:flex-row flex-col gap-14 mt-10">
              <div className=" md:w-32 w-28 h-28 md:h-32">
                <img
                  src={icon3}
                  alt=""
                  className="h-[100%] w-[100%]"
                />
              </div>
              <div className=" text-center md:text-left md:w-[80%] w-[90%]">
                <h1 className="md:text-3xl text-xl">Assured Purity & Weight</h1>
                <h2 className="md:text-xl text-sm">
                  SPOT delivery bars follow LBMA & India Good Delivery standards
                  and hence there is assurance in purity and weight
                </h2>
              </div>
            </div>

            <div className=" items-center flex md:flex-row flex-col gap-14 mt-10">
              <div className=" md:w-32 w-28 h-28 md:h-32">
                <img
                  src={icon4}
                  alt=""
                  className="h-[100%] w-[100%]"
                />
              </div>
              <div className=" text-center md:text-left md:w-[80%] w-[90%]">
                <h1 className="md:text-3xl text-xl">Secured Platform</h1>
                <h2 className="md:text-xl text-sm">
                  SPOT platform is available on web, iOS, Android and exe. Its
                  uses highest security protocols to secure trades, money
                  transfers and accounts
                </h2>
              </div>
            </div>
          </div>
        </div>
        </div>


        {/* 3rd section */}
        <div className="bg-custom-color1 py-24">
          <div className="md:w-[85%] m-auto w-[90%]">
          <h1 className="md:text-4xl text-2xl text-brown-200 text-center pb-12 font-poppinsSemibold">
            HOW IT WORKS
          </h1>

          <div className="tems-center flex flex-col lg:flex-row justify-around gap-5 ">
            <div className="bg-richblack-900 lg:w-[30%] w-[100%] h-max-content rounded-3xl p-8 items-center flex  justify-center flex-col gap-10">
              <div className="w-28 h-[40%] items-center flex  justify-center"><img
                  src={img1}
                  className="rounded-lg "
                  alt="serchfileic"
                  style={{objectFit:"contain"}}
                /></div>
              <div className="h-[50%] text-center">
                <h1 className="text-2xl font-poppinsRegular font-semibold">Open Account</h1>
                <h2 className="text-lg font-poppinsRegular mt-[0.5rem]">Simply open an account with FinePay SPOT on www.kalash.app and complete your KYC. Deposit a nominal 1-2% margin money.</h2>
              </div>
            </div>

            <div className="bg-richblack-900 lg:w-[30%] w-[100%] h-max-content rounded-3xl p-8 items-center flex  justify-center flex-col gap-10">
              <div className="w-28 h-[40%] items-center flex  justify-center"><img
                  src={img2}
                  className="rounded-lg "
                  alt="serchfileic"
                  style={{objectFit:"contain"}}
                /></div>
              <div className="h-[50%] text-center">
                <h1 className="text-2xl font-poppinsRegular font-semibold">Buy at Live Prices</h1>
                <h2 className="text-lg font-poppinsRegular mt-[0.5rem]">Buy the gold or silver bar contract of the city that you want to take delivery in</h2>
              </div>
            </div>

            <div className="bg-richblack-900 lg:w-[30%] w-[100%] h-max-content rounded-3xl p-8 items-center flex  justify-center flex-col gap-10">
              <div className="w-28 h-[40%] items-center flex  justify-center"><img
                  src={img3}
                  className="rounded-lg "
                  alt="serchfileic"
                  style={{objectFit:"contain"}}
                /></div>
              <div className="h-[50%] text-center">
                <h1 className="text-2xl font-poppinsRegular font-semibold">Take Delivery</h1>
                <h2 className="text-lg font-poppinsRegular mt-[0.5rem]">Pay the balance and take T+1 or T+2 delivery at your opted delivery centre</h2>
              </div>
            </div>
            </div>
            
          
        </div>
      </div>


      <div className="min-h-screen flex flex-col justify-center items-center text-white m-auto py-24">
            <img src={map} style={{maxWidth:"100%",width:800}}/>
        </div>
    </div>
  );
};

export default SpotPlatform;
