import React from "react";
// import Router from "./Router";
import Footer from "../components/Footer";
import ScrollToTopFeature from "../components/ScrollToTopFeature";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Outlet } from "react-router-dom";
const Layout = () => {
    return (
        <>
            {/* <ScrollToTopFeature> */}
            {/* <Router /> */}
            <Outlet />
            <Footer />
            {/* </ScrollToTopFeature> */}
        </>
    );
};

export default Layout;
