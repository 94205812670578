import React from 'react';
import axios from 'axios';
const deleteAccount = async (body) => {
    try {
        const response = await axios.post(`https://cbc2-2401-4900-3dae-9660-cdae-f6ed-1191-ba3f.ngrok-free.app/api/v1/account/delete`, body);
        return response;
    } catch (error) {
        throw error;
    }
}
export { deleteAccount };