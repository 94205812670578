import React from 'react'

const GoldsipMain = () => {
  return (
    <div>
            
            <div className=" w-[100%]  flex items-center justify-center text-white">
        <div className="container flex items-center m-auto justify-center w-[85%]">
          {/* First Column (Paragraph Section) */}
          
          <div className="md:w-[100%] w-[100%]  h-[100%]  flex justify-between items-center flex-col  md:flex-row">
           
             {/* Second Column (Image Section) */}
            <div className="md:w-[48%]    w-[100%] flex justify-end    md:h-[100%]  h-[50%]">
              <img src="./images/partnerskr.webp" alt="twsimage" style={{objectFit:"contain"}}/>
            </div>
            
            <div className="md:w-[50%]  w-[100%] h-96    xs:p-[1rem] md:p-[0px] md:text-2xl ">
              
            
            <div className="  w-[60%]   mt-28  ">
                  <p className="text-lg h-16">24K Gold Coins & Bars delivered to your doorstep</p>
                  <p className="font-poppinsRegular text-sm">
                  Convert your digital gold to physical gold by paying a nominal minting charge. Your delivery comes with free insurance, to ensure your coins and bars reach you safely.


                  </p>
                </div>
            </div>
            
          </div>
        </div>
      </div>


    </div>
  )
}

export default GoldsipMain
