import React, { useState } from "react";
import { deleteAccount } from "../service/Api";
import Notiflix from "notiflix";
import { useNavigate } from "react-router-dom";
import "./Delete.css";
const Delete = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [reason, setReason] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here
        var body = {
            mobileNumber: mobile,
            email: email,
            name: name,
            reason: reason,
        };
        const response = await deleteAccount(body);
        Notiflix.Notify.success(response?.data?.message);
        navigate("/");
    };
    return (
        <div className="form-container">
            <div className="form-card">
                <h2 className="form-title">
                    Request Account Deletion for FinePay App
                </h2>
                <p className="form-link">
                    <a
                        href="https://play.google.com/store/apps/details?id=app.finepay.invest"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://play.google.com/store/apps/details?id=app.finepay.invest
                    </a>
                </p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            className="form-input"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Mobile:</label>
                        <input
                            type="tel"
                            className="form-input"
                            id="mobile"
                            value={mobile}
                            onChange={(e) =>
                                setMobile(e.target.value.replace(/\D/g, ""))
                            } // Remove non-numeric characters
                            pattern="^\d{10}$"
                            inputMode="numeric"
                            maxLength={10}
                            title="Please enter a valid 10-digit mobile number"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email Address:</label>
                        <input
                            type="email"
                            className="form-input"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="reason">Reason for Deletion:</label>
                        <textarea
                            className="form-input"
                            id="reason"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            placeholder="Optional"
                        />
                    </div>
                    <button type="submit" className="form-button">
                        Submit Request
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Delete;
