import React, { useEffect, useState } from 'react';
import "./checkGoldPrice.css";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, LineChart, Line } from 'recharts';
import Navbar from '../components/Navbar';

const CheckGoldPrice = () => {
    const [selectedYear, setSelectedYear] = useState("yearly");
    const [liveGoldPrice, setLiveGoldPriceData] = useState(null);
    const prevGoldPrice = JSON.parse(localStorage.getItem('prevGoldPrice'));


    const getGoldPrices = () => {
        let livePrice = parseFloat(liveGoldPrice.rates.gBuy)/2;
        
        let prevDayPrice = parseFloat(prevGoldPrice?.data.rates.gBuy)/2;
        // Calculate the percentage change
        const percentageChange = ((livePrice - prevDayPrice) / prevDayPrice) * 100;

        // Construct the array
        const goldPrice = [
            {
                gram: "0.5 gm",
                yesterdayPrice: `₹${prevDayPrice.toFixed(2)}`,
                todayPrice: `₹${livePrice.toFixed(2)}`,
                priceChange: `${percentageChange.toFixed(2)}%`
            },
            {
                gram: "1 gm",
                yesterdayPrice: `₹${(prevDayPrice * 2).toFixed(2)}`,
                todayPrice: `₹${(livePrice * 2).toFixed(2)}`,
                priceChange: `${percentageChange.toFixed(2)}%`
            },
            {
                gram: "5 gm",
                yesterdayPrice: `₹${(prevDayPrice * 10).toFixed(2)}`,
                todayPrice: `₹${(livePrice * 10).toFixed(2)}`,
                priceChange: `${percentageChange.toFixed(2)}%`
            },
            {
                gram: "10 gm",
                yesterdayPrice: `₹${(prevDayPrice * 20).toFixed(2)}`,
                todayPrice: `₹${(livePrice * 20).toFixed(2)}`,
                priceChange: `${percentageChange.toFixed(2)}%`
            }
        ];

        return goldPrice;
    }

    // Function to fetch Gold Price from the API
    const fetchData = async () => {
        try {
        const response = await fetch('https://api.kuberpe.com/api/user/goldRates');
        const jsonData = await response.json();
        // Check if data exists in localStorage and if it's less than 1 day old
        const storedData = JSON.parse(localStorage.getItem('prevGoldPrice'));
        if (!storedData || (Date.now() - storedData.timestamp >= 86400000)) { // 86400000 milliseconds = 1 day
            // Update stored data in localStorage
            localStorage.setItem('prevGoldPrice', JSON.stringify({ data: jsonData, timestamp: Date.now() }));
        }

        setLiveGoldPriceData(jsonData);
        } catch (error) {
          console.error('Error fetching Gold Price:', error);
        }
    };

    useEffect(() => {
        fetchData();
    
        const intervalId = setInterval(fetchData, 300000);
    
        return () => clearInterval(intervalId);
    }, []); 

    const openPlayStore = () => {
        // URL for your app on the Play Store
        const playStoreUrl = 'http://app.kalash.app/refer/SRK892';
        window.open(playStoreUrl, '_blank');
    };

    // last four year
    const getLastFourYears = () => {
        const currentDate = new Date();
        const lastFourYears = [];
      
        for (let i = 0; i < 4; i++) {
          lastFourYears.push(currentDate.getFullYear() - i);
        }
      
        return lastFourYears;
    };
      
    const lastFourYears = getLastFourYears();

    //last four month
    const getLastFourMonths = () => {
        const currentDate = new Date();
        const lastFourMonths = [];
      
        for (let i = 0; i < 4; i++) {
          const month = (currentDate.getMonth() - i + 12) % 12;
          lastFourMonths.push(month);
        }
      
        return lastFourMonths;
    };
      
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
      
    const lastFourMonthsNames = getLastFourMonths().map(monthIndex => months[monthIndex]);

    //last four week
    const getLastFourWeeks = () => {
        const currentDate = new Date();
        const lastFourWeeks = [];
        
        for (let i = 0; i < 4; i++) {
            const weekStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - (i * 7));
            lastFourWeeks.push(formatDate(weekStartDate));
        }
        
        return lastFourWeeks;
    };
      
    const formatDate = (date) => {
        const options = { month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
      
    const lastFourWeeks = getLastFourWeeks();
      
    
    const yearly = [
        {
          name:lastFourYears[3],
          value: 4.8
        },
        {
          name:lastFourYears[2],
          value: 5.5
        },
        {
          name:lastFourYears[1],
          value: 6.3
        },
        {
          name:lastFourYears[0],
          value: 7.1
        }
    ]
    
    const monthly = [
        {
          name:lastFourMonthsNames[3],
          value: 7.4
        },
        {
          name:lastFourMonthsNames[2],
          value: 7.5
        },
        {
          name:lastFourMonthsNames[1],
          value: 7.3
        },
        {
          name:lastFourMonthsNames[0],
          value: 7.5
        }
    ]

    const weekly = [
        {
          name:lastFourWeeks[3],
          value: 7.5
        },
        {
          name:lastFourWeeks[2],
          value: 7.3
        },
        {
          name:lastFourWeeks[1],
          value: 7.6
        },
        {
          name:lastFourWeeks[0],
          value: 7.1
        }
    ]
      
  return (
    <div>
    <Navbar backgroundColour={'bg-custom-color1'}/>

      <div className='md:h-max-content bg-custom-color1 flex flex-col  items-center justify-center w-[100%] font-poppinsRegular'>
        <div className='h-[100%] w-[85%]'>

            <div className='h-[20%] w-[100%] mt-[1rem]'>
                <p className='text-white md:text-5xl text-3xl tracking-wide font-poppinsRegular font-bold'>Gold Price Today</p>
                <div className="h-2 md:w-[35%] w-[100%] mt-[1rem] bg-gradient-to-l from-blue-200 to-blue-900"></div>
                <div className='mt-[1rem] flex items-center gap-[1rem]'>
                <div className='h-[60px] w-[60px] rounded-full bg-[#421951] flex items-center justify-center'>
                    <div className="w-3 h-3 relative">
                        <div className="absolute inset-0 rounded-full bg-red-500 animate-blink-wave"></div>
                    </div>
                </div>
                <p className='text-white md:text-3xl text-xl tracking-wide font-poppinsRegular font-semibold'>Live Gold Price in India</p>
                </div>
            </div>

            <div className='h-[34%] mt-[2rem] w-[100%] flex flex-col md:flex-row gap-[2rem]'>
                <div className='md:h-[278px] lg:h-[253px] h-[260px] md:w-[60%] lg:w-[50%] w-[100%] bg-richblack-900'>
                    <table className='text-sm text-white w-full'>
                        <thead>
                            <tr>
                                <th className="border border-white md:px-4 md:py-4 px-1 py-2">Grams</th>
                                <th className="border border-white md:px-4 md:py-4 px-1 py-2">24 Carat Gold - Yesterday</th>
                                <th className="border border-white md:px-4 md:py-4 px-1 py-2">24 Carat Gold - Today</th>
                                <th className="border border-white md:px-4 md:py-4 px-1 py-2">Daily Price Change</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                               liveGoldPrice && prevGoldPrice && getGoldPrices().map((el, ind) => {
                                    return (
                                        <tr key={ind}>
                                            <td className="border border-white md:px-4 md:py-1.6 lg:py-3 px-1 py-2.5">{el.gram}</td>
                                            <td className="border border-white md:px-4 md:py-1.6 lg:py-3 px-1 py-2.5">{el.yesterdayPrice}</td>
                                            <td className="border border-white md:px-4 md:py-1.6 lg:py-3 px-1 py-2.5">{el.todayPrice}</td>
                                            <td className="border border-white md:px-4 md:py-1.6 lg:py-3 px-1 py-2.5">{el.priceChange}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className='lg:h-[252px] md:h-[278px] h-[250px] lg:w-[30%] md:w-[50%] w-[100%] rounded-md bg-richblack-900'>
                    <div className='h-[20%] w-[100%] rounded-md flex items-center justify-around border-2'>
                        <button onClick={() => setSelectedYear("yearly")} className={`text-xs rounded-md font-bold py-1 px-2 ${selectedYear==="yearly" ? 'bg-blue-500 text-white' : 'bg-blue-200 text-blue-800'}  hover:bg-blue-500  hover:text-white transition-colors duration-300 ease-in-out`}>Yearly</button>
                        <button onClick={() => setSelectedYear("monthly")} className={`text-xs rounded-md font-bold py-1 px-2 ${selectedYear==="montly" ? 'bg-blue-500 text-white' : 'bg-blue-200 text-blue-800'}  hover:bg-blue-500  hover:text-white transition-colors duration-300 ease-in-out`}>Monthly</button>
                        <button onClick={() => setSelectedYear("daily")}  className={`text-xs rounded-md font-bold py-1 px-2 ${selectedYear==="daily" ? 'bg-blue-500 text-white' : 'bg-blue-200 text-blue-800'}  hover:bg-blue-500  hover:text-white transition-colors duration-300 ease-in-out`}>Weekly</button>
                    </div>
                    <div className='h-[80%] w-[100%] flex items-center justify-center'>
                            <ResponsiveContainer width="100%" height={200}>
                                <LineChart
                                    width={800}
                                    height={200}
                                    data={selectedYear === "yearly" ? yearly : (selectedYear === "monthly" ? monthly : weekly)}
                                    margin={{ top: 15, right: 30, left: 20,  bottom: 5 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} >
                                       <LabelList position="top" fill='#20afec' formatter={(value) => value + 'K'}/>
                                    </Line>
                                </LineChart>
                            </ResponsiveContainer>
                    </div>
                </div>
            </div>

            <div className='mt-[2rem] md:w-[60%] w-[100%] mb-[1rem]'>
                <p className='text-white text-sm font-poppinsRegular'>* The above gold rates are indicative and do not include GST, TCS and other levies. For the exact rates contact your local jeweller.</p>
                <p className='text-white mt-[2rem] text-md font-poppinsRegular'>FinePay gold price chart shows you the current price of gold in the professional gold market. You can then use those real-time prices to place an order using the FinePay app.</p>
                <button onClick={openPlayStore} class="text-xs mt-[3rem] md:py-2 md:px-16 py-2 px-12 bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 text-white rounded-lg transition-all duration-300 ease-in-out">
                    <span class='text-lg font-semibold'> Buy Gold Now</span> <br /> 99.5% 24k Pure Gold
                </button>
            </div>
        </div>
      </div>

      {/* text content */}
      <div className='md:h-max-content pt-[5rem] bg-custom-color1 flex flex-col  items-center justify-center w-[100%]'>
        <div className='h-[100%] w-[85%]'>
            <p className='text-white font-poppinsRegular'>
                            1.  Introduction
                Gold Price Tool allows you to see Gold prices in real time and make decisions accordingly. The chart is automatically refreshed and the price is updated every minute. Easily check the Gold price today along with its purity. You can specifically check for a 1 gram Gold rate and 24KT Gold price today as well. See the Gold price performance today, 6 months, 1 year, 3 years and 5 years for Indian Rupees.
                <br /><br />
                2.  How is the hallmarked gold rate today in India determined?
                Well, before you proceed to buy gold, there are some things that you need to be sure of. To own gold it is important to understand its purity, hallmarking, price and its making. Hallmark in gold or Hallmarked gold in India is nothing but a certificate of quality that is granted by the government of India (the Bureau of Indian Standards). It is important to note that there is no difference between the prices of normal gold and hallmarked gold. The difference lies in gold quality. There are no additional charges induced with hallmarked gold. 
                <br/><br />
                Hallmarked gold rates in India stand out as the gold that is bought should be of high quality. The BIS assesses the gold and checks if it meets the international standards of qualified gold and is pure too.
                <br/><br />
                3.  How can you be sure of hallmarked gold in India?
                There are certain features that are present in hallmarked gold which assures its quality. It is engraved and has inscriptions. Hallmarked gold has the BIS logo, retailer’s logo, logo of the assaying centre, the year of certification and also the purity level. The gold that is hallmarked is found in the purity of 24 carat, 23 carat, 22 carat, 21 carat and 18 carat.
                The difference in the number of carats is nothing but the total percentage of gold in the metal. The highest quality is 24 carat gold, that is totally pure. It is always advisable to buy gold that is BIS certified hallmarked gold.
                <br/><br />
                4.  Gold as an asset
                Gold is considered to be an exclusive item of luxury. Gold plays a pivotal role in terms of investments. Possessing gold is simpler when compared to the purchase of other tangible assets like real estate. It eases the burden of losses during market fluctuations. Gold is tangible and highly liquid in nature. Certain classes of assets are often associated with misuse or fraud, but gold is free from such concerns.
                ‍
                However, gold is a liquid asset and there is a ready market for it. One of the drawbacks of gold is that it is difficult to sell at the market price. But you will certainly find a buyer for gold. However, you can pledge the gold for a loan and pay interest.
                <br/><br />
                5.  What is Gold price today in India per gram?
                In India, gold has a sentimental significance attached to it. The yellow metal is associated with material wealth. Indian women like gold jewellery and can never have enough of it. Because gold is such an integral part of auspicious festivals, gold sales skyrocket around Diwali. The price of gold fluctuates on a daily basis; if you wish to acquire gold, start typing Put today's gold rate into the Google search box and perform a Google search. If you want to find the El Dorado, you must keep up with the current gold rate.
                <br/><br />
                Today's gold price is impacted by different factors: 
                <br/><br />
                1. Inflation - Gold is a secure investment and used as a hedge against inflation. Hence, the demand for gold rises when inflation is high, and vice versa. As a result of the increasing customer demand, the price of gold will increase.
                <br/><br />
                2. Government Gold Reserves - The price of gold rises as large countries' central banks begin to retain gold reserves and purchase more gold. This is because the market's cash flow increases when the supply of gold decreases.
                <br/><br />
                3. Jewellery Market - Gold prices rise throughout the wedding season, as well as during festivals like Diwali, as a result of increased consumer demand. Prices rise as a result of the demand-supply imbalance.
                <br/><br />
                4. Interest Rate Trends  - Customers are more likely to sell gold to obtain cash when interest rates rise, hence an increase in gold supply leads to lower gold rates. Lower interest rates, on the other hand, result in more cash in the hands of customers, resulting in increased demand for gold and, as a result, an increase in the metal's price.
                <br/><br />
                6.  How much do jewellers charge on purchasing Gold jewellery?
                The Indian population has a fascination with gold. It is the most cherished metal, and it is flaunted in the form of jewellery on every occasion. The gold rate today in India is not standard. The gold price oscillates based on fluctuations in the market.
                <br/><br />
                Purchasing gold is an exceptionally normal peculiarity among Indians. Celebrations and wedding festivities are not complete without gold. We regularly don't wrangle the cost of gold a great deal while getting it from the neighbourhood diamond setter. Rather we shouldn't indiscriminately believe the cost asked by the diamond setter, since there are many elements that influence the last sum, including making charges, gold value, worth of jewels and so on.

                Right now, there is no such standard charging price in India and costs contrast from one gem dealer to another.
                <br/><br />
                Here is the most ordinarily utilised recipe to work out the last cost of gold:
                <br/><br />
                Last cost of the gems = Price of 22 KT gold X (weight in grams) + Making charges + GST at 3% on (cost of adornments + making charges)
                <br/><br />
                Beneath referenced are a few factors that you ought to know about while purchasing gold to try not to be deceived:
                ‍‍<br/><br />
                    * Gold must be pure

                Gold is generally accessible in various Karats (KT), which is the proportion denoting the immaculateness of gold.
                <br/><br />
                24KT gold is viewed as the most perfect type of gold yet it is too delicate to be in any way made into gems. Most famous type of gold accessible in adornments is of 22KT which has 91.6 percent gold. A few different metals like copper, zinc, cadmium or silver are added to the gold to make it more grounded.
                <br/><br />
                    *Studded jewels
                ‍
                Once in a while, individuals purchase adornments that have stone work on it. A few exploitative gem dealers gauge the whole adornment and furthermore charge for the stones in it. Also, if at any point you wish to trade/sell it back, the goldsmith ordinarily deducts the heaviness of the stone from the complete worth.
                ‍<br/><br />
                Accordingly, while purchasing gold you ought to be absolutely mindful that the worth of gold must be determined relying upon the heaviness of the adornments and lessening the heaviness of precious stones and gemstones.
                Cost of gold gems relies upon:
                ‍<br/><br />
                a) gold in the gems, for example 22KT or 18KT  
                ‍<br/>     
                b) the metal blended in with the gold.
                ‍<br/><br />
                Gold is exchanged consistently and the interest and supply of the yellow metal decides its value consistently. The unadulterated gold rates are typically distributed in the news consistently, however the gold rate for various goldsmiths shifts as it depends from whom they are purchasing gold and at what cost. Nonetheless, the thing that matters is the exact moment.
                ‍<br/><br />
                    *BIS Standard trademark

                Hallmarking is the method involved with affirming the virtue of any gold gems. A trademark confirmation is given by the Bureau of Indian Standards (BIS) and endorses the immaculateness of gold.
                ‍<br/><br />
                BIS engraves its logo as an indication of trademark alongside hallmarking focus mark, fineness number and diamond setter's recognizable proof imprint.
                ‍<br/><br />
                    *Charges for the labour
                    ‍<br/><br />
                Labour charges depend upon the kind of gold gems you are purchasing. This is on the grounds that each trimming requires an alternate way of cutting and wrapping up. It likewise shifts on the component how much fine specifying is required in a specific plan. Machine-made gems as a rule do not cost as much as man-made ones.
                ‍<br/><br />
                7.  Sovereign Gold Bond Scheme: Should you invest in them?
                Sovereign gold bonds are RBI-mandated certificates issued against gold that allow anyone to invest in gold without having to worry about keeping their physical asset safe. Should you invest in them? Take a look at these advantages and disadvantages of SGBs and choose yourself.
                The difference in the number of carats is nothing but the total percentage of gold in the metal. The highest quality is 24 carat gold, that is totally pure. It is always advisable to buy gold that is BIS certified hallmarked gold.
                ‍<br/><br />
                Advantages
                ‍<br/><br />
                1. Payment of interest - On your SGB investment, the government offers a fixed annual interest rate. This interest payment is split into two parts and given to the investor every six months.
                ‍<br/><br />
                2. Demat and Paper Format -SGB is available in paper and demat format, which eliminates the cost and risk of holding physical gold. When you invest in SGB, you receive a holding certificate rather than real gold.
                This means you won't have to worry about your gold being secure or paying an annual charge to keep it in a bank locker.
                ‍<br/><br />
                3. Tax Advantage - The interest you get from your SGB investment is not subject to TDS. You can also transfer the bond before it matures to benefit from indexation.
                Even capital gains tax will be waived if you redeem the bond beyond its maturity date. The interest, on the other hand, is completely taxable according to your income tax bracket.
                ‍<br/><br />
                Disadvantage
                ‍<br/><br />
                1. Maturity - Because gold bonds have an 8-year maturity period, many investors are put off by them.
                ‍<br/><br />
                The government has kept the maturity extended in order to avoid investors from losing money due to gold price volatility. It's also worth noting that after 5 years from the date of purchase, investors can redeem the bond.
                ‍<br/><br />

                2. Capital Loss -Because the bond's value is closely connected to the price of gold on international markets, your investment in SGB could result in a capital loss. You may incur a loss if the price at which you purchase the bond is higher than the price at which you redeem it at maturity.
                ‍<br/><br />
                However, gold is a valuable commodity, and the government works hard to maintain its price stable. And if you stay invested for 5-8 years, your odds of losing money are slim. However, it is impossible to rule out the idea entirely.
                ‍<br/><br />
                8.  How is Gold used in Electronics?
                Gold is considered to be the most useful and reliable metals due to its unique properties. Gold is a proficient conductor of electricity. It helps in carrying small units of charged particles throughout the circuit. It is an easy handle and a rust proof metal. Gold is used in trivial quantities in electronic devices. Gold is commonly used in adapters, switches and relay contacts, metal filler, linking wires or in terminal strips.Gold is an important component in almost all the electronic gadgets. For instance television sets, computers, mobile phones, calculators, personal trackers and GPS (global positioning system).
                ‍
                Today, the biggest modern utilisation of gold is in the gadgets business. Because of it being moderately more costly than different metals and the way that there is a deficiency of supply, there is a transition to reuse gold utilised in old hardware in light of the fact that its interest is still high in the business.

                With this list of benefits of utilising gold to make electronic parts, no big surprise even with every one of the monetary difficulties its utilisation presents, it is as yet liked over copper and different conveyors in the creation of connectors and other sensitive parts.
                ‍<br/><br />
                9.  Putting your gold safely in India
                According to the government of India, a public authority's Gold Monetization Scheme (GMS) permits you to store your inactive gold with a Reserve Bank of India (RBI) assigned bank and acquire revenue on the equivalent. This works like a bank fixed store. Contingent upon the residency of the GMS one decides on, one can procure up to 2.5% interest per annum.
                Today, the biggest modern utilisation of gold is in the gadgets business. Because of it being moderately more costly than different metals and the way that there is a deficiency of supply, there is a transition to reuse gold utilised in old hardware in light of the fact that its interest is still high in the business.

                The plan was sent off by the public authority in 2015 with an aim to place the inactive gold put away by people in their homes and bank storage spaces to useful use. Nonetheless, the plan didn't track down numerous takers - one of the primary reasons being what befalls the gold you store with the bank.

                According to the plan leads, the gold - adornments, bullion, curios - is expected to be stored with the bank and thus, the bank will test its immaculateness. When the immaculateness of the gold adornments is discovered, they will liquefy the gold gems and convert it into bullion or gold coins.

                You can buy Digital Gold from FinePay app that is totally secured in world-class vaults and insured by top banks of India. 
                ‍<br/><br />
                10.  The present import duty on gold
                According to the government's 'Guide for Travellers,' any passenger of Indian origin or a passenger with a valid passport who is returning to India after a stay outside of at least 6 months can bring gold as baggage. Short visits within these six months will be ignored if their overall duration does not exceed 30 days. Importantly, no other passengers will be allowed to bring gold into the nation in their luggage save for these passengers. The union government prohibits the importation of gold and silver by foreign nationals.

                The traveller should pay the duty in a foreign currency that can be converted. Duty on gold bars other than tola bars having engraved serial numbers from the maker or refiner will be 12.5% , with a 1.25% Social Welfare Surcharge. Other than that, duty on gold in any form, including tola bars and ornaments, barring decorations encrusted with stones or pearls, will be 12.5%, plus a 1.25% Social Welfare Surcharge.
                ‍
                If staying for more than 6 months, Indian passport holders and those of Indian heritage will be eligible for a reduced duty rate on gold. Short trips of up to 30 days during the six-month period will be ignored. In all other circumstances, the standard Customs Duty rate of 38.5% is applied. The Customs Duty-Free Allowance, which is available to Indian passengers who have lived abroad for more than a year, is distinct. The charge for a man is Rs. 50,000, and the rate for a lady is Rs. 1,00,000.
                ‍
                One kilogramme of gold (including jewellery) is allowed per person, and it must be brought or imported within 15 days of arriving in India.
                ‍<br/><br />
                11.  How does QE impact Gold prices in India?
                The acronym QE stands for Quantitative Easing. Quantitative easing is increasing the amount of money available in the economy to boost spending. Central banks around the world buy securities, resulting in an increase in the economy's money supply. This additional money supply finds its way into worldwide gold assets, raising gold prices.
                ‍
                A rise in the QE has an impact on the gold rate in India today, which impacts all forms of gold, including the popular 916 gold rate. Lately, there hasn't been much quantitative easing (QE) taking place around the world. The United States has completed its quantitative easing (QE) phase, and several central banks in Japan and Europe are easing.
                ‍
                At this time, it appears that QE in that country is improbable. Gold rates may fall in the market if the global economy experiences any liquidity concerns. Other factors, in addition to quantitative easing, contribute to gold's ascent. Gold prices will decline as QE is phased out. As the United States' quantitative easing programme comes to an end, gold prices in India may be affected.
                ‍<br/><br />
                12.  Factors that influence gold prices in India
                ‍<br/><br />
                Factors that influence Gold prices in India are: 
                ‍‍<br/><br />
                1. Inflation - Gold is a secure investment and used as a hedge against inflation. Hence, the demand for gold rises when inflation is high, and vice versa. As a result of the increasing customer demand, the price of gold will increase.
                ‍‍<br/><br />
                2. Government Gold Reserves - The price of gold rises as large countries' central banks begin to retain gold reserves and purchase more gold. This is because the market's cash flow increases when the supply of gold decreases.
                ‍<br/><br />
                3. Jewellery Market - Gold prices rise throughout the wedding season, as well as during festivals like Diwali, as a result of increased consumer demand. Prices rise as a result of the demand-supply imbalance.
                ‍<br/><br />
                4. Interest Rate Trends  -  Customers are more likely to sell gold to obtain cash when interest rates rise, hence an increase in gold supply leads to lower gold rates. Lower interest rates, on the other hand, result in more cash in the hands of customers, resulting in increased demand for gold and, as a result, an increase in the metal's price.
                ‍<br/><br />
                13.  Gold - A special niche
                Gold is rare, non-corrosive, provides sustainable storage value, and attracts people physically and emotionally. Society and economy endow gold with value, thereby maintaining its value. We see it not only as a symbol of wealth and prosperity, but also as an investment against the effects of inflation. 
                ‍‍<br/><br />
                Investing in this precious metal is a good choice as it helps in neutralising the effects of other high-risk investment instruments such as mutual funds and stocks. It performs well even in volatile markets. Additionally, it has the potential to improve risk factors. While other assets go down, gold is the only asset which grows. 
                ‍<br/><br />
                14.  Method for buying gold in India
                ‍<br/><br />
                You can invest in gold through the following methods: 
                ‍<br/><br />
                Physical Gold (Jewellery, Coins, etc)
                ‍<br/><br />
                Digital Gold
                ‍<br/><br />
                Gold ETFs (Exchange-Traded Funds)
                ‍<br/><br />
                Gold mutual funds
                ‍<br/><br />
                SGBs (Sovereign Gold Bonds)
                ‍<br/><br />
                15.  Demand for Gold in India
                The love for gold is beyond words. The magnificence of gold is dated back to ancient times where gold was known for its exquisiteness and glow. The word gold is derived from the Greek name Aurora, meaning the goddess of the dawn. During the prehistoric period the colour of gold was often linked to the Sun.
                ‍‍<br/><br />
            </p>
        </div>
      </div>
    </div>
  )
}

export default CheckGoldPrice