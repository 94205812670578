import React from 'react'
import Marquee from 'react-fast-marquee';

const FinePayNews = () => {
    const newsimg = [
        {
          newimg: "./images/yourstory.png",
          desc: "Google & MetiY Startup Hub shortlists 25 startups from Delhi for Appsc...",
        },
        {
          newimg: "./images/business.png",
          desc: "Register Karo FinePay is helping Indians build a habit of investing",
        },
        {
          newimg: "./images/moneyctrl.png",
          desc: "kuberPe: An app that wants to help make investing a habit ",
        },
      ];
  return (
    <div className="   bg-custom-color2 pb-20 pt-[250px] text-poppinsRegular">
        <div className="px-5">
          <h1 className=" font-poppinsBold md:text-4xl text-3xl text-left">
            FinePay in <b className=" text-yellow-100">News</b>
          </h1>
        </div>

        <div className=" m-auto  w-[100%]  my-10   md:w-[100%]">
          <Marquee pauseOnHover speed={100}>
            <div className="w-[95%]  md:h-[180px] flex  gap-16 justify-center items-center">
              {newsimg.map((item, index) => (
                <div className=" bg-white md:w-96 w-72 h-36 rounded-2xl p-[1rem] ">
                  <img
                    src={item.newimg}
                    className=" rounded-lg w-[50%]"
                    alt="newimg"
                    style={{objectFit:"fit"}}
                  />
                  <div className="w-[100%]  leading-10   md:h-20 text-black">
                    <p className="md:text-lg text-sm">{item.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </Marquee>
        </div>
      </div>
  )
}

export default FinePayNews