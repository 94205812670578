import React from "react";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Marquee from "react-fast-marquee";
import Slider from "react-slick";
import Sumit from "../assets/web/mohan.jpg";
import jyoti from "../assets/web/costumer pictures/Jyoti Prakash Dey.jpg";
import ajel from "../assets/web/costumer pictures/ajel malick.jpg"
import pramod from "../assets/web/costumer pictures/Pramod Lenka.jpg";
import LazyImage from "./LazyImage";

const Carosel = () => {

  const carouselData = [
    {
      id: 3,
      image: `${Sumit}`,
      name: "Mohan Singh",
      profession: "Software Engineer",
    },
    {
      id: 4,
      image: `${jyoti}`,
      name: "Jyoti Prakash",
      profession: "Web Developer",
    },
    {
      id: 5,
      image: `${ajel}`,
      name: "Ajel Mallick",
      profession: "Web Developer",
    },
    {
      id: 6,
      image: `${pramod}`,
      name: "Pramod Lenka",
      profession: "Web Developer",
    },
    // Add more objects as needed
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Show arrows unless hideArrows is true

    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
    ],
  };

  return (
    <>
      {/* carosel */}
      {/* install the liabrary react-slick and its dependency slick-carousel */}
      <div className="overflow-hidden w-[100%] flex justify-center items-center bg-richblack-900 text-white">

        <div className="w-[85%] font-poppinsRegular">

          <div className="lg:w-[85%] w-[100%] m-auto  md:h-60 h-32 flex flex-col gap-3 items-center justify-center ">
            <h1 className="font-poppinsBold md:text-5xl text-xl">
              Spoken by Our Customers
            </h1>
            <h2 className="font-poppinsBold text-center  text-[#717171]">
              Our customers love saving with us due to so many reason
            </h2>
          </div>

          <div className="">
          <Slider {...settings}>
            {
            carouselData.map((item) => (
              <div key={item.id} className="h-[300px] w-[300px] slider-item" style={{width:"22%"}}>
                <div className="h-[300px] w-[300px]">
                
                <LazyImage src={item.image} alt={item.name} imgProperty={'rounded-[10%]'} />
                <div className="text-center">
                  <h3 className="font-semibold text-md md:text-md mt-[1rem]">
                    {item.name}
                  </h3>
                  {/* <p className="text-[#717171] text-sm">{item.profession}</p> */}
                </div></div>
              </div>
            ))}
          </Slider>
          </div>

        </div>
        
      </div>
    </>
  );
};

export default Carosel;
