import React from "react";

function PrivacyPolicy() {
    return (
        <div className="bg-richblack-900  text-white">
            <div className="w-[85%] m-auto flex gap-9 flex-col py-20 font-poppinsRegular">
                <h1 className="md:text-6xl text-2xl font-poppinsBold md:h-32 items-center flex">
                    Privacy Policy | FinePay
                </h1>
                <p>
                    This Privacy Policy is incorporated by reference into the
                    FinePay Terms of Service (the “Terms”).
                </p>
                <p>
                    The website{" "}
                    <a href="https://www.finepay.app">www.finepay.app</a> and
                    the mobile application ‘FinePay’ (collectively referred to
                    as the “Platform”) are owned and operated by Kalash
                    Finestream Investment Private Limited, a private limited
                    company with its registered office at Embassy Manyata Park,
                    Block D3, Nagavara, Bangalore-560045, Karnataka. This
                    includes its affiliates and group entities, collectively
                    referred to as the “Company”, “We”, “Us”, or “Our”.
                </p>
                <p>
                    This Privacy Policy applies to all Users whose Personal
                    Information is processed by Us in the course of our
                    business, including through mobile applications, forums,
                    blogs, and other online or offline offerings.
                </p>
                <p>
                    We respect your privacy and handle your personal data with
                    the utmost care and confidentiality.
                </p>
                <p>
                    By visiting the Platform, you (“You” or “Your”) accept and
                    agree to be bound by the terms and conditions of this
                    Privacy Policy. This Privacy Policy is incorporated into and
                    subject to the terms of use of the Platform (“Terms”) and
                    should be read harmoniously in conjunction with the Terms.
                </p>
                {/* digital gold service */}
                <section className="max-w-4xl mx-auto p-4">
                    <h2 className="text-lg font-bold text-center md:text-xl mb-4">
                        Digital Gold Services
                    </h2>
                    <p className="mb-4 text-base leading-relaxed">
                        We respect your privacy and hence handle your personal
                        data with the utmost care and confidentiality. Please
                        read the Privacy Policy carefully prior to using or
                        registering on the Platform or accessing/availing the
                        services on the Platform inter alia in relation to
                        purchase/sale/transfer of Digital Gold from a brand
                        named “AUGMONT” operated and managed by “Augmont
                        Goldtech Private Limited” (hereinafter referred as
                        “AUGMONT”) a company incorporated under the laws of
                        India (“Services”).
                    </p>
                    <p className="mb-4 text-base leading-relaxed">
                        This Privacy Policy specifies the manner in which
                        personal data and other information is collected,
                        received, stored, processed, disclosed, transferred,
                        dealt with or otherwise handled by the Company. This
                        Privacy Policy does not apply to information that You
                        provide to, or that is collected by, any third-party
                        (excluding the Company or its affiliates set out in
                        paragraph 4 below) through the Platform, and any
                        Third-Party Sites that You access or use in connection
                        with the services offered on the Platform.
                    </p>
                    <p className="mb-4 text-base leading-relaxed">
                        By visiting the Platform, You (“You” or “Your”), accept
                        and agree to be bound by the terms and conditions of
                        this privacy policy (“Privacy Policy”). This Privacy
                        Policy is incorporated into and subject to the terms of
                        use of the Platform(“Terms”) and shall be read
                        harmoniously and in conjunction with the Terms.
                    </p>
                    <p className="mb-4 text-base leading-relaxed">
                        It is important that you read this privacy notice
                        together with any other privacy policy or fair
                        processing notice we may provide on specific occasions
                        or when we are collecting or processing Personal
                        Information about You so that You are fully aware of how
                        and why we are using Your Personal Information.
                        Additionally, we also recommend you to read the terms
                        and privacy policy of AUGMONT which can be accessed at{" "}
                        <a
                            href="https://www.augmont.com/privacy-policy"
                            className="text-blue-500 hover:underline"
                        >
                            https://www.augmont.com/privacy-policy
                        </a>
                        .
                    </p>
                    <p className="text-base leading-relaxed">
                        This privacy notice supplements the other notices/policy
                        and is not intended to supersede them.
                    </p>
                </section>
                {/* peer to peer */}
                <section className="max-w-4xl mx-auto p-4">
                    <h2 className="text-lg font-bold text-center md:text-xl mb-4">
                        Peer to Peer Investment Services
                    </h2>
                    <p className="mb-4 text-base leading-relaxed">
                        We respect your privacy and hence handle your personal
                        data with the utmost care and confidentiality. Please
                        read the Privacy Policy carefully prior to using or
                        registering on the Platform or accessing/availing the
                        services on the Platform inter alia in relation to
                        Investment/Re-investment/Maturity of Peer to Peer
                        Lending Investment from a brand named “LENDBOX” operated
                        and managed by “Transactree Technologies Private
                        Limited” (hereinafter referred as “LENDBOX”) a company
                        incorporated under the laws of India (“Services”).
                    </p>
                    <p className="mb-4 text-base leading-relaxed">
                        This Privacy Policy specifies the manner in which
                        personal data and other information is collected,
                        received, stored, processed, disclosed, transferred,
                        dealt with or otherwise handled by the Company. This
                        Privacy Policy does not apply to information that You
                        provide to, or that is collected by, any third-party
                        (excluding the Company or its affiliates set out in
                        paragraph 4 below) through the Platform, and any
                        Third-Party Sites that You access or use in connection
                        with the services offered on the Platform.
                    </p>
                    <p className="mb-4 text-base leading-relaxed">
                        By visiting the Platform, You (“You” or “Your”), accept
                        and agree to be bound by the terms and conditions of
                        this privacy policy (“Privacy Policy”). This Privacy
                        Policy is incorporated into and subject to the terms of
                        use of the Platform(“Terms”) and shall be read
                        harmoniously and in conjunction with the Terms.
                    </p>
                    <p className="mb-4 text-base leading-relaxed">
                        It is important that you read this privacy notice
                        together with any other privacy policy or fair
                        processing notice we may provide on specific occasions
                        or when we are collecting or processing Personal
                        Information about You so that You are fully aware of how
                        and why we are using Your Personal Information.
                        Additionally, we also recommend you to read the terms
                        and privacy policy of LENDBOX which can be accessed at{" "}
                        <a
                            href="https://www.lendbox.in/privacy-policy"
                            className="text-blue-500 hover:underline"
                        >
                            https://www.lendbox.in/privacy-policy
                        </a>
                        .
                    </p>
                    <p className="text-base leading-relaxed">
                        This privacy notice supplements the other notices/policy
                        and is not intended to supersede them.
                    </p>
                    {/* Sub points INFORMATION */}
                    <div className="mt-8">
                        <h3 className="text-lg font-semibold mb-2">
                            1. COLLECTION OF INFORMATION
                        </h3>
                        <p className="text-base leading-relaxed mb-4">
                            To provide you the services, we collect, use and
                            process your personal information including your
                            sensitive information. The types of personal
                            information we may collect (directly from you or
                            from Third-Party sources) and our privacy practices
                            depend on the nature of the relationship you have
                            with the Company and the requirements of applicable
                            law.
                        </p>

                        <h3 className="text-lg font-semibold mb-2">
                            2. Information that You Provide to Us
                        </h3>
                        <p className="text-base leading-relaxed mb-4">
                            While registering on Our Platform for using Our
                            Services, we collect Your Personal Information
                            including sensitive personal information such as
                            name, mobile number, email address, password, date
                            of birth, gender. We use your contact information,
                            such as your email address or phone number, to
                            authenticate your account and keep it, to secure our
                            services and to help prevent spam, fraud, and abuse.
                            We also use contact information to personalize our
                            Services, enable certain account features and to
                            send you information about our Services. If you
                            provide us with your phone number and email id, you
                            agree to receive text messages from FinePay/ FinePay
                            to that number. In addition to the Personal
                            Information mentioned above and in order to provide
                            Services to You when you purchase Gold in excess of
                            30 grams we may also collect your KYC information
                            including Your Proof of identity like Aadhaar
                            number, driving license, PAN number, finger – print
                            details and signature solely for completing the
                            account opening procedures and authenticating Your
                            transactions on the Platform. The act of providing
                            Your Aadhaar is voluntary in nature and the Company,
                            hereby agrees and acknowledges that they will
                            collect, use and store such details in compliance
                            with applicable laws and this Privacy.
                        </p>
                        <p className="text-base leading-relaxed mb-4">
                            You may also be asked for certain financial
                            information, including Your billing address, bank
                            account details, credit card number, expiration date
                            and/or other payment related details or other
                            payment method data, and debit instructions or other
                            standing instructions to process payments for the
                            Services. All the payment transactions made through
                            the Platform are electronically processed by third
                            party service provider. All UPI payments and/or card
                            payments are subject to authorisation by UPI service
                            providers and/or card issuer and are electronically
                            processed by PhonePe payment aggregators or approved
                            payment gateway providers.
                        </p>
                        <p className="text-base leading-relaxed mb-4">
                            If You choose to post messages on our message
                            boards, chat rooms or other message areas or leave
                            feedback, we will collect and store such information
                            You provide to Us. We retain this information as
                            necessary to resolve disputes, provide customer
                            support, respond to queries and inquiries, and
                            troubleshoot problems and improve the Services.
                        </p>
                        <p className="text-base leading-relaxed mb-4">
                            If You send us correspondence, such as emails or
                            letters, or if other users or third parties send us
                            correspondence about Your activities or postings on
                            the Platform, we may collect and retain such
                            information into a file specific to You for
                            responding to Your request and addressing concerns
                            in relation to Your use of the Platform. We shall be
                            entitled to retain Your Personal Information and
                            other information for such duration as may be
                            required for the purposes specified hereunder and
                            will be used by Us only in accordance with this
                            Privacy Policy.
                        </p>
                        <p className="text-base leading-relaxed mb-4">
                            We may ask You to provide certain additional
                            information about Yourself on a case-to-case basis.
                            All information disclosed by You shall be deemed to
                            be disclosed willingly and without any coercion.
                        </p>
                        <p className="text-base leading-relaxed mb-4">
                            No liability pertaining to the authenticity/
                            genuineness/misrepresentation/ fraud/negligence,
                            etc. of the information disclosed shall lie on the
                            Company nor will the Company be in any way
                            responsible to verify any information obtained from
                            You.
                        </p>
                        <p className="text-base leading-relaxed mb-4">
                            You have every right to edit, modify, review or
                            delete any information including sensitive personal
                            information provided to us.
                        </p>
                        <h3 className="text-base font-semibold mb-2">
                            3. Automated Collected Information:
                        </h3>

                        <h4 className="text-sm font-semibold mb-2">
                            Information from Third Parties:
                        </h4>
                        <p className="mb-4 text-base leading-relaxed">
                            In addition to the information that you provide us
                            directly, we also collect information that You
                            provide to (AUGMONT/LENDBOX) and other third parties
                            for providing Services and for technology support
                            like application programming interfaces (APIs) and
                            embeds to make that information available in our
                            database. We may retrieve from Your records
                            available with third parties including from Know
                            Your Customer (KYC) Registration Agency (KRA) such
                            as name, KYC details, KYC status, father’s name,
                            occupation, address details, and related documents.
                        </p>

                        <h4 className="text-sm font-semibold mb-2">
                            Usage and Log Information:
                        </h4>
                        <p className="mb-4 text-base leading-relaxed">
                            To make our Platform and Services more useful to
                            you, our servers (which may be hosted by a
                            third-party service provider) collect information
                            from you, including your browser type, operating
                            system, Internet Protocol (IP) address (a number
                            that is automatically assigned to your computer when
                            you use the Internet, which may vary from session to
                            session), domain name, and/or a date/time of your
                            visit. We use this information to examine our
                            traffic and to view how our customers use our
                            website. This type of information will not allow you
                            to be personally identified although we might be
                            able to associate it with your account. We process
                            this usage data to facilitate your access to our
                            services in technical terms (e.g., to adjust our
                            services to the terminal device you are using), and
                            to recognize and stop any misuse. We also use usage
                            data in anonymized form for statistical purposes and
                            to improve our website. Further, if You choose to
                            invest through the Platform, we will also collect
                            information about Your transactions including
                            transaction status and details and Your investment
                            behavior. We process this data to provide you
                            Services.
                        </p>

                        <h4 className="text-sm font-semibold mb-2">
                            Location Information:
                        </h4>
                        <p className="mb-4 text-base leading-relaxed">
                            When and if You download and/or use the Platform
                            through Your mobile, we may receive information
                            about Your location, Your IP address, and/or Your
                            mobile device, including a unique identifier number
                            for Your device. We may use this information to
                            provide You with location-based Services including
                            but not limited to search results and other
                            personalized content. You can withdraw Your consent
                            at any time by disabling the location-tracking
                            functions on Your mobile. However, this may affect
                            Your enjoyment of certain functionalities on Our
                            Platform.
                        </p>

                        <h4 className="text-sm font-semibold mb-2">
                            Third-Party Information:
                        </h4>
                        <p className="mb-4 text-base leading-relaxed">
                            We also collect information from third parties on
                            the tracking technologies to track the activity on
                            our service and hold certain information.
                            Browser-based, network-based, AD-Tracking, User UX
                            behavior tracking information is collected from
                            third parties. We collect information to understand
                            and track the behavior as means for security and
                            connection integrity. We will keep such information
                            for our reference and will never share it with any
                            third party.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            Also, please remember that the manner in which
                            third-party services collect, use, store, and
                            disclose Your information is governed by the
                            policies of such third parties, and the Platform
                            and/or Company shall have no liability or
                            responsibility for the privacy practices or other
                            actions of any third-party services that may be
                            enabled within the Service.
                        </p>

                        <h4 className="text-sm font-semibold mb-2">Cookies:</h4>
                        <p className="mb-4 text-base leading-relaxed">
                            We use cookies and URL information to gather
                            information regarding the date and time of your
                            visit and the information for which you searched and
                            which you viewed. Cookies are small digital
                            signature files that are stored by your web browser
                            that allow your preferences to be recorded when you
                            visit the Platform. Also, they may be used to track
                            your return visits to the website. We may use both
                            session Cookies (which expire once you close your
                            web browser) and persistent Cookies (which stay on
                            your computer until you delete them) to provide you
                            with a more personal and interactive experience on
                            our Site. Persistent Cookies can be removed by
                            following Internet browser help file directions. Our
                            uses of cookies, web beacons, or other similar
                            technologies fall into the following general
                            categories:
                        </p>
                        <ul className="list-disc pl-5 mb-4">
                            <li className="mb-2">
                                <strong className="font-semibold">
                                    Operationally Necessary:
                                </strong>{" "}
                                We may use cookies, web beacons, or other
                                similar technologies that are necessary to the
                                operation of our Services. This includes
                                technologies that allow you access to our
                                Services; that are required to identify
                                irregular site behavior, prevent fraudulent
                                activity, and improve security; or that allow
                                you to make use of our functions such as,
                                product information, saved search, or similar
                                functions.
                            </li>
                            <li className="mb-2">
                                <strong className="font-semibold">
                                    Performance Related:
                                </strong>{" "}
                                We may use cookies, web beacons, or other
                                similar technologies to assess the performance
                                of our Services, including as part of our
                                analytic practices to help us understand how our
                                visitors use our Services, determine if you have
                                interacted with our messaging, determine whether
                                you have viewed an item or link, or to improve
                                our Services’ content, applications, services,
                                or tools.
                            </li>
                            <li className="mb-2">
                                <strong className="font-semibold">
                                    Functionality Related:
                                </strong>{" "}
                                We may use cookies, web beacons, or other
                                similar technologies that allow us to offer you
                                enhanced functionality when accessing or using
                                our Services. This may include identifying you
                                when you sign into our Services or keeping track
                                of your specified preferences, interests, or
                                past items viewed so that we may enhance the
                                presentation of content on our Services.
                            </li>
                            <li className="mb-2">
                                <strong className="font-semibold">
                                    Advertising or Targeting Related:
                                </strong>{" "}
                                We may use first-party or third-party cookies
                                and web beacons to deliver content, including
                                ads relevant to your interests, on our Services
                                or on Third Party sites. This includes using
                                technologies to understand the usefulness to you
                                of the advertisements and content that has been
                                delivered to you, such as whether you have
                                clicked on an advertisement.
                            </li>
                        </ul>
                        <p className="mb-4 text-base leading-relaxed">
                            If you would like to opt out of the Technologies we
                            employ on our Platform, services, applications, or
                            tools, you may do so by blocking, deleting, or
                            disabling them as your browser or device permits.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            The information collected allows Us to provide the
                            Services and features on the Platform that most
                            likely meet Your needs and to customize the Platform
                            to make Your experience safer and easier.
                        </p>
                        <p className="text-base leading-relaxed">
                            However, You can browse the Platform without telling
                            Us who You are or revealing any Personal Information
                            about Yourself. In such a case, we will only collect
                            and store basic usage data.
                        </p>
                        <h3 className="text-base font-semibold mb-2">
                            4. Use of Your Information:
                        </h3>
                        <p className="mb-4 text-base leading-relaxed">
                            We use the Personal Information and other
                            non-Personal Information for the following purposes:
                        </p>
                        <ul className="list-disc pl-5 mb-4">
                            <li className="mb-2">
                                To provide and improve the Services on the
                                Platform that You request.
                            </li>
                            <li className="mb-2">
                                To resolve disputes and troubleshoot problems.
                            </li>
                            <li className="mb-2">
                                To help promote a safe service on the Platform
                                and protect the security and integrity of the
                                Platform, the Services, and the users.
                            </li>
                            <li className="mb-2">
                                To collect money from You in relation to the
                                Services, inform You about online and offline
                                offers, products, services, and updates.
                            </li>
                            <li className="mb-2">
                                To customize Your experience on the Platform or
                                share marketing material with You.
                            </li>
                            <li className="mb-2">
                                To detect, prevent, and protect Us from any
                                errors, fraud, and other criminal or prohibited
                                activity on the Platform.
                            </li>
                            <li className="mb-2">
                                To enforce and inform about our terms and
                                conditions.
                            </li>
                            <li className="mb-2">
                                To process and fulfill Your requests for
                                Services or respond to Your comments and queries
                                on the Platform.
                            </li>
                            <li className="mb-2">To contact You.</li>
                            <li className="mb-2">
                                To allow Our business partners and/or associates
                                to present customized messages to You.
                            </li>
                            <li className="mb-2">
                                To communicate important notices or changes in
                                the Services provided by the Company, use of the
                                Platform, and the terms/policies which govern
                                the relationship between You and the Company,
                                and with Our affiliates as set out in paragraph
                                3 below for providing services to You.
                            </li>
                            <li className="mb-2">
                                For any other purpose after obtaining Your
                                consent at the time of collection.
                            </li>
                        </ul>
                        <h3 className="text-base font-semibold mb-2">
                            5. Sharing of Information:
                        </h3>
                        <p className="mb-4 text-base leading-relaxed">
                            We may make Your Personal Information and/or other
                            Non-Personal Information available to Our partners,
                            collaborators including (AUGMONT & LENDBOX) and its
                            affiliates to enable them to provide Services
                            through the Platform to You. Please note that all
                            information shared with our partners, collaborators,
                            and affiliates or made available to our partners,
                            collaborators, and affiliates will be governed by
                            the terms of this Privacy Policy.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            We may also disclose Your Personal Information to
                            third-party vendors, consultants, and other service
                            providers who work for the Company, and who are
                            bound by contractual obligations to keep such
                            personal information confidential and use it only
                            for the purposes for which We disclose it to them.
                            This disclosure may be required for Us, for
                            instance, to provide You access to Our Services and
                            process payments including validation of Your bank
                            accounts, to facilitate and assist Our marketing and
                            advertising activities/initiatives, for undertaking
                            auditing or data analysis, or to prevent, detect,
                            mitigate, and investigate fraudulent or illegal
                            activities related to Our Services.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            You also specifically agree and consent to Us for
                            transferring and sharing your sensitive personal
                            information (including your biometric data,
                            financial information, passwords) related to You
                            with Our partners, collaborators including (AUGMONT
                            & LENDBOX) and its affiliates, third parties, and
                            other service providers solely for the legitimate
                            purpose of providing you the Services.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            We do not disclose Your Personal Information to
                            third parties for their marketing and advertising
                            purposes without Your explicit consent.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            The Company may disclose Your information, to the
                            extent necessary:
                        </p>
                        <ul className="list-disc pl-5 mb-4">
                            <li className="mb-2">
                                To comply with laws and to respond to lawful
                                requests and legal processes.
                            </li>
                            <li className="mb-2">
                                To protect the rights and property of the
                                Company, Our users, and others, including to
                                enforce the Terms.
                            </li>
                            <li className="mb-2">
                                In an emergency to protect the personal safety
                                and assets of the Company, the users, or any
                                person. In such an event, the Company is in no
                                manner responsible for informing You or seeking
                                Your approval or consent.
                            </li>
                        </ul>
                        <h3 className="text-base font-semibold mb-2">
                            6. Links to Other Third-Party Sites and Collection
                            of Information:
                        </h3>
                        <p className="mb-4 text-base leading-relaxed">
                            Our Platform may link You to other third-party
                            Platforms (“Third-Party Sites”) that may collect
                            Your Personal Information including Your IP address,
                            browser specification, or operating system. The
                            Company is not in any manner responsible for the
                            security of such information or their privacy
                            practices or content of those Third-Party Sites.
                            These third-party service providers and Third-Party
                            Sites may have their own privacy policies governing
                            the storage and retention of Your information that
                            You may be subject to.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            This Privacy Policy does not govern any information
                            provided to, stored on, or used by these third-party
                            providers and Third-Party Sites. We recommend that
                            when You enter a Third-Party Site, You review the
                            Third-Party Site’s privacy policy as it relates to
                            safeguarding of Your information.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            We use third-party advertising companies to serve
                            ads when You visit the Platform. These companies may
                            use information (not including Your name, address,
                            email address, or telephone number) about Your
                            visits to the Platform and Third-Party Sites in
                            order to provide advertisements about goods and
                            services of interest to You.
                        </p>
                        <p className="text-base leading-relaxed">
                            You agree and acknowledge that We are not liable for
                            the information published in search results or by
                            any Third-Party Sites.
                        </p>
                        <h3 className="text-base font-semibold mb-2">
                            7. Security Precautions and Measures
                        </h3>
                        <p className="mb-4 text-base leading-relaxed">
                            Our Platform has reasonable security measures and
                            safeguards in place to protect Your privacy and
                            Personal Information from loss, misuse, unauthorized
                            access, disclosure, destruction, and alteration of
                            the information in compliance with applicable laws.
                            Further, whenever You change or access Your account
                            on the Platform or any information relating to it,
                            We offer the use of a secure server.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            We cannot however ensure or warrant the security of
                            any information You transmit to the Company or
                            guarantee that Your Personal Information and/or
                            other Non-Personal Information provided for availing
                            the Services or Platform may not be accessed,
                            disclosed, altered or destroyed by a breach of any
                            of Our security measures and safeguards.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            It is further clarified that You have and so long as
                            You access and/or use the Platform (directly or
                            indirectly) the obligation to ensure that You shall
                            at all times, take adequate physical, managerial,
                            and technical safeguards, at Your end, to preserve
                            the integrity and security of Your data which shall
                            include and not be limited to Your Personal
                            Information.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            When payment information is being transmitted on or
                            through the Platform, it will be protected by
                            encryption technology. You expressly consent to the
                            sharing of Your information with third party service
                            providers, including payment gateways, to process
                            payments and manage your payment-related
                            information. Hence, the Company cannot guarantee
                            that transmissions of Your payment-related
                            information or Personal Information will always be
                            secure or that unauthorized third parties will never
                            be able to defeat the security measures taken by the
                            Company or the Company’s third-party service
                            providers.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            The Company assumes no liability or responsibility
                            for disclosure of Your information due to errors in
                            transmission, unauthorized third-party access, or
                            other causes beyond its control. You play an
                            important role in keeping Your personal information
                            secure. You shall not share Your Personal
                            Information or other security information for Your
                            account with anyone.
                        </p>
                        <p className="text-base leading-relaxed">
                            The Company has undertaken reasonable measures to
                            protect Your rights of privacy with respect to Your
                            usage of the Platform controlled by Us and Our
                            Services. However, We shall not be liable for any
                            unauthorized or unlawful disclosures of Your
                            personal information made by any third parties who
                            are not subject to Our control.
                        </p>
                        <h3 className="text-base font-semibold mb-2">
                            8. Data Storage and Retention Policy
                        </h3>
                        <p className="mb-4 text-base leading-relaxed">
                            We collect and store your data and Personal
                            Information in AWS cloud as you use services and
                            will retain the data for as long as necessary to
                            fulfil the purposes for which it was obtained.
                            Processed and non-identifiable data, however, will
                            be perpetually stored.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            To determine the appropriate retention period for
                            personal data, we consider the amount, nature, and
                            sensitivity of the personal data, the potential risk
                            of harm from unauthorized use or disclosure of your
                            personal data, the purposes for which we process
                            your personal data and whether we can achieve those
                            purposes through other means, and the applicable
                            legal requirements.
                        </p>
                        <h3 className="text-base font-semibold mb-2">
                            2. Changes to Privacy Policy
                        </h3>
                        <p className="mb-4 text-base leading-relaxed">
                            We reserve the unconditional right to change,
                            modify, add, or remove portions of this Privacy
                            Policy at any time, without specifically notifying
                            You of such changes. Any changes or updates will be
                            effective immediately. You should review this
                            Privacy Policy regularly for changes. Your
                            acceptance of the amended Privacy Policy shall
                            signify Your consent to such changes and agreement
                            to be legally bound by the same.
                        </p>

                        <h3 className="text-base font-semibold mb-2">
                            3. Grievance Officer
                        </h3>
                        <p className="mb-4 text-base leading-relaxed">
                            If You have any privacy concerns, please feel free
                            to reach out to the grievance officer, the name and
                            contact details of this Officer have been provided
                            below:
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            Name: Mr. Manoj Kumar Dalai
                            <br />
                            Email:{" "}
                            <a href="mailto:manoj@finepay.app">
                                manoj@finepay.app
                            </a>
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            He shall try to acknowledge the complaint within
                            twenty-four hours and dispose of such complaint
                            within a period of fifteen days from the date of
                            receipt of complaint.
                        </p>
                        <p className="text-base leading-relaxed">
                            Effective as on 18th August 2024
                            <br />
                            Registered Office address: Embassy Manyata Park,
                            Block D3, Nagavara, Bangalore-560045, Karnataka
                            India.
                        </p>
                        <h3 className="text-base font-semibold mb-2">
                            9. Disclaimer
                        </h3>
                        <p className="mb-4 text-base leading-relaxed">
                            The Company is merely providing a distributor
                            platform of (AUGMONT & LENDBOX) that facilitates
                            transactions of sale and investment for Users and
                            will not be liable in any manner with respect to the
                            products/services allotted to You. You hereby agree
                            and acknowledge that the data and information
                            provided on the Platform does not constitute advice
                            of any nature whatsoever and shall not be relied
                            upon by You while making investment decisions. You
                            shall be solely responsible for any investment
                            decisions and for the purchase of any mutual funds
                            on the Platform. In no event shall the Company be
                            liable to You for any loss or damage that may arise
                            from or in relation to these Terms of Use and/or due
                            to the use of this Platform or due to investments
                            made using this Platform. As part of the Platform
                            Services provided to You, after availing such
                            services, You agree to provide honest
                            feedback/review about the concerned Platform
                            Service, if required by the Company.
                        </p>

                        <h3 className="text-base font-semibold mb-2">
                            1. Your Privacy Rights
                        </h3>
                        <p className="mb-4 text-base leading-relaxed">
                            <strong>Your Consent:</strong> By visiting the
                            Platform or setting up/creating an account on the
                            Platform for availing the Services on the Platform,
                            You signify Your acceptance of the provisions of the
                            Privacy Policy. You may choose to withdraw Your
                            consent at any point in time. Such withdrawal of
                            consent must be sent in writing to{" "}
                            <a href="mailto:invest@finepay.app">
                                invest@finepay.app
                            </a>
                            . If You do not provide Your consent or later
                            withdraw Your consent, we request you not to access
                            the Platform and/or use the Services and also
                            reserve the right to not provide You any Services
                            through the Platform. In such a scenario, the
                            Company may delete Your information (Personal or
                            otherwise) or de-identify it so that it is anonymous
                            and not attributable to You. However, please note
                            that the Company reserves the right to refuse any
                            request to exercise such rights to the extent
                            permitted by applicable law.
                        </p>

                        <h3 className="text-base font-semibold mb-2">
                            2. Changes to Privacy Policy
                        </h3>
                        <p className="mb-4 text-base leading-relaxed">
                            We reserve the unconditional right to change,
                            modify, add, or remove portions of this Privacy
                            Policy at any time, without specifically notifying
                            You of such changes. Any changes or updates will be
                            effective immediately. You should review this
                            Privacy Policy regularly for changes. Your
                            acceptance of the amended Privacy Policy shall
                            signify Your consent to such changes and agreement
                            to be legally bound by the same.
                        </p>
                        <h3 className="text-base font-semibold mb-2">
                            3. Grievance Officer
                        </h3>
                        <p className="mb-4 text-base leading-relaxed">
                            If You have any privacy concerns, please feel free
                            to reach out to the grievance officer, the name and
                            contact details of this Officer have been provided
                            below:
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            Name: Mr. Manoj Kumar Dalai
                            <br />
                            Email:{" "}
                            <a href="mailto:manoj@finepay.app">
                                manoj@finepay.app
                            </a>
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            He shall try to acknowledge the complaint within
                            twenty-four hours and dispose of such complaint
                            within a period of fifteen days from the date of
                            receipt of complaint.
                        </p>
                        <p className="text-base leading-relaxed">
                            Effective as on 18th August 2024
                            <br />
                            Registered Office address: Embassy Manyata Park,
                            Block D3, Nagavara, Bangalore-560045, Karnataka
                            India.
                        </p>
                    </div>
                </section>
                {/* /ready cash  */}
                <section className="max-w-4xl mx-auto p-4">
                    <h2 className="text-lg font-bold text-center md:text-xl mb-4">
                        Ready Cash
                    </h2>

                    <h3 className="text-base font-semibold mb-2">
                        1. Information We Collect
                    </h3>
                    <p className="mb-4 text-base leading-relaxed">
                        <strong>
                            1.1 Sensitive Data and Personal Information:
                        </strong>{" "}
                        In general, we may collect Personal Information,
                        including Sensitive Data. “Personal Information” means
                        individually identifiable information that directly or
                        indirectly, in combination with other information, would
                        allow the identification of a specific living person.
                        “Sensitive Data” includes your Personal Information such
                        as financial information, including bank account details
                        and/or any other payment instrument details, sexual
                        orientation, any details that may have been voluntarily
                        provided by you, and any of the information received
                        under the above clauses by us in connection with
                        availing the Services. By providing Personal Information
                        (including Sensitive Data) to us, you consent to the
                        collection, usage, and disclosure of Personal
                        Information (including Sensitive Data), as permitted by
                        applicable laws. By using the Services, you are
                        authorizing us to gather, parse data, and retain basic
                        data related to the provision of the Services.
                    </p>
                    <h3 className="text-base font-semibold mb-2">
                        2. Personal Information:
                    </h3>
                    <p className="mb-4 text-base leading-relaxed">
                        We may collect and process your Personal Information,
                        including but not limited to the following:
                    </p>
                    <ul className="list-disc list-inside mb-4 text-base leading-relaxed">
                        <li>
                            Information provided by you at the time of
                            registration such as your name, address, email ID,
                            phone number, bank account details, financial
                            information, unique identifiers, and preferences
                            information including favorites and history;
                        </li>
                        <li>
                            Information that you provide to us directly via
                            email and/or electronic communication;
                        </li>
                        <li>
                            Your bank account statement provided by you through
                            the upload feature during the onboarding process on
                            FinePay Platform to assess your creditworthiness;
                        </li>
                        <li>
                            Your live selfie for your identity check and
                            verification with your KYC documents. The image
                            captured through this selfie may be shared with
                            third parties for the purpose mentioned in Clause
                            10.1 herein;
                        </li>
                        <li>
                            Information that you provide to us over the
                            telephone. We may make and keep a record of such
                            information shared by you;
                        </li>
                        <li>
                            Information that you provide to us in physical form
                            whether sent through post and/or courier and/or
                            handed over to our representatives in person;
                        </li>
                        <li>
                            Any other additional information required to be
                            provided via email/electronic
                            communication/telephone/post/courier after your
                            registration;
                        </li>
                        <li>
                            Hardware model, operating system, versions, and
                            identifiers like IMEI number and serial number of
                            your primary device used for availing Services;
                        </li>
                        <li>Information from other sources.</li>
                    </ul>
                    <h3 className="text-base font-semibold mb-2">
                        3. Domain Name and IP Address:
                    </h3>
                    <p className="mb-4 text-base leading-relaxed">
                        We may collect your domain name which is linked to every
                        computer/mobile device connected to the internet and a
                        set of numbers that serve as that computer’s Internet
                        Protocol or “IP” address. When you request a page from
                        any page within the Website, our web servers
                        automatically recognize your domain name and IP address.
                        The domain name and IP address do not reveal any further
                        information about you other than the IP address from
                        which you have accessed the Website.
                    </p>
                    <h3 className="text-base font-semibold mb-2">
                        4. Cookies:
                    </h3>
                    <p className="mb-4 text-base leading-relaxed">
                        If enabled, we may place cookies on your machine that
                        store small amounts of data on your computer about your
                        visit to any of the pages of this website. Cookies can
                        identify the pages that are being viewed and this can
                        assist us in tracking which of our features appeal the
                        most to you and what content you may have viewed on past
                        visits. We use cookies on our website in order to
                        personalize our service to you. Users can control the
                        use of cookies at the individual browser level. If you
                        reject cookies, you may still use our site, but your
                        ability to use some features or areas of our site may be
                        limited. You may refuse to accept cookies by activating
                        the setting on your browser which allows you to refuse
                        the setting of cookies. Unless you have adjusted your
                        browser setting to refuse cookies, our system will issue
                        cookies when you log on to the website.
                    </p>
                    <h3 className="text-base font-semibold mb-2">5. SMS:</h3>
                    <p className="mb-4 text-base leading-relaxed">
                        We do not collect, read, store, or share with third
                        parties your personal SMS, other than the SMS pertaining
                        to your financial transactions solely to assist our
                        lending partners in assessing your credit risk.
                    </p>

                    <h3 className="text-base font-semibold mb-2">6. Phone:</h3>
                    <p className="mb-4 text-base leading-relaxed">
                        We shall take one-time access to certain features of
                        your mobile phone to facilitate onboarding on the
                        platform and KYC verification:
                        <ul className="list-disc list-inside mb-4 text-base leading-relaxed">
                            <li>Camera</li>
                            <li>Location</li>
                            <li>SMS</li>
                            <li>IP Address</li>
                        </ul>
                    </p>

                    <h3 className="text-base font-semibold mb-2">
                        7. Biometric Data:
                    </h3>
                    <p className="mb-4 text-base leading-relaxed">
                        No Personal Information of yours containing biometric
                        data will be collected or stored by FinePay unless
                        permitted by extant statutory guidelines.
                    </p>
                    <h3 className="text-base font-semibold mb-2">
                        8. How We Use Information We Collect:
                    </h3>
                    <p className="mb-4 text-base leading-relaxed">
                        Our primary goal in collecting the information is to
                        provide you a platform to render our Services. We may
                        use the personal information provided by you for one or
                        more of the following:
                    </p>
                    <ul className="list-disc list-inside mb-4 text-base leading-relaxed">
                        <li>To help provide you the Services;</li>
                        <li>
                            To observe, improve, and administer the quality of
                            Services;
                        </li>
                        <li>
                            To analyze how the platform is used and diagnose
                            technical problems;
                        </li>
                        <li>
                            Remember the basic information provided by you for
                            effective access;
                        </li>
                        <li>
                            To confirm your identity in order to determine your
                            eligibility to use the platform and avail the
                            Services;
                        </li>
                        <li>To notify you about any changes to the Website;</li>
                        <li>
                            To enable us to comply with our legal and regulatory
                            obligations;
                        </li>
                        <li>
                            For the purpose of sending administrative notices,
                            Service-related alerts, and other similar
                            communication with a view to optimizing the
                            efficiency of the Website;
                        </li>
                        <li>
                            Doing market research, troubleshooting, protection
                            against error, project planning, fraud, and other
                            criminal activity;
                        </li>
                        <li>To enforce our Terms of Use.</li>
                    </ul>
                    <h3 className="text-base font-semibold mb-2">
                        9. Disclosure of Information:
                    </h3>
                    <p className="mb-4 text-base leading-relaxed">
                        <strong>9.1</strong> We do not publish your Sensitive
                        Data provided by you.
                    </p>
                    <p className="mb-4 text-base leading-relaxed">
                        <strong>9.2</strong> We will treat your Personal
                        Information strictly confidential. We will not disclose
                        your Personal Information to anyone, except in the
                        following cases:
                    </p>
                    <ul className="list-disc list-inside mb-4 text-base leading-relaxed">
                        <li>
                            We may disclose your Personal Information in the
                            event it is required to do so by law, rule,
                            regulation, law enforcement, governmental officials,
                            legal or regulatory authorities, and/or to such
                            other statutory bodies who have appropriate
                            authorization to access the same for any specific
                            legal purposes.
                        </li>
                        <li>
                            Merger, acquisitions: Information collected from you
                            may be transferred to third parties as a result of a
                            sale or acquisition, merger, or bankruptcy involving
                            the Company.
                        </li>
                        <li>
                            We may disclose your information to any of our
                            affiliates or related entities. We will ensure that
                            our affiliates and related entities maintain the
                            same standards of confidentiality and protection
                            with respect to the Personal Information shared with
                            them.
                        </li>
                        <li>
                            We may disclose your information in order to provide
                            you the Services, enforce or apply the Terms and
                            Conditions of Use, or to protect the rights,
                            property, or safety of the Company, its users, or
                            others. This includes providing information to one
                            or more lending partners, and exchanging information
                            with other companies/agencies that work for fraud
                            prevention.
                        </li>
                    </ul>
                    <h3 className="text-base font-semibold mb-2">
                        10.1 Sharing:
                    </h3>
                    <p className="mb-4 text-base leading-relaxed">
                        You hereby consent to sharing your Personal Information
                        with credit information companies, analytics, technical
                        partners, payment facilitators, commercial partners,
                        lending partners, etc., for purposes including but not
                        limited to providing and monitoring Our Services,
                        onboarding process on the FinePay Platform, assessing
                        creditworthiness, KYC verification, fraud prevention,
                        and risk evaluation. We may share aggregated demographic
                        information with our partners or affiliates. This is not
                        linked to any personal information that can identify an
                        individual person. We will not be liable for the
                        transfer of any personal identification information
                        resulting from loss or distribution of data, the
                        delineation or corruption of media storage, power
                        failures, natural phenomena, riots, acts of vandalism,
                        sabotage, terrorism, or any other event beyond our
                        control. Further, our Privacy Policy does not cover the
                        use of cookies by its partners and affiliates since we
                        do not have access or control over such cookies.
                    </p>
                    <h3 className="text-base font-semibold mb-2">
                        10.2 Consulting:
                    </h3>
                    <p className="mb-4 text-base leading-relaxed">
                        We may partner with other parties to provide specific
                        portions of the Services, if required. When you sign up
                        for the Services, we may share some of your Personal
                        Information (including phone number registered or to be
                        registered with FinePay) and Sensitive Data to the
                        extent necessary for the third party to provide these
                        Services. Our contractual arrangements with such third
                        parties restrict these parties from using personally
                        identifiable information except for the explicit purpose
                        of assisting in the provision of these Services.
                    </p>

                    <h3 className="text-base font-semibold mb-2">
                        10.3 Promotional Offers:
                    </h3>
                    <p className="mb-4 text-base leading-relaxed">
                        We may send you offers, which may be on behalf of other
                        businesses. If you do not want to receive such offers,
                        you may request us to adjust your preferences.
                    </p>
                    <h3 className="text-base font-semibold mb-2">
                        11. Change of Information:
                    </h3>
                    <p className="mb-4 text-base leading-relaxed">
                        If your Personal Information or Sensitive Data changes,
                        you may correct, delete inaccuracies, or amend
                        information by reaching out to us at ID:{" "}
                        <a
                            href="mailto:help@finepay.app"
                            className="text-blue-600 hover:underline"
                        >
                            help@finepay.app
                        </a>
                        . We may request you to provide any additional
                        information or documents as may be necessary to verify
                        the changes requested by you.
                    </p>
                    <h3 className="text-base font-semibold mb-2">
                        12. Security of Information:
                    </h3>

                    <p className="mb-4 text-base leading-relaxed">
                        <strong>12.1</strong> We take the security of your
                        information very seriously. To this end, we use several
                        security techniques including secure servers in India,
                        firewalls, and encryption, as well as physical
                        safeguards at the locations where the data are stored.
                        We have implemented appropriate administrative,
                        technical, and physical measures to protect the personal
                        data you provide against accidental, unauthorized, or
                        unlawful loss, destruction, damage, alteration, access,
                        disclosure, or use and any other unlawful forms of
                        processing. While we strive to ensure the highest level
                        of security, we cannot guarantee 100% security. We
                        maintain physical, electronic, and procedural safeguards
                        in connection with the collection, storage, and
                        disclosure of your information. Our security procedures
                        may require us to request proof of identity before
                        disclosing personal information to you. You are
                        responsible for protecting against unauthorized access
                        to your password and computer.
                    </p>

                    <p className="mb-4 text-base leading-relaxed">
                        <strong>12.2</strong> The information collected from you
                        may be transferred to, stored, and processed at any
                        destination as permitted under applicable law. By
                        submitting information to us, you consent to this
                        transfer, storage, and/or processing. We will take
                        reasonable steps to ensure that your information is
                        treated securely and in accordance with this Privacy
                        Policy.
                    </p>

                    <p className="mb-4 text-base leading-relaxed">
                        <strong>12.3</strong> By using the Services, you accept
                        the inherent security risks of data transmission over
                        the internet. Therefore, your use of the Website will be
                        at your own risk. We assume no liability for any
                        disclosure of information due to errors in transmission,
                        unauthorized third-party access, or other acts beyond
                        our control. You agree not to hold us responsible for
                        any breach of security.
                    </p>

                    <p className="mb-4 text-base leading-relaxed">
                        <strong>12.4</strong> If we become aware of any breach
                        of the security of your information, we will promptly
                        notify you and take appropriate actions to the best of
                        our ability to remedy such a breach.
                    </p>
                    <h3 className="text-base font-semibold mb-2">
                        13. Data Retention
                    </h3>

                    <p className="mb-4 text-base leading-relaxed">
                        You may request us to delete your information or revoke
                        the consent to access or opt out of processing your
                        application by submitting a request for account deletion
                        through the FinePay mobile app via the profile section.
                        Upon submitting a request to delete your account, you
                        acknowledge and understand the following:
                    </p>

                    <ul className="list-disc mb-4 ml-6 text-base leading-relaxed">
                        <li>
                            You have no outstanding credit facility availed on
                            the FinePay platform.
                        </li>
                        <li>
                            You have no pending or potential grievances in
                            relation to any of your previous dealings with us.
                        </li>
                        <li>
                            Your account will be permanently deleted, and we
                            will not be able to reactivate it.
                        </li>
                        <li>
                            You will receive no further communications from
                            FinePay.
                        </li>
                    </ul>

                    <p className="mb-4 text-base leading-relaxed">
                        However, you understand that we may be required to
                        retain, and accordingly use and disclose, any specific
                        data if required by law, rule, regulation, law
                        enforcement, governmental officials, legal or regulatory
                        authorities, or other statutory bodies with appropriate
                        authorization. This includes purposes for completing any
                        legal obligations or audit requirements. We will not
                        retain your Personal Information (including sensitive
                        personal data) for longer than is required for the
                        purpose for which the information may lawfully be used.
                    </p>

                    <h3 className="text-base font-semibold mb-2">
                        14. Notification of Changes
                    </h3>

                    <p className="mb-4 text-base leading-relaxed">
                        We may update this Privacy Policy at any time, with or
                        without advance notice. In the event of significant
                        changes in the way we treat your Personal Information,
                        or in the Privacy Policy document itself, we will
                        display a notice on the Website or send you an email
                        informing you of such changes. This will allow you to
                        review the updated terms before continuing to use the
                        Website and our Services.
                    </p>
                    <p className="mb-4 text-base leading-relaxed">
                        Continuing to use the Company’s Services or accessing
                        the Website after a notice of changes has been sent to
                        you or published on the Website will constitute your
                        consent to the changed terms.
                    </p>

                    <p className="text-base leading-relaxed">
                        It is very important that any Personal Information we
                        hold or pass on to our lending partners about you is up
                        to date and correct. Please inform us of any changes to
                        your Personal Information at{" "}
                        <a
                            href="mailto:help@finepay.app"
                            className="text-blue-500 hover:underline"
                        >
                            help@finepay.app
                        </a>
                        .
                    </p>
                    <h3 className="text-base font-semibold mb-2">
                        15. Address for Privacy Related Questions
                    </h3>

                    <p className="mb-4 text-base leading-relaxed">
                        To offer optimum support to our customers and to address
                        complaints and grievances in the most effective manner,
                        we have set out the process for registering and getting
                        the complaint addressed.
                    </p>

                    <p className="mb-4 text-base leading-relaxed">
                        In accordance with the Information Technology Act, 2000
                        and the rules made thereunder, the name and contact
                        details of the Grievance Officer are provided below:
                    </p>

                    <ul className="list-disc mb-4 ml-6 text-base leading-relaxed">
                        <li>
                            <strong>Name:</strong> Mr. Manoj Kumar Dalai
                        </li>
                        <li>
                            <strong>Address:</strong> Embassy Manyata Park,
                            Block D3, Nagavara, Bangalore-560045, Karnataka,
                            India.
                        </li>
                        <li>
                            <strong>Email ID:</strong>{" "}
                            <a
                                href="mailto:invest@finepay.app"
                                className="text-blue-500 hover:underline"
                            >
                                invest@finepay.app
                            </a>{" "}
                            /{" "}
                            <a
                                href="mailto:help@finepay.app"
                                className="text-blue-500 hover:underline"
                            >
                                help@finepay.app
                            </a>
                        </li>
                    </ul>
                </section>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
