import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";

const ContactSupport = () => {
  const [formData, setFormData] = useState({
    registeredNumber: "",
    email: "",
    issueType: "",
    description: "",
    files: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({
      ...formData,
      files: files,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, you can send formData to your backend or process it further
    console.log(formData);
  };

  const openPlayStore = () => {
    // URL for your app on the Play Store
    const playStoreUrl = 'http://app.kalash.app/refer/SRK892';
    window.open(playStoreUrl, '_blank');
};

  return (
    <div className="bg-custom-color1 text-white">
      <div className=" md:w-[50%] w-[90%] m-auto py-10 font-poppinsRegular">
        <div className="items-center flex gap-2 text-2xl py-2 font-poppinsBold">
          {/* <FaArrowLeft />  */}
          <span>Raise Ticket</span>
        </div>
        <h2 className="text-left text-sm text-richblack-400 font-poppinsRegular my-5">
          Please tell us about the issue.
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4 font-poppinsRegular">
          <div>
            <label htmlFor="registeredNumber" className="block py-2">
              Registered Number:
            </label>
            <input
              type="text"
              id="registeredNumber"
              name="registeredNumber"
              value={formData.registeredNumber}
              onChange={handleChange}
              placeholder="Phone Number"
              className=" bg-[#2e2942] px-3 py-4 w-full rounded-md"
            />
          </div>
          <div>
            <label htmlFor="email" className="block py-2">
              Email ID:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className=" bg-[#2e2942] px-3 py-4 w-full rounded-md"
            />
          </div>
          <div>
            <label htmlFor="issueType" className="block py-2">
              Type of Issue:
            </label>
            <select
              id="issueType"
              name="issueType"
              value={formData.issueType}
              onChange={handleChange}
              className=" bg-[#2e2942] px-3 py-4 w-full rounded-md"
            >
              <option value="">Gold Gifting Refer & Earn...</option>
              <option value="Bug">Bug</option>
              <option value="Feature Request">Feature Request</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div>
            <label htmlFor="description" className="block py-2">
              Description:
            </label>
            <textarea
              id="description"
              name="description"
              placeholder="Please explain your issue in detail"
              value={formData.description}
              onChange={handleChange}
              className=" bg-[#2e2942] px-3 py-4 w-full rounded-md"
            />
          </div>
          <div>
            <label htmlFor="files" className="block py-2">
              Attach Files (Optional):
            </label>
            <input
              type="file"
              id="files"
              name="files"
              onChange={handleFileChange}
              multiple
              className=" bg-custom-color1 px-3 py-2 w-full rounded-md"
            />
          </div>
          <div className="h-12 w-48 m-auto my-10">
            <button
              type="submit"
              onClick={openPlayStore}
              className="text-lg rounded-3xl bg-custom-color1 text-white border border-[#7745ff] h-[100%] w-[100%] hover:bg-[#7745ff] active:bg-[#7745ff]"
            >
              Submit
            </button>
          </div>
        </form>
        <h1 className="text-center text-sm font-poppinsRegular py-2 w-full text-richblack-400">
          Registered Address : C-1/181 G/FLOOR KH.NO-1849 C-1BLOCK PHASE-IV. AYA NAGAR
                EXTN SOUTH DELHI-110047
        </h1>
      </div>
    </div>
  );
};

export default ContactSupport;
