import React from "react";
import Navbar from "../components/Navbar";
import Presentingold1 from "../assets/web/emigygan/finepayFront.png";
import img1 from "../assets/web/emigygan/peer to peerfod google ads.png";
import img2 from "../assets/web/emigygan/rent paidfod google ads.png";
import img3 from "../assets/web/emigygan/cash  and ticket rewardfod google ads.png";
import signupPartnership from "../assets/images/signups.png";
import refer from "../assets/images/reffer.png";
import commissions from "../assets/images/monthlly commission.png";
import loyalcustomers from "../assets/images/loyal costumer.png";
const EMIHero = () => {
    return (
        <div className="font-poppinsRegular ">
            <Navbar backgroundColour={"bg-custom-color1"} />

            {/* 1st components  */}

            <div className=" w-[100%]  flex items-center justify-center bg-custom-color1 text-white">
                <div className="container flex items-center m-auto justify-center w-[85%]">
                    {/* First Column (Paragraph Section) */}
                    <div className="md:w-[100%] w-[100%]    my-10 h-[100%]  flex justify-between items-center flex-col  md:flex-row">
                        <div className="md:w-[50%] md:h-[85%] w-[100%] h-96   xs:p-[1rem] md:p-[0px] md:text-2xl ">
                            <p className="text-black-700 font-poppinsRegular  leading-12  md:text-7xl text-5xl">
                                <b>Finepay</b>
                                <br /> <b>EMIYGYAN</b>
                                <br />
                                <b className="text-sky-400/75 leading-9 font-poppinsRegular">
                                    Is back !
                                </b>
                            </p>
                            <div>
                                <p className="font-poppinsRegular">
                                    This month, your rent is on us!
                                </p>
                            </div>

                            <form className="bg-custom-color1">
                                <div className=" rounded-lg w-[80%]">
                                    <div className="lg:w-[68%] py-[1rem] ">
                                        <label
                                            className="block text-gray-700 md:text-xl text-sm font-poppinsBold py-2"
                                            htmlFor="mobile"
                                        >
                                            Email
                                        </label>
                                        <input
                                            className="shadow text-sm appearance-none rounded w-[100%] h-12 p-[0.5rem] text-white leading-tight bg-custom-color2 focus:outline-none focus:shadow-outline"
                                            id="email"
                                            type="email"
                                            placeholder="you@kalash.app"
                                        />
                                    </div>

                                    <div className="w-[100%]">
                                        <button
                                            className=" bg-custom-color3 text-lg lg:w-[68%] w-[100%] text-center h-12 hover:bg-blue-300 text-white font-poppinsBold rounded focus:outline-none focus:shadow-outline"
                                            type="button"
                                        >
                                            Get Notified
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* Second Column (Image Section) */}
                        <div className="md:w-[48%]    w-[100%] flex justify-center    md:h-[100%]  h-[50%]">
                            <img
                                src={Presentingold1}
                                alt="twsimage"
                                style={{ objectFit: "contain" }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* 2nd components */}

            <div className="h-max-content py-20 bg-custom-color2  flex flex-col  items-center justify-center w-[100%]">
                <div className="w-[85%] ">
                    <div className=" m-auto text-white pb-10 text-center flex flex-col gap-3 items-center justify-center">
                        <div className=" w-[90%] ">
                            <h2 className="text-3xl md:text-4xl text-center  text-gray-400 font-poppinsRegular">
                                How does it work?
                            </h2>
                        </div>
                    </div>

                    <div className=" text-black m-auto  flex justify-center gap-10  flex-col md:flex-row  ">
                        {/* First Div */}
                        <div className="rounded-lg   text-white   items-center flex flex-col py-6 md:w-[38%]  text-center">
                            <div className="    items-center  text-center m-auto  flex     justify-center     ">
                                <img
                                    src={img1}
                                    alt="rets"
                                    className="rounded-lg   object-contain h-44 m-auto"
                                    style={{ objectFit: "contain" }}
                                />
                            </div>

                            <p className=" p-[1rem] w-[100%] font-poppinsRegular ">
                                Start saving in the FinePay P2P, 12% asset using
                                code 'EMIYGYAN' between the 27th of this month
                                to 3rd of the next month,
                            </p>
                        </div>

                        {/* Second Div */}
                        <div className="rounded-lg  text-white items-center flex flex-col md:w-[38%] py-6 text-center">
                            <div className="     items-center  text-center m-auto  flex     justify-center  ">
                                <img
                                    src={img2}
                                    alt="slide 2"
                                    className="rounded-lg    object-contain  h-44 m-auto "
                                    style={{ objectFit: "contain" }}
                                />
                            </div>

                            <p className=" p-[1rem]  w-[100%] font-poppinsRegular ">
                                Of all the savers who have saved during this
                                duration using the EMIYGYAN code, we pick one
                                lucky saver and we pay their rent.
                            </p>
                        </div>

                        {/* Third Div */}
                        <div className="rounded-lg  text-white   items-center flex flex-col  md:w-[38%]  py-10  text-center">
                            <div className="  pb-1  items-center  text-center m-auto  flex     justify-center    ">
                                <img
                                    src={img3}
                                    alt="slide 3"
                                    className="rounded-lg  object-contain h-44 m-auto "
                                    style={{ objectFit: "contain" }}
                                />
                            </div>

                            <p className=" w-[100%] p-[1rem]  font-poppinsRegular ">
                                Multiply your chances of winning with each
                                referral and also win thousands of tickets.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* 3rd components */}

            <div className="h-max-content py-20 bg-custom-color1  flex flex-col  items-center justify-center w-[100%]">
                <div className="w-[85%] ">
                    <div className=" m-auto text-white pb-10 text-center flex flex-col gap-3 items-center justify-center">
                        <div className=" w-[90%] ">
                            <h2 className="text-3xl md:text-2xl text-center  text-gray-400 font-poppinsRegular">
                                Start saving in the FinePay P2P 12% asset using
                                code 'EMIYGYAN'
                            </h2>
                        </div>
                    </div>
                    <div className="w-[100%]">
                        <form className="bg-custom-color2 flex p-[1rem] rounded-lg flex-col gap-3 items-center justify-center lg:w-[25%] md:w-[40%] w-[80%] m-auto">
                            <div className="lg:w-[100%] w-[100%] text-white">
                                <label
                                    className="block text-gray-700 md:text-xl text-sm font-poppinsBold py-2"
                                    htmlFor="mobile"
                                >
                                    Email
                                </label>
                                <input
                                    className="shadow text-sm appearance-none rounded w-[100%] h-12 p-[0.5rem] text-white leading-tight bg-custom-color3 focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="email"
                                    placeholder="you@kalash.app"
                                />
                            </div>

                            <div className="lg:w-[100%] w-[100%] mt-[1rem]">
                                <button
                                    className=" bg-blue-300  text-lg lg:w-[100%] w-[100%] text-center h-12 hover:bg-custom-color3 text-white font-poppinsBold rounded focus:outline-none focus:shadow-outline"
                                    type="button"
                                >
                                    Get Notified
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* term and contions */}
            <div className="h-max-content py-20 bg-custom-color2  flex flex-col  items-center justify-center w-[100%]">
                <div className="w-[85%] ">
                    <div className="  text-white pb-5">
                        <div className=" w-[90%] ">
                            <h2 className="text-3xl md:text-3xl    text-gray-400 font-poppinsRegular">
                                Terms & conditions
                            </h2>
                        </div>
                    </div>

                    <ol className="text-xl text-[#717171] border border-[#4e4e4e] rounded-lg p-[2rem] font-poppinsRegular list-decimal">
                        <ul className="list-disc">
                            <li>
                                The winner needs to share their payslip, rental
                                agreement and last 3 months' rental slips with
                                us for verification.
                            </li>
                            <li>
                                If a winner fails to share these documents, then
                                the reward will not be honored.
                            </li>
                            <li>
                                If a winner is found to commit any malpractice
                                on the app or while sharing (fake) documents
                                then the winner will not be honored with the
                                reward.
                            </li>
                            <li>
                                The rent we will cover cannot exceed the amount
                                you saved in 12% P2P using coupon code -
                                'EMIYGYAN' during the offer duration. ie, If you
                                have saved ₹15,000 and your rent is ₹20,000, we
                                will only cover your rent up to the saved
                                amount, ie, ₹15,000.
                            </li>
                            <li>
                                Rewards will be given directly to the user in
                                the form of Winnings on the FinePay app or will
                                be credited to the bank account mentioned in the
                                rental agreement.
                            </li>

                            <li>
                                The maximum cap on the rent we will cover is
                                ₹50,000.
                            </li>

                            <li>
                                All decisions taken by the FinePay team are
                                final.
                            </li>
                        </ul>
                    </ol>
                </div>
            </div>
        </div>
    );
};

export default EMIHero;
