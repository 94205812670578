import React from "react";
import blogBanner from "../assets/images/blogBanner.png";
import { useNavigate } from "react-router";

const BlogCardRactangle = ({img}) => {

  const navigate = useNavigate();

// handle the detail of particular blog
  const handleBlog = () => {
    navigate("/blogdetails");
    window.scrollTo(0, 0);
  };

  return (
    <div className="md:h-[350px] rounded-lg shadow-lg flex flex-col md:flex-row items-center md:justify-between justify-center">

      <div className="h-[100%] md:w-[60%] w-[100%] rounded-lg ">
        <img
          src={img}
          alt=""
          className="h-[100%] md:rounded-l-lg w-[100%]"
          style={{objectFit:"fill"}}
        />
      </div>

      <div className="h-[100%] md:w-[40%] w-[100%] p-[1rem] bg-richblack-900 rounded-r-lg">
        <p className="text-xl text-white tracking-wide font-poppinsRegular font-bold">
        Dive into Digital Gold: Your Complete Investment Handbook
        </p>
        <p className="mt-[1rem] text-pure-greys-200 font-poppinsRegular line-clamp-5">
        In the world of investments, few assets have stood the test of time like gold. Its enduring value and reputation as a hedge against inflation have made it a preferred choice for investors seeking wealth preservation and security. But in today’s digital age, gold investment has evolved, offering new opportunities and conveniences through digital gold. In this comprehensive guide, we will take you on a journey through the realm of digital gold investment.
        </p>
        <div className="mt-[1rem] h-[25%]  flex items-center justify-between">
          <p className="font-poppinsRegular text-pure-greys-200 text-sm">
          2024-03-25
          </p>
          <button
            onClick={handleBlog}
            className="p-[0.4rem] font-poppinsRegular rounded-lg bg-custom-color1 text-white"
          >
            Read More
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogCardRactangle;
