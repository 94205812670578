import React from "react";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

import blogBanner1 from "../assets/web/blog/reasonInvestGold.png";
import blogBanner2 from "../assets/web/blog/planingtobuyDigitalGold.png";
import blogBanner3 from "../assets/web/blog/DigitalgoldVSGoldETF.png";
const BlogDGold = () => {
    const navigate = useNavigate();
    const [isAutoplay, setIsAutoplay] = useState(true);
    const [hideArrow, setHideArrows] = useState(false);

    const carouselData = [
        {
            id: 1,
            image: blogBanner1,
            title: "What is Digital Gold? Benefits,Risks & Taxation.Step-by-step Guide",
            time: "5 min read Beginner",
        },
        {
            id: 2,
            image: blogBanner2,
            title: "Rameshwaram Cafe's Owner Reveals the Story Behind the 4.5Cr Business",
            time: "5 min read Beginner",
        },
        {
            id: 3,
            image: blogBanner3,
            title: "How to earn credit card rewards points (2024)",
            time: "5 min read Beginner",
        },
        // Add more objects as needed
    ];

    const handleBlog = () => {
        navigate("/blogs");
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const handleResize = () => {
            // Disable autoplay for medium (md) screens and above
            setIsAutoplay(window.innerWidth < 768);
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setHideArrows(window.innerWidth >= 768); // Hide arrows for medium and larger screens
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [hideArrow]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: isAutoplay,
        autoplaySpeed: 3000,
        arrows: false, // Show arrows unless hideArrows is true

        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    prevArrow: false, // Hide arrows for md screens
                    nextArrow: false, // Hide arrows for md screens
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    prevArrow: false, // Hide arrows for md screens
                    nextArrow: false, // Hide arrows for md screens
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    prevArrow: false, // Hide arrows for md screens
                    nextArrow: false, // Hide arrows for md screens
                },
            },
        ],
    };

    return (
        <div className=" bg-richblack-900 text-white">
            <div className="w-[85%]  m-auto font-poppinsRegular mt-24 ">
                {/* Paragraph Section */}
                <div className="items-center flex flex-col gap-3  justify-center">
                    <h2 className="text-[#717171] text-xl font-poppinsMedium">
                        Blogs
                    </h2>
                    <h1 className="font-poppinsBold md:text-5xl text-2xl text-center">
                        Our center of{" "}
                        <span className="text-[#FFDA2D]"> knowledge.</span>
                    </h1>
                </div>

                {/* slider all blogs */}
                <div className="mt-10">
                    <Slider {...settings} className="m-auto">
                        {carouselData.map((item) => (
                            <div
                                key={item.id}
                                className="h-[400px] items-center flex  justify-center cursor-pointer md:p-4"
                            >
                                <div className="items-center flex justify-center">
                                    <img
                                        src={item.image}
                                        alt={item.name}
                                        className="md:h-[230px] md:w-96 w-72 h-52 rounded-lg   bg-white    border-pure-greys-300"
                                        style={{ objectFit: "fill" }}
                                        // loading="eager"
                                    />
                                </div>
                                <div className="text-center px-4 py-5">
                                    <h3 className="font-bold text-md md:text-xl">
                                        {item.title}
                                    </h3>
                                    <p className="text-[#717171]">
                                        {item.time}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                    <div className="h-12 w-48 m-auto">
                        <button
                            onClick={handleBlog}
                            className="text-lg rounded-lg bg-custom-color1 border border-[#7745ff] h-[100%] w-[100%] hover:bg-[#7745ff] active:bg-[#7745ff]"
                        >
                            Read More
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDGold;
