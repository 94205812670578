import React from "react";
import { useEffect, useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import icon1 from "../assets/web/1x/save in goldkalash.png"
import icon2 from "../assets/web/1x/swcurekalash.png"
import icon3 from "../assets/web/1x/trusted userskalash.png"
import icon4 from "../assets/web/1x/app ratedkalash.png"
const Sliderhome = () => {
  const [isAutoplay, setIsAutoplay] = useState(true);
  const [hideArrows, setHideArrows] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Disable autoplay for medium (md) screens and above
      setIsAutoplay(window.innerWidth < 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHideArrows(window.innerWidth >= 768); // Hide arrows for medium and larger screens
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: isAutoplay,
    autoplaySpeed: 3000,
    arrows: !hideArrows, // Show arrows unless hideArrows is true

    responsive: [
      {
        breakpoint:640,
        settings: {
          slidesToShow: 1,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          prevArrow: false, // Hide arrows for md screens
          nextArrow: false, // Hide arrows for md screens
        },
      },
    ],
  };

  return (
    <div className="  md:h-[550px] h-[520px] bg-richblack-900 text-white">
      <div className="w-[85%]  m-auto font-poppinsRegular">
        {/* Paragraph Section */}
        <div className=" md:h-60 h-60  flex flex-col gap-3 items-center justify-center">
          <h1 className="font-bold text-center md:text-5xl text-2xl">
            Why Choose FinePay?
          </h1>
          <h2 className="  text-sm md:text-xl text-center text-gray-400">
            Reasons why we are popular in India
          </h2>
        </div>

        <div>
          <Slider {...settings}>
            {/* Content Section */}

            <div className="  flex justify-center   text-white  ">
              <img
                src={icon1}
                alt="slide 1"
                className="  object-contain h-36 m-auto"
                style={{objectFit:"contain"}}
              />
              <div className="text-center   w-56 m-auto mt-4 ">
                <p className="font-bold md:text-xl text-xl">
                  Savings in 24K Gold
                </p>
                <p>Money is saved in 24K pure digital gold, powered by Augmont</p>
              </div>
            </div>

            <div className="  flex justify-center   text-white  mr-12 ">
              <img
                src={icon2}
                alt="slide 2"
                className="    object-contain h-36 m-auto"
                style={{objectFit:"contain"}}
              />

              <div className="text-center   w-56 m-auto mt-4 ">
                <p className="font-bold md:text-xl text-xl">
                 100% Security
                </p>
                <p>Gold is stored in a safe and secure gold locker</p>
              </div>
            </div>
            <div className="  flex justify-center  text-white    ">
              <img
                src={icon3}
                alt="slide 3"
                className="    object-contain  h-36 m-auto "
                style={{objectFit:"contain"}}
              />
              <div className="text-center  w-60 m-auto mt-4 ">
                <p className="font-bold md:text-xl text-xl ">
                Trusted by 1L Users
                </p>
                <p>Indians love our services and continue to save with us daily</p>
              </div>
            </div>
            <div className="  flex justify-center  text-white    ">
              <img
                src={icon4}
                alt="slide 4"
                className="   object-contain h-36 m-auto "
                style={{objectFit:"contain"}}
              />
              <div className="text-center  w-60 m-auto mt-4 ">
                <p className="font-bold md:text-xl text-xl mt-4 ">
                  Rated 4.8* on App Store
                </p>
                <p>10K Customer have given us a 4.8* or above rating on Play Store </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Sliderhome;
