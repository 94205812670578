import React from "react";

const Terms = () => {
    return (
        <div className="bg-richblack-900 text-white">
            <div className="w-[85%] m-auto flex gap-9 flex-col py-20 font-poppinsRegular">
                <h1 class="text-3xl md:text-5xl font-bold mb-6">
                    Terms and Conditions
                </h1>
                <p class="mb-4">
                    These Terms of Use (“Terms and Conditions”) mandate the
                    terms on which the users (“You” or “Your” or “User”) can
                    access and register on the website{" "}
                    <a
                        href="https://finepay.app"
                        class="text-blue-500 hover:underline"
                    >
                        finepay.app
                    </a>{" "}
                    including its mobile application 'FinePay’ operated and
                    managed by Kalash Finestream Investment Private Limited
                    (“Company” or “We” or “Us”), a company duly incorporated
                    under the laws of India, having corporate identification
                    number U64990KA2024PTC188737, collectively referred to as,
                    the “Platform”.
                </p>
                <p class="mb-4">
                    IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS AND THE
                    TERMS AND CONDITIONS OF AUGMONT & LENDBOX (AS AVAILABLE AT{" "}
                    <a
                        href="https://www.augmont.com/terms-conditions"
                        class="text-blue-500 hover:underline"
                    >
                        AUGMONT TERMS
                    </a>{" "}
                    &{" "}
                    <a
                        href="https://www.lendbox.in/terms-of-use"
                        class="text-blue-500 hover:underline"
                    >
                        LENDBOX TERMS
                    </a>
                    ), YOU MAY NOT USE THE PLATFORM AND SHOULD NOT PROCEED TO
                    CREATE ANY ACCOUNTS OR ACQUIRE ANY SUBSCRIPTIONS TO THE
                    PLATFORM. BY USING THE PLATFORM, YOU ARE AGREEING TO BE
                    BOUND BY THESE TERMS AND CONDITIONS, INCLUDING ALL
                    AMENDMENTS MADE HERETO FROM TIME TO TIME.
                </p>
                <p class="mb-4">
                    This is an electronic record in terms of the Information
                    Technology Act, 2022 and the rules made thereunder, and does
                    not require any physical or digital signatures. This is
                    being published in accordance with Rule 3(1) of the
                    Information Technology (Intermediaries Guidelines) Rules,
                    2011.
                </p>
                <p class="mb-4">
                    The Platform is an online portal that facilitates Users to
                    purchase/sale/transfer gold in digital form from a brand
                    named ‘AUGMONT’ (“Gold”) operated and managed by Augmont
                    Goldtech India Private Limited (“AUGMONT”), a company
                    incorporated under the laws of India with corporate
                    identification number U51909MH2020PTC337639. AUGMONT
                    provides for the purchase and sale of Gold, and services
                    related to safe-keeping, vaulting, delivery, and fulfillment
                    of gold.
                </p>
                <p class="mb-4">
                    The Platform also facilitates Users to Lenders/Borrowers
                    investment in digital form from a brand named ‘LENDBOX’
                    (“Investment”) operated and managed by Transactree
                    Technologies Private Limited (“LENDBOX”), a company
                    incorporated under the laws of India with corporate
                    identification number U65900DL2015PTC285968. LENDBOX
                    provides services related to peer-to-peer investment,
                    including safe-keeping, vaulting, delivery, and fulfillment.
                </p>
                <p class="mb-4">
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT COMPANY IS ONLY A
                    DISTRIBUTOR THAT FACILITATES USERS TO BUY GOLD/PEER-TO-PEER
                    INVESTMENT FROM AUGMONT & LENDBOX. YOU UNDERSTAND THAT BY
                    REGISTERING ON THE PLATFORM AND/OR ACCESSING SERVICES
                    DIRECTLY OR INDIRECTLY FROM THE PLATFORM, YOU ONLY RECEIVE
                    THE ABILITY TO USE THE PLATFORM FOR PURCHASE OF GOLD &
                    PEER-TO-PEER INVESTMENT FROM AUGMONT & LENDBOX OR SUCH OTHER
                    PRODUCTS OFFERED BY US FROM TIME TO TIME. WE SHALL NOT INCUR
                    ANY LIABILITY IN RELATION TO ANY DISPUTE RELATING THAT
                    ARISES IN RELATION TO PURCHASE OF GOLD OR PEER-TO-PEER
                    INVESTMENT SERVICES OFFERED BY AUGMONT & LENDBOX. YOU
                    EXPRESSLY AGREE THAT WE ARE NOT RESPONSIBLE FOR CUSTODY,
                    QUANTITY AND QUALITY OF PHYSICAL GOLD THAT YOU PURCHASE
                    THROUGH THE PLATFORM AND HENCE WE SHALL NOT BE LIABLE IN
                    CASE OF ANY DISPUTE THAT ARISES IN RELATION TO CUSTODY OF
                    PHYSICAL GOLD WITH THE CUSTODIAN AND TRUSTEESHIP WITH THE
                    TRUSTEE ADMINISTRATOR.
                </p>
                <p class="mb-4">
                    You understand that the Gold is being offered for purchase
                    by “AUGMONT” and the User can also sell the Gold through
                    AUGMONT. As per the terms and conditions of AUGMONT
                    (available at{" "}
                    <a
                        href="https://www.augmont.com/terms-conditions"
                        class="text-blue-500 hover:underline"
                    >
                        AUGMONT TERMS
                    </a>
                    ), the Gold purchased by You will be accounted in a customer
                    account maintained by Augmont and the corresponding quantity
                    of gold in physical form will be stored with Augmont
                    Goldtech Private Limited (“Custodian”). In order to
                    safeguard Your interest, Augmont has also appointed a
                    trustee (“Trustee Administrator”), and You agree to the
                    appointment of such Trustee Administrator and the Custodian,
                    and also accede to the terms for such arrangement with the
                    Trustee Administrator (being the Trustee Administrator
                    agreement). The Trustee Administrator shall be responsible
                    for monitoring the physical gold held on Your behalf with
                    the Custodian.
                </p>
                <p class="mb-4">
                    Please read these Terms and Conditions and privacy policy
                    (available at{" "}
                    <a
                        href="https://www.finepay.app/privacypolicy"
                        class="text-blue-500 hover:underline"
                    >
                        Privacy Policy
                    </a>
                    ) carefully before registering on the Platform or accessing
                    any material and/or information through the Platform. The
                    Company retains an unconditional right to modify or amend
                    this Terms and Conditions without any requirement to notify
                    You of the same. You can determine when this Terms and
                    Conditions was last modified by referring to the ‘Last
                    Updated’ legend above. It shall be Your responsibility to
                    check this Terms and Conditions periodically for changes.
                    Your continued use of the Platform shall indicate an
                    acceptance of the amended Terms and Conditions, and shall
                    signify Your consent to the changes to the Terms and
                    Conditions and the intention to be legally bound by the
                    amended Terms and Conditions.
                </p>
                <p class="mb-4">
                    Before using our Services and before buying the Gold, Users
                    are also recommended to read the terms of services and
                    privacy policy of Augmont Goldtech Private Limited which can
                    be accessed at{" "}
                    <a
                        href="https://www.augmont.com/terms-conditions"
                        class="text-blue-500 hover:underline"
                    >
                        AUGMONT TERMS
                    </a>
                    . In case of any conflict between these Terms and the terms
                    of Augmont, the terms of Augmont in relation to the purchase
                    and/or sale of Gold shall prevail. It is also clarified that
                    these Terms and Conditions and the terms and conditions of
                    Augmont apply to You once you avail of the Services and
                    purchase Gold.
                </p>
                <p>
                    By using the Services, you acknowledge that Company is not
                    responsible for the quality of physical gold being offered
                    and sold by Augmont Goldtech Private Limited and that any
                    disputes in relation to the quality of physical gold shall
                    be raised directly by You to Augmont Goldtech Private
                    Limited.
                </p>
                {/* eligibility */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">1. Eligibility</h2>
                <p class="mb-4">
                    By using the Services, you represent and warrant that you
                    are above the 18 years of age and qualified to enter into a
                    legally binding contract, enforceable under Indian law,
                    including the Indian Contract Act, 1872. If you are not
                    eligible to enter into any contract, then please discontinue
                    use of the Platform and leave now. If you are the parent or
                    guardian of a child under 18 years of age, by registering/by
                    creating an account or profile on the Platform, you provide
                    your consent to your child’s registration and you agree to
                    be bound by these Terms and Conditions in respect of their
                    use of our Platform. We will at all times assume (and by
                    using this Platform you warrant that) you have the legal
                    ability to purchase and sale Gold and otherwise use this
                    Platform, and capable of being bound by these Terms and
                    Conditions. Further, by using the Platform, You confirm that
                    you are not registered under any applicable Goods and
                    Services Tax Act, 2017 and that if, in the course of your
                    relationship with our Platform and/or Augmont, you become
                    registered under the applicable Goods and Services Tax Act,
                    2017, you shall inform Augmont of your change in status
                    immediately, and no later than 24 hours of such change, and
                    provide any relevant information and documents, as may be
                    requested by Augmont. The Company shall not be liable for
                    any actions that may be undertaken by Augmont pursuant to
                    change of Your status on registration under the applicable
                    Goods and Services Tax Act, 2017. If necessary, You shall
                    seek independent tax advice, and these Terms and Conditions
                    do not constitute any tax advice to You.
                </p>
                {/* registration */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    2. Registration
                </h2>
                <p class="mb-4">
                    The Services are available to only those who have subscribed
                    to the Platform by registering and creating an account with
                    Us. You can register to the Platform by providing your
                    personal information including name, age, gender and
                    registered mobile number. The registration will be validated
                    by sending a one-time password (OTP) to the registered
                    mobile number.
                </p>
                <p class="mb-4">
                    We may also ask You for certain financial information,
                    including Your billing address, bank account details, credit
                    card number, expiration date and/or other payment related
                    details or other payment method data, and debit instructions
                    or other standing instructions to process payments for the
                    Platform Services (as defined below). The Company may also
                    ask You to provide certain additional information about
                    Yourself on a case-to-case basis.
                </p>
                <p class="mb-4">
                    If there is any change in the account information, You shall
                    promptly update Your account information on the Platform. If
                    You provide any information that is untrue, inaccurate, not
                    current or incomplete (or becomes untrue, inaccurate, not
                    current or incomplete), or if the Company has reasonable
                    grounds to suspect that such information is untrue,
                    inaccurate, not current or incomplete, the Company has the
                    right to suspend or terminate Your Account and refuse any
                    and all current or future use of the Platform (or any
                    portion thereof) at its sole and absolute discretion, in
                    addition to any right that the Company may have against You
                    at law or in equity, for any misrepresentation of
                    information provided by You.
                </p>
                <p class="mb-4">
                    By registering on the Platform, you agree to (a) provide
                    correct details when prompted on the Platform and failure to
                    do so may invalidate your request to use Services; (b)
                    authorize the Platform and the Company to retain the
                    information shared by You for the purpose of using the
                    Platform and/or accessing the Services and for any marketing
                    campaign undertaken by the Company and/or third-party
                    service providers; and (c) by giving Your details, You agree
                    to abide by the Terms and Conditions and the privacy policy.
                    Also, by registering, you agree that you will not allow
                    others to use Your account and that you are fully
                    responsible for all activities that occur under Your
                    username. We may assume that any communications we receive
                    under Your account have been made by You.
                </p>
                <p class="mb-4">
                    By providing such personal information, You consent to
                    receive all information, communication and instructions
                    relating to the Platform, the Services offered by the
                    Platform and/or third-party service providers, and for
                    availing special offers and promotional benefits. You shall
                    solely be responsible for the appropriate use of the same.
                    You shall immediately notify the Company of any unauthorized
                    use / breach of your password or account and ensure that You
                    exit from Your account at the end of each session. The
                    Company reserves the right to refuse service, terminate
                    accounts, remove or edit content, or cancel orders in their
                    sole and absolute discretion. The Company cannot and will
                    not be liable for any loss or damage arising from Your
                    failure to comply with these Terms and Conditions. You may
                    be held liable for losses incurred by the Company or any
                    other user of or visitor to the Platform due to authorized
                    or unauthorized use of Your Account as a result of Your
                    failure in keeping Your Account information secure and
                    confidential.
                </p>
                {/* Platform Services Section */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    3. Platform Services
                </h2>
                <p class="mb-4">
                    By registering on the Platform, You are allowed to purchase
                    Gold & Peer to Peer Investment pursuant to the collaboration
                    between the Company and (Augmont & Lendbox). The Company
                    hereby grants You a limited, non-exclusive,
                    non-transferable, royalty-free license to use the Platform
                    for the purposes of availing the Services. The services
                    offered on the Platform shall include, but are not limited
                    to the following: (a) facilitating buying and selling of
                    Gold; and (b) maintaining a record of Your personal
                    information and financial transactions in a secure and
                    confidential manner; Upon no User transaction being
                    detected, Our system intelligently updates the User ledger
                    to meet the User’s savings goal of up to Rs. 20 per day
                    (“Platform Services”).
                </p>
                <p class="mb-4">
                    However, before placing the order and availing the Services,
                    the User may be required to provide certain KYC
                    documentation and other information as may be required by
                    Augmont in such form and manner as it may deem fit. This
                    information may be requested from You at the time of
                    registration, or at a later stage, including the following
                    events, to prevent fraudulent activities:
                </p>
                <ul class="list-disc ml-6 mb-4">
                    <li>You withdraw your purchase of Gold;</li>
                    <li>
                        You wish to convert Your winnings on the Platform to
                        purchase Gold and/or Peer to Peer Investment;
                    </li>
                    <li>
                        You wish to get Your Customer Gold delivered to Your
                        doorstep.
                    </li>
                </ul>
                <p class="mb-4">
                    The KYC verification shall be undertaken based on the AADHAR
                    Card & PAN Card issued to You, where e-Aadhar & e-PAN is
                    provided by You. We shall verify Your details from the
                    database of the issuing authority, including through
                    Digilocker. If You do not have a PAN Card, you may submit
                    Form 60 to Us.
                </p>
                <p class="mb-4">
                    It is hereby clarified that any Services offered (purchase,
                    sale or transfer of Gold on Augmont platform) are governed
                    under specific terms and conditions of Augmont, operated and
                    managed by Augmont Goldtech Private Limited which can be
                    accessed at{" "}
                    <a
                        href="https://www.augmont.com/terms-conditions"
                        class="text-blue-500 hover:underline"
                    >
                        https://www.augmont.com/terms-conditions
                    </a>
                    .
                </p>
                <p class="mb-4">
                    It is also clarified that the Company is merely providing a
                    platform to its Users to facilitate the transaction of sale
                    and investment of Gold and will not be liable in any manner
                    with respect to the products and/or services offered to You
                    by Augmont. You hereby agree and acknowledge that the data
                    and information provided on the Platform does not constitute
                    advice of any nature whatsoever and shall not be relied upon
                    by You while making investment decisions and You shall be
                    solely responsible for any investment decisions and for the
                    purchase of any Gold on the Platform. In no event shall the
                    Company be liable to You for any loss or damage that may
                    cause or arise from or in relation to these Terms and
                    Conditions and/or due to use of this Platform or due to
                    investments made using this Platform. As part of the
                    Platform Services provided to You, on availing such
                    services.
                </p>
                <p class="mb-4">
                    Further, while using the Platform, You undertake not to:
                </p>
                <ul class="list-disc ml-6 mb-4">
                    <li>
                        Defame, abuse, harass, threaten or otherwise violate the
                        legal rights of others;
                    </li>
                    <li>
                        Publish, post, upload, distribute or disseminate any
                        inappropriate, profane, defamatory, infringing, obscene,
                        indecent or unlawful topic, name, material or
                        information;
                    </li>
                    <li>
                        Copy, republish, post, display, translate, transmit,
                        reproduce or distribute any content on the Platform
                        through any medium without obtaining the necessary
                        authorization from the Company in writing;
                    </li>
                    <li>
                        Conduct or forward surveys, contests, pyramid schemes or
                        chain letters;
                    </li>
                    <li>
                        Upload or distribute files that contain software or
                        other material protected by applicable intellectual
                        property laws unless You own or control the rights
                        thereto or have received all necessary consents;
                    </li>
                    <li>
                        Upload or distribute files that contain viruses,
                        corrupted files, or any other similar software or
                        programs that may damage the operation of the Platform
                        or another’s computer;
                    </li>
                    <li>
                        Engage in any activity that interferes with or disrupts
                        access to the Platform (or the servers and networks
                        which are connected to the Platform);
                    </li>
                    <li>
                        Attempt to gain unauthorized access to any portion or
                        feature of the Platform, any other systems or networks
                        connected to the Platform, to any of the Company’s
                        server, or through the Platform, by hacking, password
                        mining or any other illegitimate means;
                    </li>
                    <li>
                        Collect or store data about other users in connection
                        with the prohibited conduct and activities;
                    </li>
                    <li>
                        Use any device or software to interfere or attempt to
                        interfere with the proper working of the Platform or any
                        transaction being conducted on the Platform, or with any
                        other person’s use of the Platform;
                    </li>
                    <li>
                        Use the Platform or any material or content for any
                        purpose that is unlawful or prohibited by these Terms
                        and Conditions, or to solicit the performance of any
                        illegal activity or other activity which infringes the
                        rights of the Company or other third parties;
                    </li>
                    <li>
                        Falsify or delete any author attributions, legal or
                        other proper notices or proprietary designations or
                        labels of the origin or source of software or other
                        material contained in a file that is uploaded;
                    </li>
                    <li>
                        Violate any applicable laws or regulations for the time
                        being in force within or outside India or anyone’s right
                        to privacy or personality;
                    </li>
                    <li>
                        Violate the Terms and Conditions contained herein or
                        terms and conditions provided elsewhere; and
                    </li>
                    <li>
                        Reverse engineer, modify, copy, distribute, transmit,
                        display, perform, reproduce, publish, license, create
                        derivative works from, transfer, or sell any information
                        or software obtained from the Platform.
                    </li>
                </ul>

                {/*  Placing of Order Section */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    4. Placing of Order
                </h2>
                <p class="mb-4">
                    You can purchase Gold on the Platform on a pre-payment basis
                    i.e., once you place the order and confirm the transaction
                    for purchase of Gold, and agree to the Terms and Conditions
                    (“Customer Order”), the Platform will notify Augmont.
                </p>
                <p class="mb-4">
                    Once the payments are received by Augmont and the KYC
                    information is found acceptable, Augmont shall issue an
                    invoice to You confirming the Customer Order placed within a
                    period of 3 (three) business days of placing such order, in
                    a manner it may deem fit. Notwithstanding anything to the
                    contrary contained in these Terms and Conditions, Company
                    and/or Augmont shall be entitled to accept or reject the
                    User, for any reason whatsoever, at its sole and exclusive
                    discretion.
                </p>
                <p class="mb-4">
                    Once the Customer Order is placed, You are not entitled to
                    cancel such a Customer Order, provided however that such
                    Customer Order shall stand cancelled if the payment fails
                    for any reason whatsoever. The Platform and/or Augmont
                    reserves the right to cancel the Customer Order, at its sole
                    discretion, if Your information, provided prior to placing
                    the Customer Order, is not found acceptable and the Platform
                    and/or Augmont are of the opinion that You are not eligible
                    to purchase Gold from Augmont.
                </p>
                <p class="mb-4">
                    In case of rejection of any of Your Customer Order, where
                    payments have been received by Augmont, such payments shall
                    be returned to You in accordance with these Terms and
                    Conditions or the terms and conditions of Augmont, as
                    applicable.
                </p>
                <p class="mb-4">
                    You are advised to periodically check the Platform to
                    understand these storage charges. In the event, Augmont is
                    not able to deduct the storage charges because Your gold
                    balance is too low, then Augmont shall be entitled to sell
                    such portion of Your Customer Gold stored with the Custodian
                    that is necessary or required to recover the unpaid storage
                    charges in question.
                </p>
                {/* Pricing and Payment Policy Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    5. Pricing and Payment Policy
                </h2>
                <p class="mb-4">
                    Except where noted otherwise, the price displayed on the
                    Platform represents the market-linked price of Gold as
                    indicated by Augmont. Market-linked prices mean that these
                    quotes are linked to the prices of gold in the commercial
                    bullion market in India. However, it does not indicate that
                    the gold prices displayed on the Platform will always be the
                    same as those of the prevalent prices displayed in other
                    commercial bullion markets. As a purchaser, it is your
                    responsibility to compare the prices and take appropriate
                    decisions after adequate diligence.
                </p>
                <p class="mb-4">
                    Notwithstanding the foregoing, you understand that these
                    prices may vary multiple times within a day, and
                    accordingly, Your payment obligations for any Customer Order
                    will depend on the prices displayed at the relevant time.
                </p>
                <p class="mb-4">
                    Payment policy: Payment will be accepted through the payment
                    options made available on the Platform, which may include
                    redirection to payment gateways and aggregators hosted by
                    third-party websites or applications including UPI payments
                    like Phone Pe -{" "}
                    <a
                        href="https://www.phonepe.com/"
                        class="text-blue-500 hover:underline"
                    >
                        https://www.phonepe.com/
                    </a>
                    , Paytm -{" "}
                    <a
                        href="https://paytm.com/"
                        class="text-blue-500 hover:underline"
                    >
                        https://paytm.com/
                    </a>
                    . At the time of purchase, fulfillment sale and/or transfer
                    of Gold, the relevant taxes will be chargeable as applicable
                    as per the Government regulations.
                </p>
                <p class="mb-4">
                    However, please note that the payment terms, delivery,
                    warranty, and cancellation and refund policy are subject to
                    the terms and conditions of Augmont which can be accessed at{" "}
                    <a
                        href="https://www.augmont.com/terms-conditions"
                        class="text-blue-500 hover:underline"
                    >
                        https://www.augmont.com/terms-conditions
                    </a>
                    . The transactions on the Platform will be completed only
                    after the successful transfer of money to Augmont's bank
                    account, either from Your registered bank account or from
                    the escrow account maintained by the Company. Please note
                    that the corresponding gold quantity will be credited to
                    Your Account maintained with Augmont within 5 (five)
                    business days from the date of successful transfer of such
                    amount. Please look at Augmont's Terms and conditions
                    accessed{" "}
                    <a
                        href="https://www.augmont.com/terms-conditions"
                        class="text-blue-500 hover:underline"
                    >
                        https://www.augmont.com/terms-conditions
                    </a>{" "}
                    and understand fully the terms of custodian and trusteeship
                    arrangement before making the investment.
                </p>
                <p class="mb-4">
                    Any rewards ("Rewards") displayed from time to time on the
                    Platform, including the Reward structure and disbursal of
                    any and all Reward amounts, are subject to change at any
                    time in accordance with Company policy. The Company reserves
                    the right to revoke, withdraw, or modify any Rewards at any
                    time and at its sole discretion. Unclaimed Rewards shall
                    expire after 90 days from the date of credit of such
                    Rewards.
                </p>

                {/*  Delivery of Gold Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    6. Delivery of Gold
                </h2>
                <p class="mb-4">
                    For the purposes of making delivery to You, You are required
                    to provide a valid address and/or any other
                    documents/information/biometric identification that may be
                    specified in this regard by the Company and Augmont on the
                    Platform from time to time.
                </p>
                <p class="mb-4">
                    You are required to take delivery of Your Customer Gold
                    within such maximum period as shall be specified for this
                    purpose by Augmont from time to time on the Platform
                    (“Maximum Storage Period”).
                </p>
                <p class="mb-4">
                    In the event that no valid address has been provided by You
                    during the Maximum Storage Period, then Platform and/or
                    Augmont shall for a period of 1 (one) year commencing from
                    the date of expiry of Maximum Storage Period (such period
                    being the “Grace Period”) attempt at least once to contact
                    You using the contact information provided by You to obtain
                    either (i) an address to which You would require the gold in
                    question to be delivered or (ii) Your bank account details
                    into which sale proceeds of the Customer Gold shall be
                    deposited. In the event that Platform and/or Augmont has not
                    been able to contact You during the applicable Grace Period
                    using the contact information provided by You or where You
                    shall during the Grace Period either fail to take delivery
                    of the physical gold in question for any reason whatsoever
                    (including where You shall not have provided any address to
                    take delivery of such physical gold); or provide the details
                    for a valid bank account into which the proceeds of any sale
                    of such Customer Gold is to be deposited; then upon expiry
                    of the applicable Grace Period for the Customer Gold in
                    question, Augmont shall be entitled to purchase such
                    Customer Gold with the purchase price being the applicable
                    prevailing price displayed on the Platform for purchase of
                    Gold from Customers.
                </p>
                {/* Return and Cancellation Policy Section */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    7. Return and Cancellation Policy
                </h2>
                <p class="mb-4">
                    On use of any coupon or discounts, as made available by us
                    on the Platform or any other website or platform of any
                    authorised third party, for purchase of Gold, the same shall
                    be verified and adjusted, as may be necessary, within 7
                    (seven) days of use of such coupon or discount.
                </p>
                <p class="mb-4">
                    Upon confirmation of the payment, You shall be permitted to
                    withdraw the monies after 24 (twenty-four) hours of
                    investment, and there is no minimum lock-in period.
                </p>
                <p class="mb-4">
                    Once the payment has been confirmed, the same shall be
                    binding on You and cannot be cancelled.
                </p>
                <p class="mb-4">
                    You can opt out of auto investing on the Platform by
                    undertaking the following actions:
                    <ul class="list-disc list-inside mb-4">
                        <li>
                            Tap on 'Locker' in the bottom right corner on the
                            mobile application.
                        </li>
                        <li>
                            Go to 'Settings' by tapping on the icon on the top
                            right corner on the mobile application.
                        </li>
                        <li>
                            Scroll down and find ‘AutoPay’ under transactions
                            and withdrawals on the mobile application.
                        </li>
                        <li>
                            Disable AutoPay by tapping on the toggle on the
                            mobile application.
                        </li>
                    </ul>
                </p>
                <p class="mb-4">
                    You can also pause their roundup detection and savings for a
                    defined period of time. You can find the option of pausing
                    Your saving for 10 (ten) days to 1 (one) month in the
                    settings of the mobile application.
                </p>

                {/*  Refund Policy Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    8. Refund Policy
                </h2>
                <p class="mb-4">
                    Please note, once an order for purchase or sale has been
                    confirmed by the customer, refunds or cancellation is not
                    possible. All orders are final upon customer confirmation.
                </p>
                <p class="mb-4">
                    Deliveries are made only to the registered address of the
                    customer. Please keep proof of identity ready at the time of
                    delivery as it may be required for verification by the
                    courier partner. If no one is available at the registered
                    address to take delivery, delivery will be attempted at
                    least once more after which the package will be returned to
                    the vault and the customer's balance will be credited back
                    with the corresponding grams of gold. Shipping charges will
                    be payable again as and when the customer asks for delivery.
                </p>
                <p class="mb-4">
                    Customers are required to verify that the package they are
                    accepting has not been tampered with and is of acceptable
                    quality. Once delivery has been taken by the customer,
                    returns and refunds are not possible. All of our products
                    are 100% certified and there should never be any problems
                    with quality. In the unlikely event of any issues, feel free
                    to contact us, we will investigate the matter to resolve any
                    concerns or issues.
                </p>
                <p class="mb-4">
                    You can email us at{" "}
                    <a
                        href="mailto:help@finepay.app"
                        class="text-blue-500 hover:underline"
                    >
                        help@finepay.app
                    </a>
                    . We assure you that your request will be addressed on
                    priority.
                </p>
                {/* Your Obligations and Responsibilities Section */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    9. Your Obligations and Responsibilities
                </h2>
                <p class="mb-4">
                    While accessing our Platform, You shall comply with these
                    Terms and Conditions and the special warnings or
                    instructions for access or use posted on the Platform from
                    time to time.
                </p>
                <p class="mb-4">
                    You shall not make any change or alteration to the Platform
                    or any Content or Services that may appear on this Platform
                    or on Augmont and shall not impair in any way the integrity
                    or operation of the Platform.
                </p>
                <p class="mb-4">
                    You shall always act in accordance with extant laws, customs
                    and in good faith.
                </p>
                <p class="mb-4">
                    You further represent and warrant that:
                    <ul class="list-disc list-inside mb-4">
                        <li>
                            Payment for purchase of Services is made from Your
                            bank account (primary holder’s bank account in case
                            of Investment Account held jointly), and the same is
                            from legitimate sources and remitted through
                            approved banking channels;
                        </li>
                        <li>
                            Before making the investment purchase, You shall
                            seek independent financial planning, legal,
                            accounting, tax or other professional advice, if
                            required;
                        </li>
                        <li>
                            You have read the offer documents of Augmont
                            (including its terms and conditions accessed at{" "}
                            <a
                                href="https://www.augmont.com/terms-conditions"
                                class="text-blue-500 hover:underline"
                            >
                                https://www.augmont.com/terms-conditions
                            </a>
                            ) and its schemes and have made an informed
                            independent investment decision before investing;
                        </li>
                        <li>
                            You will not sell, trade, or otherwise transfer Your
                            registered account to another party or impersonate
                            any other person for the purpose of creating an
                            account with the Platform;
                        </li>
                        <li>
                            You agree and acknowledge and confirm that You
                            solely, and to the exclusion of the Company, are
                            liable for all liabilities that may arise on acts of
                            omission or commission attributable to You,
                            including but not limited to acts such as making
                            inputs pertaining to UPI details, phone number or
                            email addresses, bank account details, addresses,
                            etc.
                        </li>
                    </ul>
                </p>
                {/*  Intellectual Property Rights Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    10. Intellectual Property Rights
                </h2>
                <p class="mb-4">
                    The Platform and all information, content, materials,
                    products including, but not limited to text, content,
                    photographs, graphics, texts, video and audio content and
                    computer code (“Content”) on the Platform is owned and
                    controlled by the Company and the design, structure,
                    selection and feel and arrangement of the Content is
                    protected by copyright, patent and trademark laws and other
                    applicable intellectual property rights either in the favour
                    of the Company or third parties from whom the appropriate
                    permissions have been taken under applicable laws by the
                    Company.
                </p>
                <p class="mb-4">
                    The trademarks, logos and service marks displayed on the
                    Platform (“Marks”) are the property of the Company and its
                    affiliates or other respective third parties, as the case
                    may be. You are not permitted to use the Marks without the
                    express prior written consent of the Company or the third
                    party that owns the Marks, as the case may be. Further, You
                    understand and accept that all information, except Your
                    personal information and other data submitted by You for the
                    purposes of transacting on the Platform, through the
                    Platform shall be deemed the property of the Company, and
                    the Company shall be free to use any ideas, concepts,
                    know-how or techniques provided by You on the Platform, in
                    any manner whatsoever. On initiating a contact or query
                    through the Platform, You agree to be contacted by the
                    Company or any other entities with whom the Company has
                    entered into an arrangement for the provisions of Services
                    to You.
                </p>

                {/*  Limitation of Liability Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    11. Limitation of Liability
                </h2>
                <p class="mb-4">
                    The Content on this Platform is for your general information
                    and use only and does not amount to any investment advice.
                </p>
                <p class="mb-4">
                    You expressly understand and agree that, to the maximum
                    extent permitted by applicable law, the Platform and Content
                    are provided by the Company on an “as is” basis without any
                    warranty of any kind, express, implied, statutory or
                    otherwise, including the implied warranties of title,
                    non-infringement, merchantability or fitness for a
                    particular purpose. The Platform may contain errors or
                    inaccuracies that could cause failures, corruption or loss
                    of data and/or information from Your device and/or any
                    device used by You to connect to the Platform as well as
                    from peripherals (including, without limitation, servers and
                    computers) connected to any of the above-mentioned devices.
                    You assume all risks and costs associated with Your use of
                    the Platform and accessing the Platform Services and
                    Services, including without limitation, any costs incurred
                    for the use of Your device and any damage to any equipment,
                    software or data.
                </p>
                <p class="mb-4">
                    Further, without limiting the foregoing, the Company makes
                    no warranty that (i) the Platform or Platform Services will
                    meet Your requirements, or Your use of the Platform will be
                    uninterrupted, timely, secure or error-free; (ii) the
                    results obtained from the use of the Platform will be
                    effective, accurate or reliable; (iii) any errors or defects
                    in the Platform will be corrected by the Company. No advice
                    or information, whether oral or written, obtained by You
                    from the Company shall create any warranty not expressly
                    stated in these Terms and Conditions.
                </p>
                <p class="mb-4">
                    You acknowledge that the software and hardware underlying
                    the Platform as well as other software which are required
                    for accessing the Platform are the legal property of the
                    respective vendors. The permission given by the Company to
                    access the Platform will not convey any proprietary or
                    ownership rights in the above software/hardware to You.
                </p>
                <p class="mb-4">
                    You agree and acknowledge that you are not registered under
                    the Goods and Services Tax Act, 2017.
                </p>
                {/*  Indemnity Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">12. Indemnity</h2>
                <p class="mb-4">
                    You agree to indemnify the Company, its affiliates, their
                    respective directors, officers and employees from any
                    losses, damages, penalties, claims, costs and demands
                    (including reasonable attorney fees and legal costs) arising
                    out of breach or non-performance and/or non-observance of
                    the duties and obligations, representations, warranties and
                    covenants under the Terms and Conditions or due to Your acts
                    or omissions. You further agree to hold the Company, its
                    affiliates, their respective directors, officers and
                    employees harmless against any claims made by any third
                    party due to, or arising out of, or in connection with, Your
                    use of the Platform, any misrepresentation with respect to
                    the data or information provided by You, Your violation of
                    the Terms and Conditions, or Your violation of any rights of
                    another, including any intellectual property rights.
                </p>
                <p class="mb-4">
                    In no event shall the Company, its affiliates, their
                    respective directors, officers, partners, consultants,
                    agents and employees, be liable to You or any third party
                    for any special, incidental, indirect, consequential or
                    punitive damages whatsoever, arising out of or in connection
                    with Your use of or access to the Platform or Content on the
                    Platform.
                </p>
                <p class="mb-4">
                    The Company and Augmont do not guarantee any returns on any
                    transaction consummated with respect to the Services to You,
                    either directly or indirectly. You shall be solely
                    responsible for undertaking appropriate and effective due
                    diligence and related analysis before undertaking any
                    transactions pursuant to these Terms and Conditions. You
                    further acknowledge and agree that the Company, Augmont and
                    their respective officers, directors, employees, agents and
                    affiliates will have no liability for Your purchase or other
                    decisions using the Platform.
                </p>
                {/*  Limitation in Platform Services Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    13. Limitation in Platform Services
                </h2>
                <p class="mb-4">
                    Please note that Platform Services are not available in all
                    geographical areas and depending on Your location, You may
                    not be eligible to avail certain Platform Services offered
                    by the Company. The Company reserves the absolute right to
                    determine the availability and eligibility for any of the
                    Platform Services offered on the Platform.
                </p>

                {/* Third Party Links Section */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    14. Third Party Links
                </h2>
                <p class="mb-4">
                    The Company is not responsible for the availability of
                    Content or other services on third-party sites linked from
                    the Platform and the Company urges You to read the terms of
                    use of the respective third-party sites, before accessing or
                    registering with any of such third-party sites. Further, the
                    Company does not make any warranties and expressly disclaims
                    all warranties express or implied, including without
                    limitation, those of merchantability and fitness for a
                    particular purpose, title or non-infringement with respect
                    to any information or services or products that are
                    available or advertised or sold through these third-party
                    websites.
                </p>

                {/* Force Majeure Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    15. Force Majeure
                </h2>
                <p class="mb-4">
                    The Company shall not be liable for failure or error of any
                    transaction on the Platform or for any failure on part of
                    the Company to perform any of its obligations under these
                    Terms and Conditions if performance is prevented, hindered
                    or delayed by a Force Majeure event (as defined below) and
                    in such case its obligations under these Terms and
                    Conditions shall be suspended for so long as the Force
                    Majeure event continues. The term “Force Majeure Event”
                    means any event which occurred and is beyond the control of
                    the Company, including without limitations, unavailability
                    of any communication systems, breach, or virus in the
                    processes or payment or delivery mechanism, sabotage, fire,
                    flood, explosion, acts of god, civil commotion, pandemic,
                    strikes or industrial action of any kind, riots,
                    insurrection, war, acts of government, computer hacking,
                    unauthorized access to computer data and storage devices,
                    computer crashes, malfunctioning in the computer terminal or
                    the systems getting affected by any malicious, destructive
                    or corrupting code or program, mechanical or technical
                    errors/failures or power shut down, faults or failures in
                    telecommunication etc.
                </p>
                <p class="mb-4">
                    The Company and Augmont shall be discharged from such
                    performance to the extent of and during the period of such
                    Force Majeure Event, and such non-performance of its
                    obligations shall, in no event whatsoever, amount to a
                    breach of its obligations.
                </p>

                {/*  Suspension and Termination Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    16. Suspension and Termination
                </h2>
                <p class="mb-4">
                    The Terms and Conditions will continue to apply until
                    terminated by either You or the Company for reasons set
                    forth below. If You object to the Terms and Conditions or
                    are dissatisfied with the Platform, Your only recourse is to
                    (i) close Your registered account on the Platform; and/or
                    (ii) stop accessing the Platform. In case you violate these
                    Terms and Conditions and/or any applicable law, the Company
                    may, at any time and in its sole discretion, terminate Your
                    Account and/or prevent You from accessing the Platform and
                    the Platform Services. The Company may delist You or block
                    Your future access to the Platform or suspend or terminate
                    your registered account if it believes, in its sole and
                    absolute discretion that You have infringed, breached,
                    violated, abused, or unethically manipulated or exploited
                    any term of these Terms and Conditions.
                </p>
                <p class="mb-4">
                    Notwithstanding anything in this clause, these Terms and
                    Conditions will survive indefinitely unless and until the
                    Company chooses to terminate or modify them. If You or the
                    Company terminate Your use of the Platform, the Company may
                    delete any Content or other materials relating to Your and
                    the Company shall have no liability to You or any third
                    party for doing so. However, Your transaction details may be
                    preserved by the Company for purposes of tax or regulatory
                    compliance. The Company may modify or amend these Terms and
                    Conditions at any time.
                </p>

                {/*  Governing Laws Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    17. Governing Laws
                </h2>
                <p class="mb-4">
                    The Terms of Use shall be governed and construed in
                    accordance with the laws of India without reference to
                    conflict of laws principles.
                </p>

                {/*  Disputes Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">18. Disputes</h2>
                <p class="mb-4">
                    With respect to any dispute regarding the Platform, all
                    rights and obligations and all actions contemplated by these
                    Terms and Conditions shall be governed by the laws of India
                    and subject to this Clause 17, the courts at Bangalore,
                    India shall have exclusive jurisdiction to deal with such
                    disputes. To the fullest extent permitted by applicable law,
                    any dispute, differences or claim arising out of your visit
                    to this Platform, shall be referred to and finally resolved
                    by mediation and in accordance with the dispute resolution
                    provisions under Indian Consumer Protection Act, 2019.
                    Please review our other policies, including privacy policy
                    (accessed at{" "}
                    <a
                        href="https://www.finepay.app/privacypolicy"
                        class="text-blue-500 hover:underline"
                    >
                        https://www.finepay.app/privacypolicy
                    </a>
                    ), posted on the Platform. These policies also govern your
                    visit to the Platform. We reserve the right to make changes
                    to our site, policies, and these Terms and Conditions at any
                    time without any notice to You.
                </p>
                {/*  Communications Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    19. Communications
                </h2>
                <p class="mb-4">
                    You hereby expressly agree to receive communication
                    (including transactional messages) or by way of SMS and/or
                    e-mail or through WhatsApp from the Company or any third
                    party in connection with the Platform Services or Your
                    registration on the Platform. You can unsubscribe/opt-out
                    from receiving communications through e-mail anytime by
                    writing to{" "}
                    <a
                        href="mailto:help@finepay.app"
                        class="text-blue-500 hover:underline"
                    >
                        help@finepay.app
                    </a>
                    .
                </p>

                {/*  Grievances Section */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">20. Grievances</h2>
                <p class="mb-4">
                    We are committed to resolving all disputes in a fair,
                    effective and cost-efficient manner. We seek to resolve any
                    User concerns through our Grievance cell.
                </p>
                <p class="mb-4">
                    All grievances related to the use of the Platform shall be
                    addressed to Mr. Manoj Kumar Dalai (Grievance Officer).
                    Please write to us at{" "}
                    <a
                        href="mailto:manoj@finepay.app"
                        class="text-blue-500 hover:underline"
                    >
                        manoj@finepay.app
                    </a>
                    . The Grievance Officer will get back to the User within 48
                    hours of the receipt of any complaint from You. Every
                    grievance will be provided with a complaint or ticket number
                    which can be used to track the status of the grievance.
                    Redressal or closure of a grievance might take approximately
                    one month from the date of receipt of the complaint.
                    However, if you are dissatisfied with our grievance
                    redressal mechanism, the dispute will be resolved through
                    mediation in accordance with the Consumer Protection Act,
                    2019 and as provided in Clause 17 hereinabove.
                </p>

                {/*  General Provisions Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    21. General Provisions
                </h2>
                <p class="mb-4">
                    <strong>Notice:</strong> All notices from the Company will
                    be served by email to Your registered email address or by
                    general notification on the Platform. Any notice provided to
                    the Company pursuant to the Terms and Conditions should be
                    sent to{" "}
                    <a
                        href="mailto:help@finepay.app"
                        class="text-blue-500 hover:underline"
                    >
                        help@finepay.app
                    </a>
                    .
                </p>
                <p class="mb-4">
                    <strong>Assignment:</strong> You cannot assign or otherwise
                    transfer the Terms and Conditions, or any rights granted
                    hereunder to any third party. The Company’s rights under the
                    Terms and Conditions are freely transferable by the Company
                    to any third party without Your consent.
                </p>
                <p class="mb-4">
                    <strong>Severability:</strong> If, for any reason, a court
                    of competent jurisdiction finds any provision of the Terms
                    and Conditions, or any portion thereof, to be unenforceable,
                    that provision shall be enforced to the maximum extent
                    permissible so as to give effect to the intent of the
                    parties as reflected by that provision, and the remainder of
                    the Terms and Conditions shall continue in full force and
                    effect.
                </p>
                <p class="mb-4">
                    <strong>Waiver:</strong> Any failure by the Company to
                    enforce or exercise any provision of the Terms and
                    Conditions, or any related right, shall not constitute a
                    waiver by the Company of that provision or right.
                </p>

                {/*  Cashback Offer Section */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">Cashback Offer</h2>
                <p class="mb-4">
                    This is a limited time festive offer and will cease to be
                    valid after 31st December, 2024. Users are eligible to get
                    FinePay winnings of up to ₹500. All FinePay users are
                    eligible to participate. The offer is valid on manual
                    purchase of Digital Gold on the FinePay app.
                </p>
                <p class="mb-4">
                    * FinePay reserves the right to modify or cancel entries in
                    the competition.
                </p>

                {/* Company Information Section  */}
                <h2 class="text-2xl font-semibold mt-8 mb-4">
                    Company Information
                </h2>
                <p class="mb-4">
                    Kalash Finestream Investment Private Limited.
                    <br />
                    Registered Office address: Embassy Manyata Park, Block D3,
                    Nagavara, Bangalore-560045, Karnataka.
                    <br />
                    Tel: +91-8700169668
                    <br />
                    Email:{" "}
                    <a
                        href="mailto:invest@finepay.app"
                        class="text-blue-500 hover:underline"
                    >
                        invest@finepay.app
                    </a>{" "}
                    /{" "}
                    <a
                        href="mailto:help@finepay.app"
                        class="text-blue-500 hover:underline"
                    >
                        help@finepay.app
                    </a>
                    .
                </p>
            </div>
        </div>
    );
};

export default Terms;
