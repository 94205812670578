import React from "react";

const Refund = () => {
    return (
        <div className="bg-richblack-900  text-white">
            <div className="w-[85%] m-auto flex flex-col gap-9 py-20 font-poppinsRegular">
                <h1 className="md:text-6xl text-2xl font-poppinsBold md:h-32 items-center flex ">
                    Refund Policy | FinePay
                </h1>

                {/* 1.RETURN AND CANCELLATION POLICY */}
                <div>
                    <h2 className="font-poppinsBold md:text-3xl text-xl">
                        1. RETURN AND CANCELLATION POLICY
                        <br /> <br />
                    </h2>
                    <h1>Return Policy:</h1>
                    <ul className="text-xl text-[#717171] font-poppinsRegular border border-[#4e4e4e] rounded-lg p-[2rem] list-disc">
                        <li>
                            Returns are generally not applicable for digital
                            gold investment services.
                            <br /> <br />
                        </li>
                        <li>
                            Digital gold transactions are considered final. Once
                            the gold is purchased or transferred to your
                            account, it cannot be returned or refunded.
                        </li>
                    </ul>
                    <h1>Cancellation Policy:</h1>
                    <ul className="text-xl text-[#717171] font-poppinsRegular border border-[#4e4e4e] rounded-lg p-[2rem] list-disc">
                        <li>
                            Orders can be cancelled within 24 hours of placing
                            the order.
                            <br /> <br />
                        </li>
                        <li>
                            After 24 hours, cancellations are not permitted due
                            to the immediate and fluctuating nature of gold
                            prices.
                        </li>
                        <li>
                            Once the order is processed beyond this window, it
                            cannot be cancelled.
                        </li>
                    </ul>
                </div>

                {/* 2.REFUND POLICY */}
                <div>
                    <h2 className="font-poppinsBold md:text-3xl text-xl">
                        2. REFUND POLICY
                        <br /> <br />
                    </h2>

                    <ul className="text-xl text-[#717171] font-poppinsRegular border border-[#4e4e4e] rounded-lg p-[2rem] list-disc">
                        <li>
                            We want to ensure you have a smooth and secure
                            experience with our digital gold investment
                            services. However, once a purchase or sale is
                            confirmed by you, the transaction is final, and
                            we’re unable to offer cancellations or refunds due
                            to the nature of the service.
                            <br /> <br />
                        </li>
                        <li>
                            A refund will only be considered if there was a
                            system error, and such errors need to be supported
                            by valid proof.
                        </li>
                        <li>
                            Refunds may take 7-10 business days to process after
                            the issue is verified by FinePay
                        </li>
                        <li>
                            If you have any concerns or need further assistance,
                            we’re just an email away at invest@finepay.app.
                            We’re here to listen and will make sure your request
                            is handled with care and priority.
                        </li>
                        {/* <li>
              Deliveries are made only to the registered address of the
              customer. Please keep proof of identity ready at the time of
              delivery as it may be required for verification by the courier
              partner. If no one is available at the registered address to take
              delivery, delivery will be attempted at least once more after
              which the package will be returned to the vault and the customer's
              balance will be credited back with the corresponding grams of
              gold. Shipping charges will be payable again as and when the
              customer asks for delivery.
              <br /> <br />
            </li> */}
                        {/* <li>
              Customers are required to verify that the package they are
              accepting has not been tampered with and is of acceptable quality.
              Once delivery has been taken by the customer, returns and refunds
              are not possible. All of our products are 100% certified and there
              should never be any problems with quality. In the unlikely event
              of any issues, feel free to contact us, we will investigate the
              matter to resolve any concerns or issues.
              <br /> <br />
            </li> */}
                        <li>
                            You can email us at{" "}
                            <span className="text-[#2E8BFF]">
                                manoj@finepay.app
                            </span>
                            . We assure you that your request will be addressed
                            on priority.
                            <br /> <br />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Refund;
