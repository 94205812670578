import React from "react";
import Jobs from "../../components/Jobs";
import { useNavigate } from "react-router";
import { MdContentCopy } from "react-icons/md";
import { FaRegSmile } from "react-icons/fa";
import Navbar from "../../components/Navbar";

const Career = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/apply");
    window.scrollTo(0, 0);
  };
  return (
    <div className="bg-richblack-900 text-white font-poppinsRegular">
      {/* first section */}
      <div className="font-poppinsRegular">
      <Navbar backgroundColour={'bg-[#336588]'}/>
        <div
          className="h-[700px]"
          style={{
            backgroundImage: "url('./images/career.jpg'",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* <!-- Content goes here --> */}
          <div className="items-center flex flex-col justify-center text-black relative text-center h-[100%] w-[100%]  bg-[#336588] bg-opacity-60">
            <div className="top-28 relative">
              <h2 className="md:text-6xl text-3xl font-poppinsBold text-[#ecc949] ">
                #JoinTheKalash
              </h2>
              <h2 className="md:text-xl text:xl text-white font-poppinsMedium pt-10">
                Be a part of our exciting journey in making finance fun!
              </h2>
              <button
                onClick={handleClick}
                className="bg-[#336588] font-poppinsSemibold text-white w-48 h-14 text-xl mt-5 bg-opacity-60 rounded-lg hover:bg-richblue-800"
              >
                View open roles
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* second section life of kalash */}
      <div className="md:w-[85%] w-[100%] m-auto mt-24 ">
        <div className=" rounded-3xl items-center flex flex-col lg:flex-row justify-around gap-10 md:p-20 p-5 bg-custom-color1 text-white">
          {/* content */}
          <div className="lg:w-[50%] w-[100%]">
            <h1 className="md:text-4xl text-2xl font-poppinsMedium">
              This is <span className="text-[#7745ff]">life</span> at FinePay
              <span className="text-[#7745ff]">.</span>
            </h1>
            <h2 className="text-xl text-[#717171] mt-5">
              In the quiet but beautiful neighbourhood of Aya Nagar Delhi
              is where you will find us. But we come from all over the country.
              The people who go on to be successful at FinePay are easy to spot.
              Ownership and consistency are what drive them. Their fuel is their
              passion and above all, treating their fellow workers as their
              equals is their edge.
            </h2>
          </div>
          {/* image */}
          <div className=" lg:w-[50%] w-[100%] items-center flex justify-center">
            <img
              src="./images/careers.jpg"
              alt="careers1"
              className="w-[100%] rounded-3xl "
              style={{objectFit:"contain"}}
            />
          </div>
        </div>
      </div>

      {/* third section hiring process */}
      <div className="md:w-[85%] w-[100%] rounded-3xl m-auto md:p-20 p-10 bg-custom-color1 text-white mt-24">
        <div className="text-center">
          <h2 className="text-pure-greys-500 text-xl">Super transparent</h2>
          <h2 className="md:text-5xl text-3xl font-poppinsBold pb-16 text-center ">
          Our <span className="text-[#7745ff]">hiring</span>  process.
          </h2>
        </div>
        <div className="flex justify-around flex-col lg:flex-row lg:gap-8 gap-20 relative">
          <div className="flex flex-col items-center justify-center  h-[300px] w-[100%] lg:w-[30%] rounded-3xl bg-[#2e2942] text-white">
            <div className="relative p-3 flex bottom-10  bg-[#eae7fd] items-center rounded-full ">
              <div className="h-14 w-14 rounded-full text-xl text-white bg-custom-color3 items-center flex justify-center">
                01
              </div>
            </div>
            <div className="px-4 h-[250px]">
              {" "}
              <h1 className="text-xl mb-2 text-center font-poppinsBold">
                Exploration
              </h1>
              <h2 className="text-md text-center">
                Our talent acquisition team reads applications (they really do)
                to gauge how serious a candidate is about FinePay.
              </h2>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center h-[300px] w-[100%] lg:w-[30%] rounded-3xl bg-[#2e2942] text-white">
            <div className="relative p-3 flex bottom-10   bg-[#eae7fd] items-center rounded-full">
              <div className="h-14 w-14 rounded-full text-xl text-white bg-custom-color3 items-center flex justify-center">
                02
              </div>
            </div>
            <div className="px-4 h-[250px]">
              <h1 className="text-xl mb-2 text-center font-poppinsBold">
                Two-way Interview
              </h1>
              <h2 className="text-md text-center">
                With the hiring manager. Are you up for it? Do you like the
                role? These are the answers we seek. Pro-tip: Ask lots of
                questions. Assignments may be given.
              </h2>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center h-[300px] w-[100%] lg:w-[30%] rounded-3xl bg-[#2e2942] text-white">
            <div className="relative p-3 flex bottom-10   bg-[#eae7fd] items-center rounded-full">
              <div className="h-14 w-14 rounded-full text-xl text-white bg-custom-color3 items-center flex justify-center">
                03
              </div>
            </div>
            <div className="px-4 h-[250px]">
              <h1 className="text-xl mb-2 text-center font-poppinsBold">
                Culture matters
              </h1>
              <h2 className="text-md text-center">
                This round is to see if you fit FinePay's culture & for you to
                find out if it works for you too. If yes, then we’re in
                business.
              </h2>
            </div>
          </div>
        </div>
      </div>

      {/* forth section */}
      <div className="w-[85%] m-auto  mt-24">
        <div className=" items-center flex flex-col md:flex-row justify-around pb-10">
          <div className=" border-b w-full md:w-auto md:border-0 text-center items-center flex flex-col justify-center ">
            <h1 className="lg:text-9xl text-5xl font-poppinsBold">2000</h1>
            <h1 className="md:text-2xl font-poppinsBold items-center flex justify-center gap-3 ">
              <MdContentCopy style={{color:"#7745ff"}}/> No. of Profiles Received
            </h1>
          </div>
          <div className=" border-b w-full md:w-auto md:border-0 text-center items-center flex flex-col justify-center">
            <h1 className="lg:text-9xl text-5xl font-poppinsBold">248</h1>
            <h1 className="md:text-2xl font-poppinsBold items-center flex justify-center gap-3 ">
              <MdContentCopy style={{color:"#7745ff"}}/> No. Of Interviews
            </h1>
          </div>
          <div className=" border-b w-full md:w-auto md:border-0 text-center items-center flex flex-col justify-center">
            <h1 className="lg:text-9xl text-5xl font-poppinsBold">5</h1>
            <h1 className="md:text-2xl font-poppinsBold items-center flex gap-3 justify-center">
            <FaRegSmile style={{color:"#7745ff"}}/> Offers Made
            </h1>
          </div>
        </div>
        <div className=" border-r-yellow-100 items-center flex flex-col justify-center">
          <h2 className="text-xl p-[0.5rem]">
            Our TAT in this week was around 2 weeks for a closure! We have
            rolled out 5 offers already this week? Apply now to get the 6th!
          </h2>
          <h2 className="text-xl p-[0.5rem]">
            All it takes is an application and 2 weeks to be in the position and
            culture you truly enjoy, apply now to join our rocketship! 🚀
          </h2>
        </div>
      </div>

      {/* fifth section */}
      <div>
      <Jobs />
      </div>
      

      {/*sixth section */}
      <div className=" mt-24 pb-20">
        <div className="md:w-[70%] m-auto rounded-3xl bg-custom-color1 flex flex-col items-center justify-center h-[250px] py-24">
          <div className="items-center relative p-4 flex justify-center bottom-16 bg-richblack-900 h-24 w-24  rounded-full">
            <img
              src="./images/search.svg"
              alt="automated syastem"
              className=""
              style={{objectFit:"contain"}}
            />
          </div>
          <div className=" items-center flex justify-center flex-col">
            <h1 className="lg:text-2xl text-xl text-center font-poppinsBold">
              Can’t find what you’re looking for?
            </h1>
            <button
              onClick={handleClick}
              className=" md:w-[80%] w-[80%] items-center h-14 mt-8  rounded-3xl border font-poppinsSemibold transition duration-150 ease-in-out hover:text-[#7745ff]"
            >
              Check All Openings
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
