import React from "react";
import Navbar from "../components/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa6";

const SiteMap = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (type) => {
    switch (type) {
      case "Home":
        navigate("/");
        window.scrollTo(0, 0);
        break;
      case "About-us":
        navigate("/aboutus");
        window.scrollTo(0, 0);
        break;
      case "Digital-Gold":
        navigate("/dgold");
        window.scrollTo(0, 0);
        break;
      case "Gold-Pro":
        navigate("/goldpro");
        window.scrollTo(0, 0);
        break;
      case "Fine-Plus":
        navigate("/kalash-plus");
        window.scrollTo(0, 0);
        break;
      case "Gold-Plus":
        navigate("/gold-plus");
        window.scrollTo(0, 0);
        break;
      case "FineX":
        navigate("/finex");
        window.scrollTo(0, 0);
        break;
      case "Carriers":
        navigate("/careers");
        window.scrollTo(0, 0);
        break;
      case "Instant-Loans":
        navigate("/instantloans");
        window.scrollTo(0, 0);
        break;
      case "What is Digital Gold":
        navigate("/digitalgold");
        window.scrollTo(0, 0);
        break;
      case "Partner-Program":
        navigate("partnerprogram");
        window.scrollTo(0, 0);
        break;
      case "Check gold price":
        navigate("/check-live-gold-price");
        window.scrollTo(0, 0);
        break;
      case "FAQ":
        navigate("/faq");
        window.scrollTo(0, 0);
        break;

      case "Privacy-Policy":
        navigate("/privacypolicy");
        window.scrollTo(0, 0);
        break;
      case "Terms":
        navigate("/terms");
        window.scrollTo(0, 0);
        break;
      case "Refund":
        navigate("/refunds");
        window.scrollTo(0, 0);
        break;
      case "Blogs":
        navigate("/blogs");
        window.scrollTo(0, 0);
        break;
      case "contactsupport":
        navigate("/contactsupport");
        window.scrollTo(0, 0);
        break;
      case "Ismspolicy":
        navigate("/ismspolicy");
        window.scrollTo(0, 0);
        break;

      default:
        navigate("/");
        window.scrollTo(0, 0);
        break;
    }
  };

  return (
    <div className="font-poppinsRegular ">
      <Navbar backgroundColour={"bg-custom-color1"} />

      {/* SITEMAP TEXT */}
      <div className="bg-custom-color1 h-[5vh] w-[100%]">
        <p className="font-poppinsRegular text-center text-xl font-semibold text-[#db9e2a]">SITEMAP</p>
        <div className="w-[100%] flex items-center justify-center"><div className="h-[5px] w-[80px] bg-[#db9e2a] rounded-lg"></div></div>
      </div>

      <div className="w-[100%] bg-custom-color1 flex items-ccenter justify-center h-max-content">

        <div className="w-[84%] h-max-content p-[2rem]">
          <div className="w-[100%] flex items-center">

            {/* Home */}
            <div className="w-[100%] h-[30px] flex items-center gap-[1rem]">
              <div className="h-[80%] w-[3px] bg-white"></div>
              <p className="font-poppinsRegular text-lg font-semibold text-[#db9e2a]">HOME</p>
            </div>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="instantloans">Instant Loan</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="digitalgold">What is Digital Gold</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="partnerprogram">Partner Programs</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="check-live-gold-price">Check Gold Price</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="faq">FAQs</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="blogs">Blogs</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="careers">Caarers</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="aboutus">About us</a></p>
          </div>

          {/* OUR PRODUCTS */}
          <div className="w-[100%] h-[30px] flex items-center gap-[1rem] mt-[1.4rem]">
              <div className="h-[80%] w-[3px] bg-white"></div>
              <p className="font-poppinsRegular text-lg font-semibold text-[#db9e2a]">OUR PRODUCTS</p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="dgold">Digital Gold</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="goldpro">Gold Pro</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="kalash-plus">Fine Plus</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="finex">FineX</a></p>
          </div>

          {/* Help */}
          <div className="w-[100%] h-[30px] flex items-center gap-[1rem] mt-[1.4rem]">
              <div className="h-[80%] w-[3px] bg-white"></div>
              <p className="font-poppinsRegular text-lg font-semibold text-[#db9e2a]">HELP</p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="kuberji">Contact Support</a></p>
          </div>

          {/* Help */}
          <div className="w-[100%] h-[30px] flex items-center gap-[1rem] mt-[1.4rem]">
              <div className="h-[80%] w-[3px] bg-white"></div>
              <p className="font-poppinsRegular text-lg font-semibold text-[#db9e2a]">TERMS & CONDITION</p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="privacypolicy">Privacy Policy</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="terms">Terms of Use</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="refunds">Refund Policy</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="ismspolicy">ISMS Policy</a></p>
          </div>
          <div className="lg:w-[25%] w-[100%] h-[20px] flex items-center gap-[1rem] ml-[2rem] mt-[1rem]">
              <p className="font-poppinsRegular h-[100%] w-[100%] text-md font-semibold border-l-[5px] border-[#77716b] text-[#77716b] pl-[1rem] hover:border-white cursor-pointer  active:border-white  hover:text-white cursor-pointer  active:text-white "><a href="grievanceredressal">Grievance Redressal</a></p>
          </div>

      {/* follow us section */}
        <div className="mt-20">
          <h1 className="md:text-4xl text-2xl font-poppinsBold text-center text-white">
            FOLLOW US ON
          </h1>
          <div className="flex justify-around m-auto items-center md:w-[40%] w-full pt-12 text-white">
            <div className="">
              <a href="https://www.instagram.com/kalash_now/" target="blank">
                {" "}
                <FaInstagram style={{ height: "40px", width: "40px" }} />
              </a>
            </div>
            <div>
              <a href="https://www.facebook.com/kalash/" target="blank">
                {" "}
                <FaFacebook style={{ height: "40px", width: "40px" }} />
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/company/99000719/admin/feed/posts/"
                target="blank"
              >
                {" "}
                <FaLinkedin style={{ height: "40px", width: "40px" }} />
              </a>
            </div>
            <div>
              <a href="https://twitter.com/gold_kalash" target="blank">
                {" "}
                <FaTwitter style={{ height: "40px", width: "40px" }} />
              </a>
            </div>

            <div>
              <a href="https://www.youtube.com/@kalash" target="blank">
                {" "}
                <FaYoutube style={{ height: "40px", width: "40px" }} />
              </a>
            </div>
          </div>
        </div>
          
        </div>
      </div>

    </div>
  );
};

export default SiteMap;
